import React, { useState, createContext, useContext } from 'react';
import { StyleSheet } from 'react-native';
import { useMutation, useApolloClient } from '@apollo/client';

import { getCurrentRouteName } from 'src/util/urlUtils';
import PostInformationModal from 'src/components/PostInformationModal/PostInformationModal';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import ConfirmationParagraphContainer from 'src/components/ConfirmationModal/ConfirmationParagraphContainer';
import ConfirmationParagraph from 'src/components/ConfirmationModal/ConfirmationParagraph';
import ConfirmationHeader from 'src/components/ConfirmationModal/ConfirmationHeader';
import { TouchableWithoutFeedback } from 'src/components/Touchable';
import PostActionsModal from 'src/components/PostActionsModal';
import { DELETE_POST_MUTATION } from 'src/graphql/Post';
import PostModalContext from 'src/contexts/PostModalContext';

const styles = StyleSheet.create({
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    cursor: 'auto',
    backgroundColor: 'rgba(0, 0, 0, 0.015)',
  },
});

// This component provides a way to open the "post modal actions" dropdown. This
// dropdown allows an artist to edit, delete, or see more information about a post.
// It receives a ref to the component that triggered the modal, so that the modal can
// be placed near that component.
const PostActionsModalContext = createContext({
  openPostActionsModal: () => {},
  closePostActionsModal: () => {},
});

const WIDTH_OFFSET_PX = 250;
const HEIGHT_OFFSET_PX = 50;

export const PostActionsModalContextProvider = ({ navigation, children }) => {
  const activeRoute = getCurrentRouteName(navigation.state);
  const client = useApolloClient();
  const [selectedPost, setSelectedPost] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [isPostInformationModalOpen, setPostInformationModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({});
  const { openPostModal } = useContext(PostModalContext);

  const [deletePost] = useMutation(DELETE_POST_MUTATION, {
    update: async () => {
      await client.resetStore();
    },
    awaitRefetchQueries: true,
  });

  const onDelete = async () => {
    await deletePost({ variables: { id: selectedPost.id, username: selectedPost.username } });

    if (activeRoute === 'Post') {
      navigation.navigate('User', { username: selectedPost?.username });
    }
  };

  // Positions the modal next to the button that opened it.
  const openPostActionsModal = ({ post, ref }) => {
    const { x, y } = ref.current.getBoundingClientRect();
    setModalPosition({ top: window.pageYOffset + y - HEIGHT_OFFSET_PX, left: x - WIDTH_OFFSET_PX });
    setSelectedPost(post);
    setIsVisible(true);
  };

  const closePostActionsModal = () => {
    setIsVisible(false);
    setSelectedPost(null);
  };

  const handlePostInformation = () => {
    setIsVisible(false);
    setPostInformationModalOpen(true);
  };

  const handleEdit = () => {
    setIsVisible(false);
    openPostModal({ post: selectedPost });
  };

  const handleDelete = () => {
    setIsVisible(false);
    setIsConfirmDeleteModalOpen(true);
  };

  return (
    <PostActionsModalContext.Provider
      value={{
        openPostActionsModal,
        closePostActionsModal,
      }}
    >
      {children}
      {isVisible && (
        <>
          <TouchableWithoutFeedback style={styles.overlay} onPress={closePostActionsModal} />
          <PostActionsModal
            onPostInformation={handlePostInformation}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onClose={closePostActionsModal}
            position={modalPosition}
          />
        </>
      )}
      {isPostInformationModalOpen && (
        <PostInformationModal
          post={selectedPost}
          onClose={() => setPostInformationModalOpen(false)}
        />
      )}
      {isConfirmDeleteModalOpen && (
        <ConfirmationModal
          alertModal
          onClose={() => setIsConfirmDeleteModalOpen(false)}
          onConfirm={() => {
            setIsConfirmDeleteModalOpen(false);
            onDelete();
          }}
          confirmText="Delete"
        >
          <ConfirmationHeader>Are you sure you want to delete this post?</ConfirmationHeader>
          <ConfirmationParagraphContainer>
            <ConfirmationParagraph>This cannot be undone.</ConfirmationParagraph>
          </ConfirmationParagraphContainer>
        </ConfirmationModal>
      )}
    </PostActionsModalContext.Provider>
  );
};

export default PostActionsModalContext;
