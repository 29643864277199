import React, { useContext } from 'react';
import { Image, Text, View } from 'react-native';
import { bool } from 'prop-types';
import { withNavigation } from 'react-navigation';

import SubscribeFlowContext from 'src/contexts/SubscribeFlowContext';

import { TouchableOpacity } from 'src/components/Touchable';
import styles from 'src/components/ChannelHeader/SubscribeButton.styles';

const SubscribeButton = ({
  inverted,
  isSubscribed,
  text,
  subtitle,
  subscribedText,
  postToRedirect,
  navigation,
  icon,
  subscribedIcon,
}) => {
  const username = navigation.getParam('username');
  const { openSubscribeModal } = useContext(SubscribeFlowContext);

  const buttonText = () => {
    if (isSubscribed) return subscribedText || 'Subscribed';

    return text || 'Subscribe for $1.29';
  };

  const buttonIcon = () => {
    if (isSubscribed) return subscribedIcon;
    return icon;
  };

  const handleOnPress = () => {
    if (isSubscribed && postToRedirect) {
      navigation.push('Post', { username, public_uid: postToRedirect });
      return;
    }

    openSubscribeModal();
  };

  const showSubtitle = subtitle && !isSubscribed;
  const buttonDisabled = isSubscribed && !postToRedirect;

  return (
    <>
      <TouchableOpacity
        style={[
          styles.container,
          inverted ? styles.inverted : {},
          buttonDisabled ? styles.subscribed : {},
          inverted && buttonDisabled ? styles.invertedAndSubscribed : {},
          showSubtitle ? styles.withSubtitle : {},
        ]}
        onPress={handleOnPress}
        disabled={buttonDisabled}
      >
        <View style={styles.buttonContainer}>
          {(icon || subscribedIcon) && <Image source={{ uri: buttonIcon() }} style={styles.icon} />}
          <Text style={[styles.text, inverted ? styles.invertedText : {}]}>{buttonText()}</Text>
        </View>
      </TouchableOpacity>
      {showSubtitle && (
        <Text style={[styles.subtitle, inverted ? styles.subtitleInverted : {}]}>{subtitle}</Text>
      )}
    </>
  );
};

SubscribeButton.propTypes = {
  inverted: bool,
};

SubscribeButton.defaultProps = {
  inverted: false,
};

export default withNavigation(SubscribeButton);
