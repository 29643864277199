import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { Text } from 'react-native';

import StylePropType from 'src/util/StylePropType';
import styles from 'src/components/Link.styles';

const Link = ({ href, style, hoverStyle, children, onPress, ...props }) => {
  const [isHover, setHover] = useState(false);

  return (
    <Text
      href={href}
      onPress={onPress}
      accessibilityRole={href && 'link'}
      style={[styles.link, style, isHover && [styles.hover, hoverStyle]]}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      {children}
    </Text>
  );
};

Link.defaultProps = {
  style: {},
  hoverStyle: {},
  onPress: undefined,
  href: undefined,
};

Link.propTypes = {
  style: StylePropType,
  hoverStyle: StylePropType,
  onPress: func,
  href: string,
};

export default Link;
