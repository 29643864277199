import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    height: 50,
    alignContent: 'center',
    justifyContent: 'center',
    paddingLeft: 14,
    paddingRight: 14,
    fontSize: 20,
  },
});

export default styles;
