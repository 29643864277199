import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';

import { isArtist } from 'src/util/userUtils';
import { getCurrentRouteName } from 'src/util/urlUtils';
import FloatingPlusButton from 'src/components/FloatingPlusButton';
import { GET_CURRENT_USER } from 'src/graphql/User';
import PostModalContext from 'src/contexts/PostModalContext';
import PostModal from 'src/components/PostModal/PostModal';

const PostModalRoot = ({ navigation }) => {
  const { data: { me } = {} } = useQuery(GET_CURRENT_USER);
  const activeRoute = getCurrentRouteName(navigation.state);
  const showFloatingButton =
    activeRoute === 'Home' ||
    (activeRoute === 'User' && navigation.getParam('username') === me?.username);

  const {
    openPostModal,
    closePostModal,
    postModalState: { postModalOpen, post },
  } = useContext(PostModalContext);

  if (!isArtist(me)) return null;

  return (
    <>
      {showFloatingButton && <FloatingPlusButton onClick={() => openPostModal({})} />}
      {postModalOpen && <PostModal onClose={closePostModal} post={post} />}
    </>
  );
};

export default PostModalRoot;
