import { StyleSheet } from 'react-native';

import Colors from 'src/styles/colors';

const styles = StyleSheet.create({
  text: {
    textAlign: 'left',
    fontSize: 17,
    lineHeight: 22,
    color: Colors.darkGray,
    marginBottom: 24,
  },
});

export default styles;
