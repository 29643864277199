import React, { useEffect, useState } from 'react';
import { View, Image } from 'react-native';

import { featureShape } from 'src/constants/Shapes';
import FlowHeader from 'src/components/FlowModal/FlowHeader';
import FlowDescriptionLarge from 'src/components/FlowModal/FlowDescriptionLarge';
import FlowDescriptionMedium from 'src/components/FlowModal/FlowDescriptionMedium';
import styles from 'src/components/Features/FeatureModal/Feature.styles';
import FeatureVideo from 'src/components/Features/FeatureModal/FeatureVideo';

const Feature = ({
  feature: { title, description, subtext, fileUrl, videoHtml, imageWidth, imageHeight },
}) => {
  // We are rendering images in a third of their size so that they don't look
  // low resolution on retina diplays.

  const [containerWidth, setContainerWidth] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const onLayout = (event) => {
    const { width: viewWidth } = event.nativeEvent.layout;
    setContainerWidth(viewWidth);
  };

  useEffect(() => {
    if (containerWidth && fileUrl) {
      if (imageWidth / 3 <= containerWidth) {
        setWidth(imageWidth / 3);
        setHeight(imageHeight / 3);
      } else {
        const ratio = imageWidth / imageHeight;
        setWidth(containerWidth);
        setHeight(containerWidth / ratio);
      }
    }
  }, [imageWidth, imageHeight, containerWidth, fileUrl]);

  return (
    <View style={styles.container}>
      <FlowHeader style={[styles.text, styles.headerText]}>{title}</FlowHeader>
      <FlowDescriptionLarge style={[styles.text, styles.descriptionText]}>
        {description}
      </FlowDescriptionLarge>
      {fileUrl && (
        <View style={styles.imageContainer} onLayout={onLayout}>
          <Image source={fileUrl} style={[styles.image, { width, height }]} resizeMode="contain" />
        </View>
      )}
      {videoHtml && <FeatureVideo style={styles.video} videoHtml={videoHtml} />}
      {!!subtext && (
        <FlowDescriptionMedium style={[styles.text, styles.subtext]}>
          {subtext}
        </FlowDescriptionMedium>
      )}
    </View>
  );
};

Feature.propTypes = {
  feature: featureShape.isRequired,
};

export default Feature;
