/* The FlowLoading is an overlay with a loading indicator that sits on top of
the content of the flow modal. This allows us to make the size of the modal stay
the same while loading without specifying any fixed heights.

Additionally, exists at the top level of the modal and therefore occupies its
full height and width, including the navigation. The navigation is kept visible
by setting it at a higher zIndex than the overlay. By using this method, the
loading indicator is centered within the modal including the navigation but the
navigation remains visible at all times. */

import React from 'react';
import { View } from 'react-native';

import styles from 'src/components/FlowModal/FlowLoading.styles';
import LoadingIndicator from 'src/components/LoadingIndicator';

const FlowLoading = () => (
  <View testID="overlay" style={styles.loadingOverlay}>
    <LoadingIndicator />
  </View>
);

export default FlowLoading;
