import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { GET_CURRENT_USER } from 'src/graphql/User';
import { GET_COLLECTIONS_FOR_ARTIST, MOVE_COLLECTION_HIGHER } from 'src/graphql/Collection';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import ConfirmationHeader from 'src/components/ConfirmationModal/ConfirmationHeader';
import EditCollectionModal from 'src/components/Collection/EditCollectionModal';
import styles from 'src/components/SelectCollectionFlow/SelectCollectionFlow.styles';
import CollectionPickerModal from 'src/components/SelectCollectionFlow/CollectionPickerModal';

const SelectCollectionFlowSteps = {
  Select: 0,
  Edit: 1,
  Confirmation: 2,
};

const SelectCollectionFlow = ({ onClose, onChange, selectedCollectionId }) => {
  const [step, setStep] = useState(SelectCollectionFlowSteps.Select);
  const [selectedCollection, setSelectedCollection] = useState();
  const [confirmationText, setConfirmationText] = useState('');

  const {
    data: { me },
  } = useQuery(GET_CURRENT_USER);

  const { data: { collections = [] } = {} } = useQuery(GET_COLLECTIONS_FOR_ARTIST, {
    variables: { username: me.username },
  });

  const [moveCollectionHigher] = useMutation(MOVE_COLLECTION_HIGHER, {
    refetchQueries: [{ query: GET_COLLECTIONS_FOR_ARTIST, variables: { username: me.username } }],
    awaitRefetchQueries: true,
  });

  const onMoveCollectionHigher = async (collection) => {
    await moveCollectionHigher({ variables: { id: collection.id } });
  };

  const openEditCollectionModal = (collection) => {
    setSelectedCollection(collection);
    setStep(SelectCollectionFlowSteps.Edit);
  };

  const closeEditCollectionModal = () => {
    setSelectedCollection();
    setStep(SelectCollectionFlowSteps.Select);
  };

  const goToConfirmationModal = (text, collectionId) => {
    setConfirmationText(text);
    setStep(SelectCollectionFlowSteps.Confirmation);
    onChange(collectionId);
  };

  return (
    <>
      {
        {
          [SelectCollectionFlowSteps.Select]: (
            <CollectionPickerModal
              collections={collections}
              selectedCollectionId={selectedCollectionId}
              onChange={onChange}
              onClose={onClose}
              openCollectionModal={openEditCollectionModal}
              moveCollectionHigher={onMoveCollectionHigher}
            />
          ),
          [SelectCollectionFlowSteps.Edit]: (
            <EditCollectionModal
              collection={selectedCollection}
              onBack={closeEditCollectionModal}
              onSave={goToConfirmationModal}
              onClose={onClose}
            />
          ),
          [SelectCollectionFlowSteps.Confirmation]: (
            <ConfirmationModal onConfirm={onClose} confirmText="OK">
              <ConfirmationHeader style={styles.confirmationText}>
                {confirmationText}
              </ConfirmationHeader>
            </ConfirmationModal>
          ),
        }[step]
      }
    </>
  );
};

export default SelectCollectionFlow;
