import React from 'react';
import { Text } from 'react-native';
import { func, bool, string, shape, objectOf } from 'prop-types';
import { withNavigation } from 'react-navigation';

import { resetNavigation } from 'src/util/navigationUtils';
import styles from 'src/components/BackButton.styles';
import webStyles from 'src/components/BackButton.module.css';
import { TouchableOpacity } from 'src/components/Touchable';
import SmallBackArrowSvg from 'assets/images/small-back-arrow.svg';
import clsx from 'clsx';

const BackButton = ({ navigation, withText, onPress, iconClassName, defaultRoute }) => (
  <TouchableOpacity
    style={styles.containerStyle}
    onPress={() => {
      if (onPress) {
        return onPress();
      }

      if (navigation.isFirstRouteInParent()) {
        return resetNavigation(navigation, defaultRoute);
      }

      window.history.back();
      return null;
    }}
    testID="back-button"
  >
    <>
      <SmallBackArrowSvg
        className={clsx(withText ? webStyles.smallIcon : webStyles.withoutTextIcon, iconClassName)}
      />
      {withText && <Text style={styles.backText}>Back</Text>}
    </>
  </TouchableOpacity>
);

BackButton.defaultProps = {
  iconClassName: '',
  onPress: null,
  withText: false,
  defaultRoute: { routeName: 'Home' },
};

BackButton.propTypes = {
  iconClassName: string,
  onPress: func,
  withText: bool,
  defaultRoute: shape({ routeName: string, params: objectOf(string) }),
};

export default withNavigation(BackButton);
