import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  rating: {
    height: 13,
    marginRight: 10,
    opacity: '70%',
  },
});

export default styles;
