import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  moreOptionsButton: {
    height: 28,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  moreOptionsText: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 18,
    color: '#7A7A7B',
  },
  moreOptionsContainer: {
    paddingHorizontal: 20,
    backgroundColor: '#FAFAFA',
  },
});

export default styles;
