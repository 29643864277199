import React, { useRef, useState, useEffect } from 'react';
import { Image, Text, View } from 'react-native';
import { func, string, bool, oneOfType, object } from 'prop-types';
import ReactPlayer from 'react-player/file';

import { getFileTypeFromURL } from 'src/util/functionUtils';
import { TouchableOpacity } from 'src/components/Touchable';
import styles from 'src/components/PostModal/FileUploader.web.styles';
import webStyles from 'src/components/PostModal/FileUploader.module.css';
import EyeSvg from 'assets/images/eye.svg';
import CrossCircleSvg from 'assets/images/cross-circle.svg';
import clsx from 'clsx';

const FileUploader = ({
  file,
  isNewRecord,
  isDraft,
  onChange,
  handleSaveDraft,
  saveButtonEnabled,
  onClear,
}) => {
  const [preview, setPreview] = useState('');
  const hiddenFieldInput = useRef(null);

  useEffect(() => {
    if (!file) return () => {};

    // A file could be the object just uploaded or the url of the file already uploaded.
    // If the file is a URL, then we set the preview directly. Otherwise we create an ObjectURL.
    if (typeof file === 'string') {
      setPreview(file);
      return () => {};
    }

    // Create the preview
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    // Free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const handleOnPress = () => {
    hiddenFieldInput.current.click();
  };

  const handleChange = (event) => {
    if (event.target.files.length === 0) {
      return false;
    }

    const uploadedFile = event.target.files[0];

    return onChange(uploadedFile);
  };

  const showSaveButton = isNewRecord || isDraft;

  let fileType;

  if (file) {
    if (typeof file === 'string') {
      fileType = getFileTypeFromURL(file);
    } else {
      [fileType] = file.type.split('/');
    }
  }

  if (file) {
    return (
      <View style={styles.container}>
        <View style={styles.content}>
          {
            {
              image: <Image source={preview} style={{ width: '100%', height: '100%' }} />,
              video: (
                <ReactPlayer
                  playing
                  loop
                  muted
                  playsinline
                  url={preview}
                  width="100%"
                  height="100%"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    overflow: 'hidden',
                  }}
                  config={{
                    file: {
                      attributes: {
                        style: {
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        },
                      },
                    },
                  }}
                />
              ),
            }[fileType]
          }
          <View style={styles.changeFileOverlay}>
            {showSaveButton && (
              <TouchableOpacity
                onPress={handleSaveDraft}
                disabled={!saveButtonEnabled}
                style={[
                  styles.button,
                  styles.imageOverlayButton,
                  saveButtonEnabled ? {} : styles.saveButtonDisabled,
                ]}
              >
                <EyeSvg
                  className={clsx(
                    webStyles.previewDraftIcon,
                    !saveButtonEnabled && webStyles.buttonTextDisabled,
                  )}
                />
                <Text
                  style={[styles.buttonText, saveButtonEnabled ? {} : styles.buttonTextDisabled]}
                >
                  Preview a draft of this post
                </Text>
              </TouchableOpacity>
            )}
          </View>
          <TouchableOpacity
            style={[styles.removeFileButton, styles.imageOverlayButton]}
            onPress={onClear}
          >
            <CrossCircleSvg className={webStyles.removeFileIcon} />
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.gradient}>
          <TouchableOpacity style={styles.button} onPress={handleOnPress}>
            <Text style={styles.buttonText}>Choose Image or Video...</Text>
          </TouchableOpacity>
          <input
            type="file"
            ref={hiddenFieldInput}
            onChange={handleChange}
            style={{ display: 'none' }}
            data-testid="file-input"
          />
        </View>
      </View>
    </View>
  );
};

FileUploader.propTypes = {
  file: oneOfType([string, object]),
  isNewRecord: bool,
  onChange: func.isRequired,
  handleSaveDraft: func,
  isDraft: bool,
  saveButtonEnabled: bool,
  onClear: func.isRequired,
};

FileUploader.defaultProps = {
  file: '',
  handleSaveDraft: () => {},
  isNewRecord: false,
  isDraft: false,
  saveButtonEnabled: false,
};

export default FileUploader;
