import { useContext } from 'react';

import NotificationsContext from 'src/contexts/NotificationsContext';

const useNotification = () => {
  const { showNotification } = useContext(NotificationsContext);
  return showNotification;
};

export default useNotification;
