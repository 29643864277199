import { useApolloClient, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import React, { useState, useContext } from 'react';
import { withNavigation } from 'react-navigation';

import { onLogOut } from 'src/util/functionUtils';
import DrawerContext from 'src/contexts/DrawerContext';
import AuthFlowContext from 'src/contexts/AuthFlowContext';
import { GET_CURRENT_USER } from 'src/graphql/User';
import styles from 'src/components/LeftSidebar.styles';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import { Button } from 'src/components/Touchable';
import ConfirmationHeader from 'src/components/ConfirmationModal/ConfirmationHeader';
import ConfirmationParagraphContainer from 'src/components/ConfirmationModal/ConfirmationParagraphContainer';
import ConfirmationParagraph from 'src/components/ConfirmationModal/ConfirmationParagraph';
import CloseSvg from 'assets/images/close.svg';
import HomeSvg from 'assets/images/home.svg';
import SettingsSvg from 'assets/images/settings.svg';
import LoginIconSvg from 'assets/images/login-icon.svg';
import LogoutIconSvg from 'assets/images/logout-icon.svg';
import webStyles from 'src/components/LeftSidebar.module.css';
import clsx from 'clsx';

const LeftSidebar = ({ navigation }) => {
  const [isLogOutModalOpen, setLogOutModalOpen] = useState(false);
  const { toggleAuthFlow } = useContext(AuthFlowContext);

  const client = useApolloClient();
  const { data: { me } = {} } = useQuery(GET_CURRENT_USER);

  return (
    <DrawerContext.Consumer>
      {({ closeDrawer }) => {
        return (
          <>
            <View style={styles.sidebarBody}>
              <View style={styles.header}>
                <View style={styles.closeBtnRow}>
                  <Button style={styles.closeBtn} onPress={() => closeDrawer('left')}>
                    <CloseSvg className={webStyles.closeIcon} />
                  </Button>
                </View>
                <View style={styles.list}>
                  <Button
                    testID="home-drawer-btn"
                    style={styles.listItem}
                    onPress={() => {
                      navigation.push('Home');
                      closeDrawer('left');
                    }}
                  >
                    <HomeSvg className={webStyles.listItemIcon} />
                    <Text style={styles.listItemText}>Home</Text>
                  </Button>
                  {me && (
                    <Button
                      testID="account-settings-btn"
                      style={styles.listItem}
                      onPress={() => {
                        navigation.push('Settings');
                        closeDrawer('left');
                      }}
                    >
                      <SettingsSvg className={webStyles.listItemIcon} />
                      <Text style={styles.listItemText}>Account Settings</Text>
                    </Button>
                  )}
                  {me ? (
                    <Button
                      style={styles.listItem}
                      testID="logout-drawer-btn"
                      onPress={() => {
                        closeDrawer('left');
                        setLogOutModalOpen(true);
                      }}
                    >
                      <LogoutIconSvg
                        className={clsx(webStyles.listItemIcon, webStyles.loginIcon)}
                      />
                      <Text style={styles.listItemText}>Logout</Text>
                    </Button>
                  ) : (
                    <Button
                      style={styles.listItem}
                      testID="login-drawer-btn"
                      onPress={() => {
                        closeDrawer('left');
                        toggleAuthFlow({ defaultsToSignup: false });
                      }}
                    >
                      <LoginIconSvg className={clsx(webStyles.listItemIcon, webStyles.loginIcon)} />
                      <Text style={styles.listItemText}>Login</Text>
                    </Button>
                  )}
                </View>
                <View style={styles.linksRow}>
                  <Text
                    accessibilityRole="link"
                    href="https://about.channels.org/terms-of-service"
                    style={[styles.link, styles.underline]}
                  >
                    Terms of Service
                  </Text>
                  <Text style={styles.link}>&nbsp;and&nbsp;</Text>
                  <Text
                    accessibilityRole="link"
                    href="https://about.channels.org/privacy-policy"
                    style={[styles.link, styles.underline]}
                  >
                    Privacy Policy
                  </Text>
                </View>
              </View>
            </View>

            {isLogOutModalOpen && (
              <ConfirmationModal
                onClose={() => setLogOutModalOpen(false)}
                onConfirm={() => {
                  onLogOut(client);
                  closeDrawer('left');
                  setLogOutModalOpen(false);
                }}
              >
                <ConfirmationHeader>Confirm Log Out</ConfirmationHeader>
                <ConfirmationParagraphContainer>
                  <ConfirmationParagraph>Are you sure you want to log out?</ConfirmationParagraph>
                </ConfirmationParagraphContainer>
              </ConfirmationModal>
            )}
          </>
        );
      }}
    </DrawerContext.Consumer>
  );
};

LeftSidebar.propTypes = {
  navigation: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withNavigation(LeftSidebar);
