import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  button: {
    borderRadius: 14,
    marginBottom: 16,
    backgroundImage: 'none',
    backgroundColor: '#307BF6',
    borderWidth: 0,
    maxHeight: 53,
  },
  hover: {
    backgroundImage: 'none',
    backgroundColor: '#3D82F5',
  },
  pressed: {
    backgroundImage: 'none',
    backgroundColor: '#276EE3',
  },
  buttonText: {
    lineHeight: 50,
    fontWeight: 600,
    fontSize: 17,
  },
});

export default styles;
