import React, { useState, useRef } from 'react';
import { func, string, bool, number } from 'prop-types';
import { useMutation } from '@apollo/client';

import FlowModal from 'src/components/FlowModal/FlowModal';
import FlowModalNavigation from 'src/components/FlowModal/FlowModalNavigation';
import FlowLoading from 'src/components/FlowModal/FlowLoading';
import { MIN_LOADING_DURATION_MS } from 'src/components/LoadingIndicator';
import { getCurrentRouteForRedirect } from 'src/util/urlUtils';
import wrapAnimationDuration from 'src/util/wrapAnimationDuration';
import {
  CREATE_PENDING_EMAIL_LOGIN,
  CREATE_PENDING_PHONE_LOGIN,
  CREATE_PENDING_SIGN_UP,
} from 'src/graphql/User';
import { NEXT_MODAL_DELAY_MS } from 'src/constants/Delays';
import UsernameScreen from 'src/components/AuthFlow/UsernameScreen';
import ContactScreen from 'src/components/AuthFlow/ContactScreen';
import ConfirmationSentScreen from 'src/components/AuthFlow/ConfirmationSentScreen';

// Maps errors from createPendingSignup to more specific versions tailored to
// this flow.
const mapCreatePendingSignupErrors = (rawErrors) =>
  rawErrors.map((error) => {
    return error === 'Username has already been taken.'
      ? `${error} Please go back and choose a different username.`
      : error;
  });

export const AuthSteps = {
  Blank: 0,
  Username: 1,
  Contact: 2,
  SendConfirmation: 3,
};

const AuthFlow = ({
  onClose,
  redirectUrl,
  artistUsername,
  artistName,
  showPaymentOptions,
  totalSteps,
  defaultsToSignUp,
  showLoggedOutMessage,
  animationInTiming,
  navigation,
}) => {
  const [isSignUp, setIsSignUp] = useState(defaultsToSignUp);
  const [step, setStep] = useState(defaultsToSignUp ? AuthSteps.Username : AuthSteps.Contact);
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneType, setPhoneType] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const [createPendingEmailLogin] = useMutation(CREATE_PENDING_EMAIL_LOGIN);
  const [createPendingPhoneLogin] = useMutation(CREATE_PENDING_PHONE_LOGIN);
  const [createPendingSignup] = useMutation(CREATE_PENDING_SIGN_UP);

  const switchedIsSignUp = useRef(false);

  const toggleIsSignup = () => {
    if (isSignUp) {
      setIsSignUp(false);
      setStep(AuthSteps.Contact);
    } else {
      setStep(AuthSteps.Blank);
      setTimeout(() => {
        setIsSignUp(true);
        setStep(AuthSteps.Username);
      }, NEXT_MODAL_DELAY_MS);
    }
    switchedIsSignUp.current = true;
  };

  const onBack = () => {
    if (step === AuthSteps.Contact) {
      setStep(AuthSteps.Blank);
      setTimeout(() => {
        setStep(AuthSteps.Username);
      }, NEXT_MODAL_DELAY_MS);
    } else if (step === AuthSteps.SendConfirmation) {
      setStep(AuthSteps.Contact);
    }
  };

  const onUsernameScreenNext = (selectedUsername) => {
    setUsername(selectedUsername);
    setStep(AuthSteps.Blank);
    setTimeout(() => {
      setStep(AuthSteps.Contact);
    }, NEXT_MODAL_DELAY_MS);
  };

  const onContactScreenNext = async (selectedPhoneType) => {
    try {
      setLoading(true);
      setPhoneType(selectedPhoneType);
      const variables = {
        phone: phone || null,
        email: email || null,
        phoneType: selectedPhoneType || null,
        username,
        redirectUrl: redirectUrl ?? getCurrentRouteForRedirect(navigation.state),
        minDuration: MIN_LOADING_DURATION_MS,
      };

      if (!phone.length && !email.length) {
        throw new Error('Must provide either email or phone.');
      }
      if (isSignUp) {
        const results = await createPendingSignup({ variables });
        const { errors: newErrors } = results.data.createPendingSignup;
        setErrors(mapCreatePendingSignupErrors(newErrors));
        if (newErrors.length > 0) {
          setEmail('');
          setPhone('');
          setPhoneType('');
          return;
        }
      } else {
        let results = null;
        if (variables.email) {
          results = (await createPendingEmailLogin({ variables })).data.createPendingEmailLogin;
        } else {
          results = (await createPendingPhoneLogin({ variables })).data.createPendingPhoneLogin;
        }
        const { errors: newErrors } = results;
        setErrors(newErrors);
        if (newErrors.length > 0) {
          setEmail('');
          setPhone('');
          setPhoneType('');
          return;
        }
      }
    } catch (err) {
      setEmail('');
      setPhone('');
      setPhoneType('');
      setErrors([err.message]);
      return;
    } finally {
      setLoading(false);
    }

    setStep(AuthSteps.SendConfirmation);
    setErrors([]);
  };

  const showBackButton =
    (isSignUp && step !== AuthSteps.Username) || (!isSignUp && step !== AuthSteps.Contact);

  return (
    <>
      {step === AuthSteps.Username && (
        <UsernameScreen
          onClose={onClose}
          artistUsername={artistUsername}
          artistName={artistName}
          onSubmit={onUsernameScreenNext}
          username={username}
          setUsername={setUsername}
          toggleIsSignup={toggleIsSignup}
        />
      )}
      {(step === AuthSteps.Contact || step === AuthSteps.SendConfirmation) && (
        // Sets a breakPoint of 360 so that the login modal will only appear full
        // screen on smaller devices e.g. iPhone 5
        <FlowModal
          onClose={onClose}
          lightweight
          animationInTiming={wrapAnimationDuration(animationInTiming)}
        >
          {loading && <FlowLoading />}
          <FlowModalNavigation onClose={onClose} onBack={showBackButton ? onBack : null} />
          {
            {
              [AuthSteps.Contact]: (
                <ContactScreen
                  email={email}
                  phone={phone}
                  username={username}
                  artistName={artistName}
                  setEmail={setEmail}
                  setPhone={setPhone}
                  errors={errors}
                  isSignUp={isSignUp}
                  setErrors={setErrors}
                  onSubmit={onContactScreenNext}
                  onToggleIsSignUp={toggleIsSignup}
                  totalSteps={totalSteps}
                  showPaymentOptions={showPaymentOptions}
                  showLoggedOutMessage={showLoggedOutMessage && !switchedIsSignUp.current}
                  onClose={onClose}
                />
              ),
              [AuthSteps.SendConfirmation]: (
                <ConfirmationSentScreen
                  email={email}
                  phone={phone}
                  phoneType={phoneType}
                  onResend={onContactScreenNext}
                />
              ),
            }[step]
          }
        </FlowModal>
      )}
    </>
  );
};

AuthFlow.defaultProps = {
  artistUsername: null,
  redirectUrl: null,
  showPaymentOptions: false,
  animationInTiming: 205,
};

AuthFlow.propTypes = {
  defaultsToSignUp: bool.isRequired,
  onClose: func.isRequired,
  artistUsername: string,
  redirectUrl: string,
  showPaymentOptions: bool,
  animationInTiming: number,
};

export default AuthFlow;
