import { StyleSheet } from 'react-native';

const SMALL_SIZE = 26;
const MEDIUM_SIZE = 32;
const LARGE_SIZE = 64;

const styles = (size = 'medium') => {
  switch (size) {
    case 'large':
      return StyleSheet.create({
        avatar: {
          height: LARGE_SIZE,
          width: LARGE_SIZE,
          borderRadius: LARGE_SIZE,
        },
        letterAvatar: {
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#5C5C5C',
          backgroundImage:
            'linear-gradient(206deg, rgba(255, 255, 255, 0.183867) 0%, rgba(18, 34, 40, 0.298541) 83.33%)',
        },
        avatarLetter: {
          color: '#FFF',
          opacity: 0.8,
          fontWeight: 600,
          fontSize: LARGE_SIZE / 2,
          lineHeight: LARGE_SIZE,
          height: LARGE_SIZE,
          textAlignVertical: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily:
            'ui-rounded, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
        },
        avatarImage: {
          borderRadius: LARGE_SIZE,
          overflow: 'hidden',
        },
        avatarImageBorder: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          borderRadius: LARGE_SIZE,
          width: '100%',
          borderWidth: 1,
          borderColor: 'rgba(255, 255, 255, 0.1)',
          boxSizing: 'border-box',
          zIndex: 1,
        },
        avatarIcon: {
          height: LARGE_SIZE,
          width: LARGE_SIZE,
        },
      });
    case 'small':
      return StyleSheet.create({
        avatar: {
          height: SMALL_SIZE,
          width: SMALL_SIZE,
          borderRadius: SMALL_SIZE,
          marginRight: 13,
        },
        letterAvatar: {
          backgroundColor: '#5C5C5C',
          backgroundImage:
            'linear-gradient(206deg, rgba(255, 255, 255, 0.183867) 0%, rgba(18, 34, 40, 0.298541) 83.33%)',

          alignItems: 'center',
          justifyContent: 'center',
        },
        imageAvatar: {
          height: SMALL_SIZE,
          width: SMALL_SIZE,
          borderRadius: SMALL_SIZE,
        },
        avatarLetter: {
          color: '#FFF',
          opacity: 0.8,
          fontWeight: 600,
          textAlignVertical: 'center',
          lineHeight: '1em',
          marginTop: 7,
          marginBottom: 6,
          height: SMALL_SIZE,
          fontSize: SMALL_SIZE / 2,
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily:
            'ui-rounded, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
        },
        avatarImage: {
          borderRadius: SMALL_SIZE,
          overflow: 'hidden',
        },
        avatarImageBorder: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          borderRadius: SMALL_SIZE,
          width: '100%',
          borderWidth: 1,
          borderColor: 'rgba(255, 255, 255, 0.1)',
          boxSizing: 'border-box',
          zIndex: 1,
        },
        avatarIcon: {
          height: SMALL_SIZE,
          width: SMALL_SIZE,
        },
      });
    default:
      return StyleSheet.create({
        avatar: {
          height: MEDIUM_SIZE,
          width: MEDIUM_SIZE,
          borderRadius: MEDIUM_SIZE,
          shadowColor: '#000000',
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.05,
          shadowRadius: 6,
          elevation: 3,
        },
        letterAvatar: {
          backgroundColor: '#5C5C5C',
          backgroundImage:
            'linear-gradient(206deg, rgba(255, 255, 255, 0.183867) 0%, rgba(18, 34, 40, 0.298541) 83.33%)',
          alignItems: 'center',
          justifyContent: 'center',
        },
        avatarLetter: {
          color: '#FFF',
          opacity: 0.8,
          fontWeight: 600,
          textAlignVertical: 'center',
          lineHeight: '1em',
          marginTop: 13,
          marginBottom: 12,
          fontSize: MEDIUM_SIZE / 2,
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily:
            'ui-rounded, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
        },
        avatarImage: {
          borderRadius: MEDIUM_SIZE,
          overflow: 'hidden',
        },
        avatarImageBorder: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          borderRadius: MEDIUM_SIZE,
          width: '100%',
          borderWidth: 1,
          borderColor: 'rgba(255, 255, 255, 0.1)',
          boxSizing: 'border-box',
          zIndex: 1,
        },
        avatarIcon: {
          height: MEDIUM_SIZE,
          width: MEDIUM_SIZE,
        },
      });
  }
};

export default styles;
