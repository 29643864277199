/* eslint-disable import/prefer-default-export */

export function getFileType(file) {
  if (!file || typeof file === 'string') return null;
  if (file.type.match('image.*')) return 'image';
  if (file.type.match('video.*')) return 'video';
  if (file.type.match('audio.*')) return 'audio';

  return null;
}
