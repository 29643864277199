import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    height: 50,
    marginTop: 2,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  exitButton: {
    marginLeft: 3,
    padding: 15,
  },
  closeIcon: {
    width: 18,
    height: 18,
  },
  title: {
    fontSize: 17,
    lineHeight: 22,
    fontWeight: 600,
    color: '#333',
    paddingLeft: 10,
  },
  actionText: {
    color: '#1D89FF',
    fontSize: 17,
    lineHeight: 22,
    marginRight: 6,
    fontWeight: 600,
  },
  actionButton: {
    textAlign: 'right',
    paddingRight: 10,
    width: 75,
  },
  disabled: {
    opacity: '40%',
  },
});

export default styles;
