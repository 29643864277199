/* eslint-disable react/no-danger */
import React from 'react';
import clsx from 'clsx';

import styles from 'src/components/ChannelHeader/ProfileTagline.module.css';

const ProfileTagline = ({ tagline, inverted }) => (
  <div
    className={clsx(styles.tagline, inverted && styles.inverted)}
    dangerouslySetInnerHTML={{ __html: tagline }}
  />
);

export default ProfileTagline;
