import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  text: {
    textAlign: 'left',
    width: '100%',
    fontSize: 13,
    color: 'rgba(60, 60, 67, 0.6)',
    marginBottom: 16,
  },
});

export default styles;
