// eslint-disable-next-line import/prefer-default-export
export const zIndex = {
  bottomBar: 1000,
  topBar: 1000,
  modal: 1001,
  loadingOverlay: 1002,
  modalNavigation: 1003,
  postActionsModal: 1004,
  // We raise the post caption above the timestamp so that the timestamp hitbox
  // takes precedence over the timestamp hitbox.
  postCaption: 1,
};
