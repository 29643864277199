import React from 'react';
import { StyleSheet, View } from 'react-native';

import StylePropType from 'src/util/StylePropType';
import ChildrenPropType from 'src/util/ChildrenPropType';

const styles = StyleSheet.create({
  container: {
    marginBottom: 13,
  },
});

const ConfirmationParagraphContainer = ({ children, style }) => (
  <View style={[styles.container, style]}>{children}</View>
);

ConfirmationParagraphContainer.propTypes = {
  style: StylePropType,
  children: ChildrenPropType.isRequired,
};

ConfirmationParagraphContainer.defaultProps = {
  style: {},
};
export default ConfirmationParagraphContainer;
