import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { string, bool, func } from 'prop-types';
import { TextInput } from 'react-native';

import Colors from 'src/styles/colors';
import StylePropType from 'src/util/StylePropType';
import styles from 'src/components/BaseInput.styles';

const BaseInput = forwardRef(
  (
    {
      placeholder,
      focus,
      style,
      label,
      secureTextEntry,
      onChange,
      isOnlyInput,
      value,
      placeholderTextColor,
      prefix,
      excludeFromFS,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef();
    const formattedValue = () => {
      if (prefix) {
        return `${prefix}${value}`;
      }

      return value;
    };

    const onChangeText = (text) => {
      if (prefix) {
        onChange(text.replace(prefix, ''));
      } else {
        onChange(text);
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          inputRef.current.focus();
        },
      }),
      [inputRef],
    );

    useEffect(() => {
      if (focus) inputRef.current.focus();
    }, []);

    return (
      <TextInput
        data-only-input={isOnlyInput}
        ref={inputRef}
        autoFocus={focus}
        secureTextEntry={secureTextEntry}
        placeholder={placeholder}
        style={[styles.input, style]}
        autoCorrect={false}
        onChangeText={onChangeText}
        placeholderTextColor={placeholderTextColor}
        value={formattedValue()}
        dataSet={{ 'fs-exclude': excludeFromFS, 'only-input': isOnlyInput }}
        {...props}
      />
    );
  },
);

BaseInput.propTypes = {
  placeholder: string,
  placeholderTextColor: string,
  focus: bool,
  label: string,
  onChange: func.isRequired,
  isOnlyInput: bool,
  secureTextEntry: bool,
  value: string,
  style: StylePropType,
  prefix: string,
  excludeFromFS: bool,
};

BaseInput.defaultProps = {
  placeholder: '',
  placeholderTextColor: Colors.inputPlaceholderColor,
  style: null,
  focus: false,
  label: null,
  secureTextEntry: false,
  isOnlyInput: false,
  excludeFromFS: false,
  value: '',
  prefix: null,
};

export default BaseInput;
