// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ContactScreen-module__smallText__2jEVk {\n    margin: 0;\n    padding-left: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"smallText": "ContactScreen-module__smallText__2jEVk"
};
module.exports = exports;
