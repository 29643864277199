import { StyleSheet } from 'react-native';
import { zIndex } from 'src/styles/zIndex';

const styles = StyleSheet.create({
  header: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingHorizontal: 12,
    borderTopRightRadius: 18,
    borderTopLeftRadius: 18,
    paddingTop: 16,
    minHeight: 48,
    paddingBottom: 4,
    boxSizing: 'content-box',
    alignItems: 'center',
    zIndex: zIndex.modalNavigation,
    marginBottom: 4,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48,
  },
  rightButton: {
    marginLeft: 'auto',
    marginRight: 6,
  },
  closeText: {
    color: '#0279FF',
    fontSize: 16,
    fontWeight: 600,
  },
  dotsContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    marginHorizontal: 'auto',
    width: 'fit-content',
  },
});

export default styles;
