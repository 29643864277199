import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'space-between',
    height: `calc(min(100vw, 1680px) * 0.5625 + 50px)`,
    maxHeight: `100vh`,
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftColumn: {
    flex: 1,
    paddingRight: 24,
    alignItems: 'start',
  },
  rightColumn: {
    width: '24%',
    minWidth: 222,
  },
  hairline: {
    marginBottom: 20,
  },
  castVerbText: {
    color: '#FFFFFF80',
    fontSize: 15,
    lineHeight: 20,
  },
  castVerbTextInverted: {
    color: '#00000080',
  },
  castText: {
    color: '#FFFFFF',
    fontSize: 15,
    lineHeight: 20,
  },
  castTextInverted: {
    color: '#000000',
  },
  title: {
    alignSelf: 'start',
    maxWidth: '57%',
    fontFamily: 'System',
    fontSize: 'min(calc(1px + 4.2vw), 72px)',
    fontWeight: 600,
    lineHeight: `112.5%`,
    textAlign: 'left',
    color: '#FFF',
    marginTop: 'auto',
  },
  buttonContainer: {
    marginTop: 'auto',
    marginBottom: 2,
  },
  editButtonContainer: {
    marginBottom: 2.5,
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  bottomOverlay: {
    height: 190,
    width: '100%',
    backgroundImage: 'linear-gradient(to bottom, rgba(21, 23, 24, 0) 0%, #151718 65.87%)',
    zIndex: 2,
  },
  bottomContainer: {
    width: '100%',
    zIndex: 3,
    position: 'absolute',
    bottom: 0,
  },
  bottomSolidColor: {
    width: '100%',
    height: 100,
  },
  bottomInnerContainer: {
    width: '100%',
    boxSizing: 'border-box',
    paddingHorizontal: '4vw',
    position: 'absolute',
    bottom: 0,
    zIndex: 3,
  },
  channelInfo: {
    flexDirection: 'row',
    fontFamily: 'System',
    fontSize: 13,
    lineHeight: 20,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 14.5,
    color: 'rgba(255, 255, 255, 0.70)',
  },
  channelInfoWithLogo: {
    marginTop: 17,
  },
  channelInfoInverted: {
    color: 'rgba(0, 0, 0, 0.69)',
  },
  invertedText: {
    color: '#000',
  },
  charityRow: {
    flexDirection: 'row',
    marginTop: 14,
    marginBottom: 24,
    alignItems: 'center',
  },
  charityText: {
    fontSize: 13,
    lineHeight: 16,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  charityInvertedText: {
    color: 'rgba(0, 0, 0, 0.65)',
  },
  charityLink: {
    textDecorationLine: 'underline',
    color: 'rgba(255, 255, 255, 0.6)',
  },
});

export default styles;
