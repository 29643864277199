import React from 'react';
import { View } from 'react-native';

import ProgressiveImage from 'src/components/ProgressiveImage';

const ChannelLogo = ({ logo, ratio, maxHeight, style }) => {
  return (
    <View style={[{ width: `min(100%, ${maxHeight}px * ${ratio})` }, style]}>
      <ProgressiveImage
        imageSource={{ uri: logo }}
        resizeMode="contain"
        placeholderColor="rgba(0, 0, 0, 0)"
        ratio={ratio}
        dataSet={{ 'background-position-bottom': true }}
        testID="channel-logo"
        forceRender
      />
    </View>
  );
};

export default ChannelLogo;
