import React from 'react';

import DrawerContext from 'src/contexts/DrawerContext';
import styles from 'src/components/MenuButton.styles';
import { TouchableOpacity } from 'src/components/Touchable';
import MenuSvg from 'assets/images/menu.svg';

const MenuButton = () => (
  <DrawerContext.Consumer>
    {({ leftDrawerOpen, openDrawer, closeDrawer }) => (
      <TouchableOpacity
        testID="left-drawer-open-button"
        style={styles.container}
        onPress={(evt) => {
          // NOTE(zbrukas): we're preventing the default behavior here
          // for the buttons to work on narrow viewports
          evt.preventDefault();
          if (leftDrawerOpen) {
            closeDrawer('left');
          } else {
            openDrawer('left');
          }
        }}
      >
        <MenuSvg />
      </TouchableOpacity>
    )}
  </DrawerContext.Consumer>
);

export default MenuButton;
