import React from 'react';
import { string } from 'prop-types';
import { StyleSheet, View } from 'react-native';

import StylePropType from 'src/util/StylePropType';

const styles = StyleSheet.create({
  fullWidthHairlineStroke: {
    width: 1,
    backgroundSize: '1px 100%',
  },
});

const VerticalHairlineStroke = ({ style, color }) => {
  const colorStyle = {
    backgroundImage: `linear-gradient(to left, ${color} 0%, ${color} 66%, transparent 67%)`,
  };
  const lineStyles = [styles.fullWidthHairlineStroke, colorStyle, style];

  return <View style={lineStyles} />;
};

VerticalHairlineStroke.propTypes = {
  color: string,
  style: StylePropType,
};

VerticalHairlineStroke.defaultProps = {
  color: '#DCDBE0',
  style: {},
};

export default VerticalHairlineStroke;
