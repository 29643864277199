let disabledScrollElements = 0;

const enableScroll = (enable = true): void => {
  if (enable) {
    disabledScrollElements -= 1;
  } else {
    disabledScrollElements += 1;
  }

  document.body.setAttribute(
    'style',
    `overflow: ${disabledScrollElements > 0 ? 'hidden' : 'auto'};`,
  );
};

export default enableScroll;
