import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    marginTop: 24,
  },
  button: {
    backgroundColor: '#FAFAFA',
    width: '100%',
    height: 52,
  },
  text: {
    fontSize: 17,
    lineHeight: 22,
    color: '#EC0730',
    margin: 'auto',
  },
});

export default styles;
