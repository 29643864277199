import React, { useState, createContext } from 'react';

const FeaturesContext = createContext({
  toggleFeatureModal: () => {},
  featureModalOpen: false,
  openFeatureModalAfterFlow: false,
  setOpenFeatureModalAfterFlow: () => {},
});

export const FeaturesContextProvider = ({ children }) => {
  const [featureModalOpen, setFeatureModalOpen] = useState(false);
  const [openFeatureModalAfterFlow, setOpenFeatureModalAfterFlow] = useState(false);

  const toggleFeatureModal = () => {
    setFeatureModalOpen((oldFeatureModalOpen) => !oldFeatureModalOpen);
  };

  return (
    <FeaturesContext.Provider
      value={{
        featureModalOpen,
        toggleFeatureModal,
        openFeatureModalAfterFlow,
        setOpenFeatureModalAfterFlow,
      }}
    >
      {children}
    </FeaturesContext.Provider>
  );
};

export default FeaturesContext;
