const hairlineBorderStyle = (direction, color) => {
  const directionToAngles = {
    bottom: 0,
    left: 90,
    top: 180,
    right: 270,
  };
  const angle = directionToAngles[direction];
  return {
    backgroundImage: `linear-gradient(${angle}deg, transparent 50%, transparent 0%, ${color} 50%, ${color} 100%)`,
  };
};

export default hairlineBorderStyle;
