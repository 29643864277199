import { format } from 'date-fns';

export function monthDateAndYearTitle(date) {
  return format(new Date(date), 'PP');
}
// Ex. Output - 11/27/88, 9:06 AM
export function shortFormTimeStamp(date) {
  return format(new Date(date), 'MM/dd/yy, p');
}

export function timestampFormat(date) {
  const newDate = date || new Date();

  return format(new Date(newDate), "yyyy-MM-dd'T'HH:mm");
}

export function timestampFormatForRequest(date) {
  return new Date(date).getTime();
}

export function localTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
