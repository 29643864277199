import React from 'react';
import { Text } from 'react-native';

import ChildrenPropType from 'src/util/ChildrenPropType';
import StylePropType from 'src/util/StylePropType';
import styles from 'src/components/FlowModal/FlowDescriptionMedium.styles';

const FlowDescriptionMedium = ({ children, style }) => (
  <Text style={[styles.text, style]}>{children}</Text>
);

FlowDescriptionMedium.defaultProps = {
  style: {},
};

FlowDescriptionMedium.propTypes = {
  children: ChildrenPropType.isRequired,
  style: StylePropType,
};

export default FlowDescriptionMedium;
