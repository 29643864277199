/* eslint-disable */
import React, { useContext } from 'react';
import { Image, Text, View } from 'react-native';
import { bool, string } from 'prop-types';

import styles from './SubscribeButton.styles';
import nftStyles from './SecondarySubscribeButton.styles';
import { TouchableOpacity } from '../Touchable';
import LoadingIndicator from '../LoadingIndicator';
import retryingLazy from 'src/util/retryingLazy';
import useLazyMonitor from 'src/util/useLazyMonitor';
import SubscribeFlowContext from 'src/contexts/SubscribeFlowContext';
import { SubscriptionMethod } from '__generated__/globalTypes';
import ConnectWalletFlowContext from 'src/contexts/ConnectWalletFlowContext';

const ConnectWalletFlow = retryingLazy(() =>
  import('src/components/ConnectWalletFlow/ConnectWalletFlow'),
);

const ConnectWalletButton = ({ inverted, text, subtitle, additionalButtonText, icon }) => {
  const { openSubscribeModal } = useContext(SubscribeFlowContext);
  const { connectWalletFlowOpen } = useContext(ConnectWalletFlowContext);
  const multipleButtons = !!additionalButtonText;
  const { loading: connectWalletFlowLoading } = useLazyMonitor(
    ConnectWalletFlow,
    connectWalletFlowOpen,
  );

  const handlePress = async () => {
    // TODO(charlie): Add some sort of FullStory logging here.
    openSubscribeModal({ explicitSubscribeMethod: SubscriptionMethod.token });
  };

  return (
    <>
      <TouchableOpacity
        style={[
          styles.container,
          inverted ? styles.inverted : {},
          subtitle ? styles.withSubtitle : {},
          multipleButtons ? nftStyles.noMargin : {},
        ]}
        onPress={handlePress}
      >
        <View style={styles.buttonContainer}>
          {icon && <Image source={{ uri: icon }} style={styles.icon} />}
          <Text style={[styles.text, inverted ? styles.invertedText : {}]}>
            {text}
            {connectWalletFlowLoading && (
              <LoadingIndicator style={nftStyles.loadingIndicator} inverted={inverted} />
            )}
          </Text>
        </View>
      </TouchableOpacity>
      {subtitle && (
        <Text
          style={[
            styles.subtitle,
            inverted ? styles.subtitleInverted : {},
            multipleButtons ? nftStyles.noMargin : {},
          ]}
        >
          {subtitle}
        </Text>
      )}
    </>
  );
};

ConnectWalletButton.propTypes = {
  inverted: bool,
  text: string.isRequired,
  subtitle: string,
  additionalButtonText: string,
};

ConnectWalletButton.defaultProps = {
  inverted: false,
  subtitle: null,
  additionalButtonText: null,
};

export default ConnectWalletButton;
