import React, { useContext } from 'react';

import DrawerContext from 'src/contexts/DrawerContext';
import Drawer from 'src/components/Drawer';
import RightSidebar from 'src/components/RightSidebar';

const RightDrawer = () => {
  const { rightDrawerOpen, closeDrawer } = useContext(DrawerContext);

  return (
    <Drawer
      placement="right"
      width={300}
      height="100vh"
      open={rightDrawerOpen}
      onClose={() => closeDrawer('right')}
      handler={false}
      level={null}
      onHandleClick={() => closeDrawer('right')}
    >
      <RightSidebar />
    </Drawer>
  );
};

export default RightDrawer;
