import React from 'react';
import { View } from 'react-native';
import { number } from 'prop-types';
import { times } from 'lodash';

import StylePropType from 'src/util/StylePropType';
import styles from 'src/components/DotsPagination.styles';
import webStyles from 'src/components/DotsPagination.module.css';
import CircleSvg from 'assets/images/circle.svg';
import clsx from 'clsx';

/**
 * Component that helps a user understand where they are in a multi-step flow,
 * represented by multiple horizontally laid out dots with one of those dots
 * highlighted.
 *
 * Note: `currentIndex` is 1-indexed.
 */
const DotsPagination = ({ totalSteps, currentStep, style }) => (
  <View style={[styles.dotsContainer, style]}>
    {/* Acts as a sentinel for tests to indicate which dot is marked as active */}
    <View testID={`dot-pagination-${currentStep}/${totalSteps}`} style={{ display: 'none' }} />
    {times(totalSteps, (index) => {
      if (index === currentStep - 1) {
        return (
          <CircleSvg
            key={`full-dot-${index}`}
            className={clsx(webStyles.dotIcon, webStyles.fullDot)}
          />
        );
      }
      return (
        <CircleSvg
          key={`empty-dot-${index}`}
          className={clsx(webStyles.dotIcon, webStyles.emptyDot)}
        />
      );
    })}
  </View>
);

DotsPagination.defaultProps = {
  style: {},
};

DotsPagination.propTypes = {
  totalSteps: number.isRequired,
  currentStep: number.isRequired,
  style: StylePropType,
};

export default DotsPagination;
