const WebStorage = {
  get(key) {
    return new Promise((resolve, reject) => {
      if (window.localStorage) {
        try {
          resolve(localStorage.getItem(key));
        } catch (err) {
          reject(err);
        }
      } else {
        resolve(null);
      }
    }).catch((err) => {
      throw new Error(`[web-storage-wrapper] - ${err}`);
    });
  },
  set(key, value) {
    return new Promise((resolve, reject) => {
      if (window.localStorage) {
        try {
          resolve(localStorage.setItem(key, value));
        } catch (err) {
          reject(err);
        }
      } else {
        resolve(null);
      }
    }).catch((err) => {
      throw new Error(`[web-storage-wrapper] - ${err}`);
    });
  },
  del(key) {
    return new Promise((resolve, reject) => {
      if (window.localStorage) {
        try {
          resolve(localStorage.removeItem(key));
        } catch (err) {
          reject(err);
        }
      } else {
        resolve(null);
      }
    }).catch((err) => {
      throw new Error(`[web-storage-wrapper] - ${err}`);
    });
  },
  clear() {
    return new Promise((resolve, reject) => {
      if (window.localStorage) {
        try {
          resolve(localStorage.clear());
        } catch (err) {
          reject(err);
        }
      } else {
        resolve(null);
      }
    }).catch((err) => {
      throw new Error(`[web-storage-wrapper] - ${err}`);
    });
  },
};

export default WebStorage;
