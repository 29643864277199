import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  hidden: {
    display: 'none',
  },
  initialLoadingIndicator: {
    marginTop: 20,
  },
  refetchLoadingIndicator: {
    marginLeft: 10,
    height: 15,
    width: 15,
  },
  likesCount: {
    fontWeight: 600,
  },
  userRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  likesTimestamp: {
    paddingTop: 11,
    fontSize: 10,
    fontWeight: 600,
    lineHeight: 18,
    color: '#8A8A8E',
    whiteSpace: 'nowrap',
    textAlign: 'right',
    marginLeft: 10,
  },
});

export default styles;
