import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  noMargin: {
    marginBottom: 0,
  },
  loadingIndicator: {
    position: 'absolute',
    right: -20,
    top: 0,
    bottom: 0,
    marginVertical: 'auto',
    width: 14,
    height: 14,
  },
});

export default styles;
