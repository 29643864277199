import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  backDrop: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    cursor: 'default',
    zIndex: 1,
  },
  topContainer: {
    paddingBottom: 3,
    marginTop: 0,
  },
  topline: {
    textAlign: 'center',
    fontSize: 13,
    paddingBottom: 7,
    paddingTop: 12,
  },
  middleLine: {
    fontWeight: '600',
    textAlign: 'center',
    fontSize: 17,
    paddingBottom: 22,
  },
  bottomLine: {
    textAlign: 'center',
    fontSize: 17,
    paddingVertical: 11,
    color: '#0279FF',
  },
  cancel: {
    textAlign: 'center',
    fontSize: 17,
    paddingVertical: 11,
    color: '#0279FF',
  },
  base: {
    backgroundColor: 'white',
    width: '100%',
    minWidth: 250,
    height: '95%',
    minHeight: 50,
    borderRadius: 16,
    zIndex: 0,
    paddingTop: 10,
    alignContent: 'center',
    justifyContent: 'flex-end',
  },
  modalContent: {
    margin: 0,
  },
  boxContainer: {
    alignSelf: 'center',
    width: 270,
    height: 240,
    zIndex: 2,
  },
});

export default styles;
