import { ApolloLink } from '@apollo/client';
import { asyncMap } from '@apollo/client/utilities';

import { delay } from 'src/util/promiseUtils';

// Link that ensures that a mutation or query takes at least minDuration
// milliseconds to execute.
//
// minDuration is passed as a variable to the mutation or query.
export default class MinDurationLink extends ApolloLink {
  constructor() {
    super((operation, forward) => {
      const start = new Date();
      const { minDuration } = operation.variables;
      if (minDuration) {
        // eslint-disable-next-line no-param-reassign
        delete operation.variables.minDuration;
      }

      return asyncMap(forward(operation), async (response) => {
        const timeElapsed = new Date() - start;
        const timeLeft = minDuration - timeElapsed;

        if (timeLeft > 0) await delay(timeLeft);

        return response;
      });
    });
  }
}
