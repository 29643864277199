// Serializes a route name and set of parameters into a string from which they
// can be reconstructed.
//
// Returns a string of the form:
//
//   routeName?paramKey1=paramValue1&paramKey2=paramValue2
export function serializeNavigation(routeName, parameters = null) {
  if (!parameters) return routeName;
  const paramStr = Object.entries(parameters)
    .map(([key, value]) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');
  return `${routeName}?${paramStr}`;
}

// Deserializes a route and set of parameters from a string that was encoded
// with serializeNavigation().
//
// Returns an object of the form:
//
//   {
//     routeName: 'Home',
//     parameters: { paramKey1: 'paramValue1', paramKey2: 'paramValue2' }
//   }
export function deserializeNavigation(str) {
  const parts = decodeURI(str).split('?');

  switch (parts.length) {
    case 1:
      // There were no parameters.
      return {
        routeName: parts[0],
        parameters: null,
      };
    case 2: {
      const entriesAsArrays = parts[1].split('&').map((serializedEntry) => {
        const paramParts = serializedEntry.split('=');
        if (paramParts.length !== 2) {
          throw new Error(`Failed to parse invalid route: '${str}'`);
        }
        return [decodeURIComponent(paramParts[0]), decodeURIComponent(paramParts[1])];
      });
      return {
        routeName: parts[0],
        parameters: Object.fromEntries(entriesAsArrays),
      };
    }
    default:
      throw new Error(`Failed to parse invalid route: '${str}'`);
  }
}

export const getShortURL = (url) => new URL(url).hostname;

export const getCurrentRoute = (navigationState) => {
  if (!navigationState) {
    return null;
  }

  const route = navigationState.routes[navigationState.index];
  // Dive into nested navigators
  if (route.routes) {
    return getCurrentRoute(route);
  }

  return route;
};

export const getCurrentRouteName = (navigationState) => {
  if (navigationState?.routeName) return navigationState.routeName;

  const route = getCurrentRoute(navigationState);
  if (route) {
    return route.routeName;
  }
  return null;
};

const getCurrentRouteParams = (navigationState) => {
  const route = getCurrentRoute(navigationState);
  if (route?.params) {
    return route.params;
  }
  return {};
};

export const getCurrentRouteForRedirect = (navigationState) => {
  const routeName = getCurrentRouteName(navigationState);
  const params = getCurrentRouteParams(navigationState);

  return serializeNavigation(routeName, { ...params });
};

export const getShareUrl = (username, publicUid, time = null) => {
  let url = `${window.location.origin}/${username}/p/${publicUid}/share`;
  if (time !== null) url += `?t=${time}`;

  return url;
};
