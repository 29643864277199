import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  contentContainer: {
    alignSelf: 'center',
    paddingHorizontal: 40,
    width: '100%',
    maxWidth: 448,
    marginBottom: 40,
  },
  centered: {
    textAlign: 'center',
  },
});

export default styles;
