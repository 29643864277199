import React, { Suspense, useContext } from 'react';

import ConnectWalletFlowContext from 'src/contexts/ConnectWalletFlowContext';
import retryingLazy from 'src/util/retryingLazy';

const ConnectWalletFlow = retryingLazy(() =>
  import('src/components/ConnectWalletFlow/ConnectWalletFlow'),
);

const ConnectWalletFlowRoot = () => {
  const { connectWalletFlowOpen, flowProps } = useContext(ConnectWalletFlowContext);

  return (
    <>
      {connectWalletFlowOpen && (
        <Suspense fallback={null}>
          <ConnectWalletFlow {...flowProps} />
        </Suspense>
      )}
    </>
  );
};

export default ConnectWalletFlowRoot;
