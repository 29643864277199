import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  sidebarBody: {
    backgroundColor: '#222327',
    height: '100%',
    paddingVertical: 12,
    paddingHorizontal: 3,
  },
  closeBtnRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    height: 30,
    marginBottom: 10,
  },
  closeBtn: {
    maxHeight: 30,
    backgroundColor: '#222327',
  },
  list: {
    width: '100%',
  },
  listItem: {
    backgroundColor: '#222327',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  listItemText: {
    fontSize: 13,
    fontWeight: '700',
    color: '#B6B6B6',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
  },
  linksRow: {
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'left',
    marginLeft: 49,
    marginTop: 20,
  },
  link: {
    fontSize: 12,
    color: '#939393',
    fontWeight: '500',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  modalText: {
    textAlign: 'center',
    fontSize: 13,
    paddingTop: 6,
  },
});
export default styles;
