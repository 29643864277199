/* eslint-disable react/no-danger */
import React from 'react';
import { string } from 'prop-types';
import { View } from 'react-native';
import styles from 'src/components/Features/FeatureModal/FeatureVideo.web.styles';
import StylePropType from 'src/util/StylePropType';

const FeatureVideo = ({ videoHtml, style }) => (
  <View style={[styles.container, style]}>
    <div dangerouslySetInnerHTML={{ __html: videoHtml }} />
  </View>
);

FeatureVideo.propTypes = {
  videoHtml: string.isRequired,
  style: StylePropType,
};

FeatureVideo.defaultProps = {
  style: {},
};

export default FeatureVideo;
