import React, { useState } from 'react';
import { Text, View, ScrollView } from 'react-native';
import { func } from 'prop-types';
import { get } from 'lodash';
import { useQuery, useMutation } from '@apollo/client';

import { GET_CURRENT_USER } from 'src/graphql/User';
import { collectionShape } from 'src/constants/Shapes';

import { TouchableWithoutFeedback, TouchableOpacity } from 'src/components/Touchable';
import LoadingIndicator from 'src/components/LoadingIndicator';
import ErrorMessage from 'src/components/ErrorMessage';
import Modal from 'src/components/Modal';
import Switch from 'src/components/Switch';
import HorizontalHairlineStroke from 'src/components/HorizontalHairlineStroke';
import CharacterLimitedInputField from 'src/components/CharacterLimitedInputField';
import styles from 'src/components/Collection/EditCollectionModal.styles';
import webStyles from 'src/components/Collection/EditCollectionModal.module.css';
import {
  UPDATE_COLLECTION_MUTATION,
  CREATE_COLLECTION_MUTATION,
  GET_COLLECTIONS_FOR_ARTIST,
} from 'src/graphql/Collection';
import SmallBackArrowSvg from 'assets/images/small-back-arrow.svg';
import CrossSvg from 'assets/images/cross.svg';
import InformationIconSvg from 'assets/images/information-icon.svg';

const EditCollectionModal = ({ collection, onBack, onSave, onClose }) => {
  const [name, setName] = useState(get(collection, 'name', ''));
  const [description, setDescription] = useState(get(collection, 'description', ''));
  const [slug, setSlug] = useState(get(collection, 'slug', ''));
  const [isPublic, setIsPublic] = useState(get(collection, 'public', false));
  const [errors, setErrors] = useState([]);

  const {
    data: { me },
  } = useQuery(GET_CURRENT_USER);

  const [updateCollection, { loading: updateCollectionLoading }] = useMutation(
    UPDATE_COLLECTION_MUTATION,
    {
      variables: {
        id: collection?.id,
        name,
        description,
        public: isPublic,
      },
      refetchQueries: [{ query: GET_COLLECTIONS_FOR_ARTIST, variables: { username: me.username } }],
      awaitRefetchQueries: true,
    },
  );

  const [createCollection, { loading: createCollectionLoading }] = useMutation(
    CREATE_COLLECTION_MUTATION,
    {
      variables: {
        name,
        description,
        slug,
        public: isPublic,
      },
      refetchQueries: [{ query: GET_COLLECTIONS_FOR_ARTIST, variables: { username: me.username } }],
      awaitRefetchQueries: true,
    },
  );
  const showLoading = updateCollectionLoading || createCollectionLoading;

  const handleSubmit = async () => {
    try {
      setErrors([]);

      if (!name.trim()) {
        setErrors(['Title cannot be empty.']);
        return;
      }
      if (!slug.trim()) {
        setErrors(['Slug cannot be empty.']);
        return;
      }

      if (collection) {
        const {
          data: {
            updateCollection: { errors: formErrors },
          },
        } = await updateCollection({ variables: { minDuration: 1000 } });
        if (formErrors?.length) {
          setErrors(formErrors);
          return;
        }
        onSave(`Collection "${name}" has been updated.`, collection.id);
      } else {
        const {
          data: {
            createCollection: { collection: createdCollection, errors: formErrors },
          },
        } = await createCollection({ variables: { minDuration: 1000 } });

        if (formErrors?.length) {
          setErrors(formErrors);
          return;
        }
        onSave(`Collection "${name}" has been added.`, createdCollection.id);
      }
    } catch (err) {
      setErrors([err]);
    }
  };

  return (
    <Modal
      style={styles.modal}
      backdropTransitionInTiming={1}
      backdropTransitionOutTiming={1}
      animationInTiming={1}
      animationOutTiming={1}
      testID="collection-modal"
    >
      <ScrollView contentContainerStyle={styles.contentContainerStyle}>
        <TouchableWithoutFeedback style={styles.backDrop} onPress={onClose} />
        <View style={[styles.sizeBox, styles.sizeBoxTop]} />
        <View style={styles.container}>
          <View style={styles.content}>
            <View style={styles.navigation}>
              {onBack ? (
                <TouchableOpacity style={styles.button} onPress={onBack} testID="back-btn">
                  <SmallBackArrowSvg className={webStyles.backIcon} />
                </TouchableOpacity>
              ) : (
                <TouchableOpacity style={styles.button} onPress={onClose} testID="close-btn">
                  <CrossSvg className={webStyles.closeIcon} />
                </TouchableOpacity>
              )}
              <Text style={styles.title}>
                {collection ? 'Edit Collection' : 'Create Collection'}
              </Text>
              {showLoading ? (
                <View style={styles.loadingContainer}>
                  <LoadingIndicator style={styles.loading} />
                </View>
              ) : (
                <TouchableOpacity
                  onPress={handleSubmit}
                  style={[styles.button, styles.actionButton]}
                >
                  <Text style={styles.actionText}>Save</Text>
                </TouchableOpacity>
              )}
            </View>
            <HorizontalHairlineStroke color="#C4C4C4" />
            <View style={styles.inputContainer}>
              <Text style={styles.label}>Title</Text>
              <CharacterLimitedInputField
                text={name}
                onChange={setName}
                maxLength={70}
                placeholder="Give the collection a title..."
                inputStyle={styles.input}
                numberOfLines={2}
                disabled={showLoading}
                testID="name-input"
              />
            </View>
            <HorizontalHairlineStroke color="#C4C4C4" />
            <View style={styles.inputContainer}>
              <Text style={styles.label}>Description</Text>
              <CharacterLimitedInputField
                text={description}
                onChange={setDescription}
                maxLength={200}
                placeholder="Give it a description (optional)..."
                inputStyle={styles.input}
                numberOfLines={5}
                disabled={showLoading}
                testID="description-input"
              />
            </View>
            <HorizontalHairlineStroke color="#C4C4C4" />
            <View style={styles.inputContainer}>
              <Text style={styles.label}>{collection ? "Slug (Can't change this)" : 'Slug'}</Text>
              <CharacterLimitedInputField
                disabled={!!collection || showLoading}
                text={slug}
                onChange={setSlug}
                maxLength={40}
                placeholder="Give the collection a slug..."
                inputStyle={[styles.input, collection ? styles.disabledInput : {}]}
                numberOfLines={1}
                testID="slug-input"
              />
            </View>
            <HorizontalHairlineStroke color="#C4C4C4" />
            <View style={[styles.inputContainer, styles.row]}>
              <Text style={[styles.label, styles.publicLabel]}>Public</Text>
              <Switch onValueChange={setIsPublic} value={isPublic} style={styles.switch} />
            </View>
            <HorizontalHairlineStroke color="#C4C4C4" />
            {!!errors.length && (
              <View style={styles.inputContainer}>
                {errors.map((error) => (
                  <ErrorMessage key={error} text={error} />
                ))}
                <HorizontalHairlineStroke color="#C4C4C4" />
              </View>
            )}
            <View style={styles.informationContainer}>
              <InformationIconSvg className={webStyles.informationIcon} />
              <Text style={styles.informationText}>
                A collection is not visible to visitors of your channel until there is at least one
                published post in it.
              </Text>
            </View>
          </View>
        </View>
        <View style={[styles.sizeBox, styles.sizeBoxBottom]} />
      </ScrollView>
    </Modal>
  );
};

EditCollectionModal.propTypes = {
  collection: collectionShape,
  onBack: func.isRequired,
  onClose: func.isRequired,
  onSave: func,
};

EditCollectionModal.defaultProps = {
  collection: undefined,
  onSave: () => {},
};

export default EditCollectionModal;
