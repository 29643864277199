import { StyleSheet } from 'react-native';

import { zIndex } from 'src/styles/zIndex';

const styles = StyleSheet.create({
  wrapper: {
    paddingBottom: 20,
    flex: 1,
  },
  popupContainer: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginTop: 0,
  },
  popupContainerBreak: {
    borderRadius: 12,
  },
  postOptionsContainer: {
    marginTop: 36,
    marginHorizontal: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  postOptionsDivider: {
    width: 16,
  },
  loadingOverlay: {
    position: 'absolute',
    top: 55,
    left: 0,
    width: '100%',
    height: 'calc(100% - 55px)',
    backgroundColor: '#FFF',
    opacity: '67%',
    zIndex: zIndex.modalNavigation,
  },
  overlay: {
    position: 'absolute',
    top: 94,
    left: 0,
    width: '100%',
    height: 'calc(100% - 94px)',
    backgroundColor: '#00000080',
    zIndex: zIndex.modalNavigation,
    cursor: 'default',
  },
  cancelUploadText: {
    marginBottom: 12,
  },
  errorMessage: {
    justifyContent: 'center',
    marginTop: 16,
    marginBottom: 0,
    marginHorizontal: 20,
  },
});

export default styles;
