import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  buttonContainer: {
    flex: 1,
    width: '100%',
    minHeight: 46,
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  featureOptionPriority: {
    fontWeight: 600,
  },
  featureOptionDefault: {
    fontWeight: 400,
  },
  buttonText: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: 17,
    lineHeight: 22,
    color: '#0279FF',
  },

  hairline: {
    width: '100%',
  },
});

export default styles;
