import React from 'react';
import { Animated } from 'react-native';
import { ENABLE_ANIMATIONS } from 'env';

const START_VALUE = 0.0;
const END_VALUE = 0.5;
const isInteraction = false;
const DURATION = 500;
const useNativeDriver = false;

export default class LoadingView extends React.Component {
  constructor(props) {
    super(props);

    this.animation = new Animated.Value(0);
  }

  componentDidMount() {
    this.start();
  }

  start() {
    if (!ENABLE_ANIMATIONS) return;

    Animated.loop(
      Animated.sequence([
        Animated.timing(this.animation, {
          duration: DURATION,
          isInteraction,
          toValue: END_VALUE,
          useNativeDriver,
        }),
        Animated.timing(this.animation, {
          duration: DURATION,
          isInteraction,
          toValue: START_VALUE,
          useNativeDriver,
        }),
      ]),
    ).start();
  }

  render() {
    const { children, style, borderRadius } = this.props;
    const animationStyle = {
      backgroundColor: 'gray',
      height: '100%',
      width: '100%',
      opacity: this.animation,
      position: 'absolute',
      top: 0,
      borderRadius,
    };

    return (
      <Animated.View style={[style, { borderRadius }]}>
        <Animated.View style={[animationStyle, { borderRadius }]}>{children}</Animated.View>
      </Animated.View>
    );
  }
}
