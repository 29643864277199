import React from 'react';
import BaseDrawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';

import 'src/components/Drawer.web.css';

const Drawer = (props) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <BaseDrawer {...props} />;
};

export default Drawer;
