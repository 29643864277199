import React from 'react';
import { Text } from 'react-native';

import ChildrenPropType from 'src/util/ChildrenPropType';
import StylePropType from 'src/util/StylePropType';
import styles from 'src/components/FlowModal/FlowDescriptionSmall.styles';

const FlowDescriptionSmall = ({ children, style }) => (
  <Text style={[styles.text, style]}>{children}</Text>
);

FlowDescriptionSmall.defaultProps = {
  style: {},
};

FlowDescriptionSmall.propTypes = {
  children: ChildrenPropType.isRequired,
  style: StylePropType,
};

export default FlowDescriptionSmall;
