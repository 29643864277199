import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: 270,
    marginBottom: 20,
  },
});

export default styles;
