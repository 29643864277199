import React from 'react';
import { Text } from 'react-native';
import { string, func, bool } from 'prop-types';

import styles from 'src/components/PostInformationModal/Tab.styles';
import webStyles from 'src/components/PostInformationModal/Tab.module.css';
import { TouchableOpacity } from 'src/components/Touchable';
import clsx from 'clsx';

const Tabs = ({ Icon, text, selected, onPress }) => (
  <TouchableOpacity
    style={[styles.tab, selected ? [styles.selected, styles.selectedBorder] : {}]}
    onPress={onPress}
  >
    <Icon className={clsx(webStyles.icon, selected && webStyles.selected)} />
    <Text style={[styles.text, selected ? styles.selected : {}]}>{text}</Text>
  </TouchableOpacity>
);

Tabs.propTypes = {
  text: string.isRequired,
  selected: bool.isRequired,
  onPress: func.isRequired,
};

export default Tabs;
