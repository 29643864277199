import { StyleSheet } from 'react-native';

import Colors from 'src/styles/colors';

const styles = StyleSheet.create({
  containerStyle: {
    width: 'calc(100% + 2px)',
    marginLeft: -1,
  },
  flagButtonContainer: {
    background: '#fbfbfb',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  flagButton: {
    height: '100%',
  },
  selectorContainer: {
    height: 212,
    boxShadow: '0px 16px 16px -16px #15191f82',
    paddingBottom: 8,
    borderColor: Colors.darkGray,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    zIndex: 1,
    backgroundColor: '#ffffff',
  },
});

export default styles;
