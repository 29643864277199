import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  centeringColumn: {
    alignItems: 'center',
    paddingHorizontal: 30,
  },
  shieldRow: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 45,
    marginTop: 36,
    marginBottom: 15,
    flex: 1,
    zIndex: 1,
  },
});

export default styles;
