// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Footer-module__wavesIcon__qtxm9 {\n    margin: 21px 0 42px 0;\n    width: 32px;\n    height: 20px;\n    opacity: 86%;\n}\n", ""]);
// Exports
exports.locals = {
	"wavesIcon": "Footer-module__wavesIcon__qtxm9"
};
module.exports = exports;
