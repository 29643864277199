import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    flex: 1,
    maxWidth: 364,
    minWidth: 250,
    paddingHorizontal: 20,
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: 68,
    marginTop: 16,
  },
  iconRow: {
    marginBottom: 12,
    minHeight: 47,
    alignSelf: 'center',
  },
  header: {
    marginBottom: 12,
    textAlign: 'center',
  },
  username: {
    textAlign: 'center',
  },
  button: {
    marginTop: 24,
  },
});

export default styles;
