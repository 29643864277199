import { v4 as uuidv4 } from 'uuid';

import PlatformStorage from 'src/storage';

export const isArtist = (user) => !!user?.roles?.includes('artist');

const LOGGED_OUT_USER_UUID_LOCAL_STORAGE_KEY = 'logged_out_user_uuid';

export const getLoggedOutUserUUID = async () => {
  const uuid = await PlatformStorage.get(LOGGED_OUT_USER_UUID_LOCAL_STORAGE_KEY);
  if (uuid) {
    return uuid;
  }

  const newUUID = uuidv4();
  PlatformStorage.set(LOGGED_OUT_USER_UUID_LOCAL_STORAGE_KEY, newUUID);
  return newUUID;
};

export const isPrerenderSession = () => navigator.userAgent.includes('Prerender');
