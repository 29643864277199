import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  text: {
    fontSize: 34,
    fontWeight: 'bold',
    lineHeight: 36,
    color: '#14171A',
    marginBottom: 24,
  },
});

export default styles;
