import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';

import { GET_CURRENT_USER, GET_USER } from 'src/graphql/User';
import AuthFlowContext from 'src/contexts/AuthFlowContext';
import AuthFlow from 'src/components/AuthFlow/AuthFlow';
import { withNavigation } from 'react-navigation';

const AuthFlowRoot = ({ navigation }) => {
  const username = navigation.getParam('username');
  const {
    toggleAuthFlow,
    authState: {
      authFlowOpen,
      defaultsToSignUp,
      authStep,
      showPaymentOptions,
      redirectUrl,
      showLoggedOutMessage,
      onClose,
      animationInTiming,
      totalSteps,
    },
  } = useContext(AuthFlowContext);
  const { data: { me } = {} } = useQuery(GET_CURRENT_USER);
  const { data: { user: artist } = {}, loading: loadingArtist } = useQuery(GET_USER, {
    variables: { username },
    skip: !username,
  });

  const handleClose = () => {
    toggleAuthFlow({});
    if (onClose) onClose();
  };

  return (
    <>
      {!me && authFlowOpen && !loadingArtist && (
        <AuthFlow
          artistUsername={username}
          artistName={artist?.realName}
          onClose={handleClose}
          redirectUrl={redirectUrl}
          firstStep={authStep}
          totalSteps={totalSteps}
          showPaymentOptions={showPaymentOptions}
          defaultsToSignUp={defaultsToSignUp}
          showLoggedOutMessage={showLoggedOutMessage}
          animationInTiming={animationInTiming}
          navigation={navigation}
        />
      )}
    </>
  );
};

export default withNavigation(AuthFlowRoot);
