import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    marginTop: 11,
  },
  text: {
    color: '#7A7A7B',
    fontSize: 14,
    lineHeight: 18,
    marginBottom: 7,
  },
  timezone: {
    marginTop: 7,
    marginBottom: 0,
  },
});

export default styles;
