import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  tabsContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 32,
  },
  option: {
    flex: 1,
    height: 44,
    backgroundColor: '#F8F8F8',
    borderRadius: 8,
    textAlign: 'center',
  },
  firstOption: {
    marginRight: 10,
  },
  active: {
    backgroundColor: '#307BF6',
  },
  text: {
    margin: 'auto',
    fontWeight: 600,
    fontSize: 17,
    color: '#929292',
    textTransform: 'capitalize',
  },
  activeText: {
    color: '#FFFFFF',
  },
});

export default styles;
