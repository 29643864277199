import React, { useState } from 'react';
import { Text } from 'react-native';
import { func, number } from 'prop-types';
import { useQuery } from '@apollo/client';
import { find, truncate } from 'lodash';

import { TouchableOpacity } from 'src/components/Touchable';
import SelectCollectionFlow from 'src/components/SelectCollectionFlow/SelectCollectionFlow';
import SvgImage from 'src/components/SvgImage';
import { GET_COLLECTIONS_FOR_ARTIST } from 'src/graphql/Collection';
import { GET_CURRENT_USER } from 'src/graphql/User';
import styles from 'src/components/PostModal/PostToButton.styles';

const PostToButton = ({ collectionId, onChange }) => {
  const [selectCollectionFlowOpen, setSelectCollectionFlowOpen] = useState(false);

  const {
    data: { me },
  } = useQuery(GET_CURRENT_USER);

  const { data: { collections = [] } = {} } = useQuery(GET_COLLECTIONS_FOR_ARTIST, {
    variables: { username: me.username },
  });

  const getCollectionName = () => {
    const collection = find(collections, { id: collectionId });
    if (collection) {
      return truncate(collection.name, { length: 15 });
    }

    return 'Create a collection';
  };

  return (
    <>
      <TouchableOpacity style={styles.container} onPress={() => setSelectCollectionFlowOpen(true)}>
        <Text style={styles.postToText}>Post to:</Text>
        {collectionId ? (
          <Text style={styles.optionSelected}>{getCollectionName()}</Text>
        ) : (
          <Text style={[styles.optionSelected, styles.defaultOption]}>Create first collection</Text>
        )}
        <SvgImage
          alt="Drop down arrow"
          source={require('assets/images/drop-down-arrow.svg?url')}
          style={styles.dropdownIcon}
        />
      </TouchableOpacity>
      {selectCollectionFlowOpen && (
        <SelectCollectionFlow
          selectedCollectionId={collectionId}
          onChange={onChange}
          onClose={() => setSelectCollectionFlowOpen(false)}
        />
      )}
    </>
  );
};

PostToButton.propTypes = {
  collectionId: number,
  onChange: func.isRequired,
};

PostToButton.defaultProps = {
  collectionId: undefined,
};

export default PostToButton;
