const darkGray = '#222327';
const loadingColor = '#D8D8D8';
const white = '#fafafa';
const accentBlue = '#0279FF';
const settingsLightGray = '#ebebeb';
const Colors = {
  primary: white,
  secondary: darkGray,
  tertiary: white,
  loading: loadingColor,
  accentColor: accentBlue,
  error: '#ED1240',
  settingsLight: settingsLightGray,
  mainBackground: '#F5F5F5',
  defaultCenterColumnBackground: '#FFF',
  defaultCenterColumnBorder: '#EFEFEF',
  accountCenterColumnBorder: '#DCDBE0',
  // We use a brighter, happier background color on the home screen.
  homeCenterColumnBackground: '#FDFDFD',
  homeMainBackground: '#F5F5F5',
  // We use a darker background color on account settings to give more
  // differentiation to the white content rows.
  accountCenterColumnBackground: '#F7F7F7',
  inputBackgroundColor: '#FBFBFB',
  inputPlaceholderColor: '#3C3C4399',
  darkGray: '#3A3C3F',
  transparent: 'transparent',
  footer: '#222327',
  selectedButton: 'rgba(0, 0, 0, 0.09)',
};

export default Colors;
