import React from 'react';
import { func, string } from 'prop-types';
import { View } from 'react-native';

import styles from 'src/components/EditUsernameFlow/Finish.styles';
import webStyles from 'src/components/EditUsernameFlow/Finish.module.css';
import FlowHeader from 'src/components/FlowModal/FlowHeader';
import FlowButtonFlat from 'src/components/FlowModal/FlowButtonFlat';
import FlowDescriptionLarge from 'src/components/FlowModal/FlowDescriptionLarge';
import PaymentConfirmationSvg from 'assets/images/payment-confirmation.svg';

const Finish = ({ onClose, username }) => (
  <View style={styles.wrapper} testID="edit-username-finish">
    <View style={styles.iconRow}>
      <PaymentConfirmationSvg className={webStyles.confirmationIcon} />
    </View>
    <FlowHeader style={styles.header}>Cool!</FlowHeader>
    <FlowDescriptionLarge style={styles.username}>
      You are now @{username}. Hey!
    </FlowDescriptionLarge>
    <FlowButtonFlat onPress={onClose} style={styles.button}>
      Continue
    </FlowButtonFlat>
  </View>
);

Finish.propTypes = {
  onClose: func.isRequired,
  username: string.isRequired,
};

export default Finish;
