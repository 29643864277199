import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  sidebarBody: {
    backgroundColor: '#FAFAFA',
    height: '100%',
  },
  header: {
    backgroundColor: '#222327',
    justifyContent: 'flex-start',
  },
  closeBtnRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    maxHeight: 60,
  },
  closeBtn: {
    backgroundColor: '#21222700',
  },
  userRow: {
    alignItems: 'center',
  },
  userNameBox: {
    flexDirection: 'row',
    alignitems: 'center',
  },
  userName: {
    fontSize: 17,
    fontWeight: 600,
    lineHeight: 19,
    marginTop: 12,
    marginBottom: 12,
    marginRight: 8,
    marginLeft: 17,
    color: '#F2F2F2',
    letterSpacing: '0.01em',
  },
  subscribedChannelList: {
    paddingLeft: 15,
    paddingTop: 20,
    paddingBottom: 6,
  },
  editSettingsLink: {
    marginLeft: 'auto',
    marginRight: 20,
    color: '#3EA5FF',
    cursor: 'pointer',
    fontSize: 13,
    letterSpacing: 0.1,
    textDecorationLine: 'none',
  },
  whatsNewRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 12,
    marginBottom: 8,
    lineHeight: 20,
  },
  whatsNewBox: {
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
  },
  whatsNewText: {
    color: '#C0C1C1',
    fontSize: 13,
    fontWeight: 600,
  },
  bellIcon: {
    width: 20,
    height: 20,
    marginRight: 3,
  },
  recentlyViewedContainer: {
    marginLeft: 18,
  },
  recentlyViewed: {
    alignSelf: 'start',
  },
  channelList: {
    paddingBottom: 20,
  },
});
export default styles;
