import React from 'react';
import { Image, Text, View } from 'react-native';
import { bool, string } from 'prop-types';

import styles from 'src/components/ChannelHeader/SubscribeButton.styles';
import nftStyles from 'src/components/ChannelHeader/SecondarySubscribeButton.styles';
import WithFeatureExplanation from 'src/components/hocs/WithFeatureExplanation';
import { TouchableOpacity } from 'src/components/Touchable';

const SecondarySubscribeButton = ({
  inverted,
  text,
  subtitle,
  featureUid,
  additionalButtonText,
  icon,
  previewUid,
}) => {
  const multipleButtons = !!additionalButtonText;

  const handleOnPress = () => {
    const videoElement = document.getElementById(previewUid);
    if (videoElement) {
      videoElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // TODO(charlie): Memoize this (somewhat complicated) component.
  // eslint-disable-next-line react/no-unstable-nested-components
  const Component = ({ openFeatureExplanation }) => (
    <>
      <TouchableOpacity
        style={[
          styles.container,
          inverted ? styles.inverted : {},
          subtitle ? styles.withSubtitle : {},
          multipleButtons ? nftStyles.noMargin : {},
        ]}
        onPress={featureUid ? openFeatureExplanation : handleOnPress}
      >
        <View style={styles.buttonContainer}>
          {icon && <Image source={{ uri: icon }} style={styles.icon} />}
          <Text style={[styles.text, inverted ? styles.invertedText : {}]}>{text}</Text>
        </View>
      </TouchableOpacity>
      {subtitle && (
        <Text
          style={[
            styles.subtitle,
            inverted ? styles.subtitleInverted : {},
            multipleButtons ? nftStyles.noMargin : {},
          ]}
        >
          {subtitle}
        </Text>
      )}
    </>
  );

  const HocComponent = WithFeatureExplanation(featureUid)(Component);

  return <HocComponent />;
};

SecondarySubscribeButton.propTypes = {
  inverted: bool,
  text: string.isRequired,
  subtitle: string,
  featureUid: string.isRequired,
  previewUid: string,
  additionalButtonText: string,
};

SecondarySubscribeButton.defaultProps = {
  inverted: false,
  subtitle: null,
  additionalButtonText: null,
};

export default SecondarySubscribeButton;
