import React from 'react';
import DocumentMeta from 'react-document-meta';

import ChildrenPropType from 'src/util/ChildrenPropType';
import { isIOS } from 'src/util/functionUtils';

const DefaultMetaTags = ({ children }) => {
  // This disables iOS's "auto-zoom" into inputs with a font size of less than
  // 16px, which it doesn't do for system modals. We disable this so that our
  // own modals which emulate iOS's feel native.
  //
  // Unfortunately, this same meta tag disables zooming altogether on Android,
  // so we need to apply it only on iOS.
  //
  // See:
  // https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone#comment90641872_46254706
  const viewport = isIOS()
    ? 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
    : 'width=device-width, initial-scale=1.0';

  const metaTags = {
    title: 'Channels',
    description: 'Share your gifts. Change the world.',
    meta: {
      property: {
        'og:site_name': 'Channels',
        'og:locale': 'en_US',
        'og:type': 'website',
        'og:title': 'Channels',
        'og:description': 'Share your gifts. Change the world.',
        'og:image':
          'https://uploads-ssl.webflow.com/5ea1d4a52fca7bf10826df37/5f34466b77a4b33548b354d5_channels%20app%20icon.png',
        'og:url': window.location.href,
      },
      name: {
        viewport,
      },
    },
  };

  return <DocumentMeta {...metaTags}>{children}</DocumentMeta>;
};

DefaultMetaTags.propTypes = {
  children: ChildrenPropType.isRequired,
};

export default DefaultMetaTags;
