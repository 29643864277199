import React from 'react';
import { func, string } from 'prop-types';
import { View } from 'react-native';

import StylePropType from 'src/util/StylePropType';
import styles from 'src/components/PaymentOptions.styles';
import SvgImage from 'src/components/SvgImage';
import FlowDescriptionLarge from 'src/components/FlowModal/FlowDescriptionLarge';
import { TouchableWithoutFeedback } from 'src/components/Touchable';

const sources = {
  apple: require('assets/images/apple-pay.svg?url'),
  google: require('assets/images/google-pay.svg?url'),
  card: require('assets/images/credit-card.svg?url'),
};

const PaymentOptions = ({ style, onPress, title }) => {
  const handleOnPress = () => {
    if (onPress) onPress();
  };

  return (
    <View style={[styles.paymentBox, style]}>
      <FlowDescriptionLarge style={styles.paymentText}>{title}</FlowDescriptionLarge>
      <View style={styles.paymentOptions}>
        {Object.keys(sources).map((paymentType) => (
          <TouchableWithoutFeedback
            key={paymentType}
            onPress={handleOnPress}
            testID={`${paymentType}-payment-icon`}
            style={onPress ? {} : styles.notClickable}
          >
            <SvgImage
              source={sources[paymentType]}
              style={styles.paymentIcon}
              alt={`${paymentType} icon`}
            />
          </TouchableWithoutFeedback>
        ))}
      </View>
    </View>
  );
};

PaymentOptions.defaultProps = {
  onPress: null,
  style: {},
  title: 'Accepted Payment Methods:',
};

PaymentOptions.propTypes = {
  style: StylePropType,
  onPress: func,
  title: string,
};

export default PaymentOptions;
