import React, { useContext, useState } from 'react';
import { Text, View, ScrollView } from 'react-native';
import PropTypes from 'prop-types';
import { withNavigation } from 'react-navigation';
import { useQuery } from '@apollo/client';

import { isArtist } from 'src/util/userUtils';
import FeaturesContext from 'src/contexts/FeaturesContext';
import DrawerContext from 'src/contexts/DrawerContext';
import { GET_SUBSCRIBED_ARTISTS, GET_CURRENT_USER, GET_AVAILABLE_ARTISTS } from 'src/graphql/User';
import styles from 'src/components/RightSidebar.styles';
import EditUsernameFlow from 'src/components/EditUsernameFlow/EditUsernameFlow';
import Avatar from 'src/components/Avatar';
import { Button, TouchableOpacity, TouchableWithoutFeedback } from 'src/components/Touchable';
import ChannelPosterList from 'src/components/ChannelPosterList';
import SvgImage from 'src/components/SvgImage';
import CloseSvg from 'assets/images/close.svg';
import PencilSvg from 'assets/images/pencil.svg';
import webStyles from 'src/components/RightSidebar.module.css';
import { GET_FEATURES } from 'src/graphql/Feature';

const RightSidebar = ({ navigation }) => {
  const { closeDrawer } = useContext(DrawerContext);
  const { toggleFeatureModal } = useContext(FeaturesContext);
  const { data: { availableArtists = [] } = {} } = useQuery(GET_AVAILABLE_ARTISTS);
  const { data: { subscribedTo = [] } = {}, loading: loadingSubscribedTo } =
    useQuery(GET_SUBSCRIBED_ARTISTS);
  const { data: { me } = {} } = useQuery(GET_CURRENT_USER);
  const { data: { newFeatures } = {}, loading: featuresLoading } = useQuery(GET_FEATURES);

  if (!me) return null;

  const { username, thumbnailProfileImage: profileImage, realName } = me;
  const [editUsernameModalOpen, setEditUsernameModalOpen] = useState(false);

  const onEditSettings = () => {
    navigation.navigate('Settings');
    closeDrawer('right');
  };

  const closeRightSidebar = () => closeDrawer('right');

  const handleChannelPressed = (artistUsername) => {
    closeRightSidebar();
    navigation.push('User', { username: artistUsername });
  };

  const openFeatureModal = () => {
    closeRightSidebar();
    toggleFeatureModal();
  };

  const toggleEditUsernameModal = () => {
    setEditUsernameModalOpen(!editUsernameModalOpen);
    closeDrawer('right');
  };

  return (
    <View testID="right-sidebar" style={styles.sidebarBody}>
      <View style={styles.header}>
        <View style={styles.closeBtnRow}>
          <Button style={styles.closeBtn} onPress={closeRightSidebar}>
            <CloseSvg className={webStyles.closeIcon} />
          </Button>
        </View>
        <View style={styles.userRow}>
          <Avatar size="large" username={username} profileImage={profileImage} />
          <View style={styles.userNameBox} testID="username">
            <Text style={styles.userName} excludeFromFS>
              {isArtist(me) ? realName : `@${username}`}
            </Text>
            {!isArtist(me) && (
              <TouchableWithoutFeedback
                onPress={toggleEditUsernameModal}
                testID="edit-username-button"
              >
                <PencilSvg className={webStyles.editIcon} />
              </TouchableWithoutFeedback>
            )}
          </View>
        </View>
        <View style={styles.whatsNewRow}>
          <TouchableOpacity
            style={[
              styles.whatsNewBox,
              !featuresLoading && newFeatures.length > 0 ? {} : { visibility: 'hidden' },
            ]}
            onPress={openFeatureModal}
          >
            <SvgImage
              source={require('assets/images/bell-icon.svg?url')}
              style={styles.bellIcon}
              alt="Bell icon"
            />
            <Text style={styles.whatsNewText} testID="whats-new-button">
              What&apos;s new?
            </Text>
          </TouchableOpacity>
          <Text
            style={styles.editSettingsLink}
            testID="edit-settings-link"
            onPress={onEditSettings}
          >
            Edit Settings
          </Text>
        </View>
      </View>
      <ScrollView contentContainerStyle={styles.channelList}>
        <ChannelPosterList
          title="Your channels"
          emptyListText="You have no active channel subscriptions."
          style={styles.subscribedChannelList}
          onChannelPress={handleChannelPressed}
          channels={subscribedTo}
          loading={loadingSubscribedTo}
          testID="subscribed-channels-list"
        />
        {!!availableArtists.length && (
          <ChannelPosterList
            title="Recently viewed channels"
            style={styles.subscribedChannelList}
            onChannelPress={handleChannelPressed}
            channels={availableArtists}
            testID="recently-viewed-channels-list"
          />
        )}
      </ScrollView>
      {editUsernameModalOpen && <EditUsernameFlow onClose={toggleEditUsernameModal} />}
    </View>
  );
};

RightSidebar.propTypes = {
  navigation: PropTypes.shape({
    getParam: PropTypes.func.isRequired,
  }).isRequired,
};

export default withNavigation(RightSidebar);
