import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  loadingContainer: {
    width: 18,
    height: 18,
    margin: 'auto',
  },
  loading: {
    width: '100%',
    height: '100%',
    opacity: '72%',
  },
});

export default styles;
