// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EditCollectionModal-module__backIcon__2tMMl {\n    font-size: 24px;\n}\n.EditCollectionModal-module__closeIcon__p1TdZ {\n    font-size: 18px;\n}\n\n.EditCollectionModal-module__informationIcon__jHWIC {\n    color: #7A7A7B;\n    font-size: 20px;\n    margin-right: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"backIcon": "EditCollectionModal-module__backIcon__2tMMl",
	"closeIcon": "EditCollectionModal-module__closeIcon__p1TdZ",
	"informationIcon": "EditCollectionModal-module__informationIcon__jHWIC"
};
module.exports = exports;
