import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  button: {
    width: 40,
    height: 40,
    marginBottom: 3,
    marginRight: 2,
  },
});

export default styles;
