import { US } from 'src/constants/CountryCodes';

export const unformatPhoneNumber = (phoneNumber, country) =>
  phoneNumber.replace(`+${country.callingCode[0]}`, '').replace(/\D/g, '');

export const formatPhoneNumber = (phoneNumber, country = US, backspacePressed = false) => {
  const phone = unformatPhoneNumber(phoneNumber, country);

  if (country.cca2 === 'US' && phone.length <= 10) {
    const zip = phone.substring(0, 3);
    const middle = phone.substring(3, 6);
    const last = phone.substring(6, 10);

    if (phone.length > 6) {
      return `(${zip}) ${middle}-${last}`;
    }
    if (phone.length > 3) {
      return `(${zip}) ${middle}`;
    }
    if (phone.length === 3 && !backspacePressed) {
      return `(${zip})`;
    }
    if (phone.length > 0) {
      return `${zip}`;
    }
  }

  return phone;
};

export const addCountryCode = (phoneNumber, country) => `+${country.callingCode[0]} ${phoneNumber}`;

export const formatPhoneWithCountryCode = (phoneNumber, country) =>
  addCountryCode(formatPhoneNumber(phoneNumber, country), country);
