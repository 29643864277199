import React from 'react';
import { string, oneOf } from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';

import StylePropType from 'src/util/StylePropType';

const styles = StyleSheet.create({
  fullWidthHairlineStroke: {
    height: 1,
    marginLeft: 0,
    backgroundSize: '100% 1px',
  },
  strokeWithText: {
    height: 24,
    alignItems: 'center',
  },
  text: {
    width: 'fit-content',
    height: 'fit-content',
    position: 'relative',
    margin: 'auto',
    inset: 0,
    paddingHorizontal: 12,
    paddingBottom: 2,
    backgroundColor: '#FFFFFF',
    fontSize: 17,
    lineHeight: 22,
    color: '#3A3C3F',
  },
});

const HorizontalHairlineStroke = ({ color, direction, style, textStyle, text }) => {
  const colorStyle = {
    backgroundImage: `linear-gradient(to ${direction}, ${color} 0%, ${color} 66%, transparent 67%)`,
  };
  const lineStyles = [
    styles.fullWidthHairlineStroke,
    text ? styles.strokeWithText : {},
    colorStyle,
    style,
  ];

  // These CSS properties can't be set directly in RN, so we set a data property
  // that then maps to a CSS selector in index.html that sets the property.
  const data = { 'background-position-center': true, 'background-repeat-x': true };

  const textNode = text ? (
    <Text dataSet={data} style={[styles.text, textStyle]}>
      {text}
    </Text>
  ) : null;

  return (
    <View dataSet={data} style={lineStyles}>
      {textNode}
    </View>
  );
};

HorizontalHairlineStroke.propTypes = {
  color: string,
  direction: oneOf(['top', 'bottom']),
  style: StylePropType,
  text: string,
  textStyle: StylePropType,
};

HorizontalHairlineStroke.defaultProps = {
  color: '#DCDBE0',
  direction: 'bottom',
  style: {},
  textStyle: {},
};

export default HorizontalHairlineStroke;
