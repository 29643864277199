import { StyleSheet } from 'react-native';

import Colors from 'src/styles/colors';

const styles = StyleSheet.create({
  backDrop: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    cursor: 'default',
    zIndex: 1,
  },
  middleLine: {
    textAlign: 'center',
    fontSize: 13,
    paddingTop: 6,
  },
  middleLineParagraph: {
    textAlign: 'center',
    fontSize: 13,
    paddingTop: 9,
    lineHeight: 16,
  },
  buttonContainer: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 17,
    paddingBottom: 11,
    paddingTop: 11,
    color: '#0279FF',
  },
  confirmText: {
    fontWeight: '600',
  },
  confirmTextAlertModal: {
    color: '#FF0000',
  },
  base: {
    backgroundColor: 'rgba(253, 253, 253, 0.8)',
    backdropFilter: 'blur(56px)',
    width: '100%',
    minWidth: 250,
    height: '100%',
    minHeight: 50,
    borderRadius: 13,
    overflow: 'hidden',
    paddingTop: 0,
    paddingBottom: 0,
    zIndex: 0,
    alignContent: 'center',
    justifyContent: 'flex-end',
  },
  modalContent: {
    margin: 0,
  },
  boxContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    width: 270,
    zIndex: 2,
  },
  topContainer: {
    width: '100%',
    paddingHorizontal: 18,
    marginTop: 19,
    marginBottom: 2,
  },
  bottomContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    alignSelf: 'flex-end',
    flexWrap: 'wrap',
    width: '100%',
  },
  pressedButton: {
    backgroundColor: Colors.selectedButton,
  },
});

export default styles;
