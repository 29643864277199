import { gql } from '@apollo/client';

import { FeatureFragment } from 'src/graphql/Fragments';

const GET_FEATURES = gql`
  query GetFeatures {
    newFeatures: features(type: NEW_FEATURE) {
      ...FeatureInfo
    }
    featuresForLoggedOutUsers: features(type: LOGGED_OUT) {
      ...FeatureInfo
    }
    featuresForNewSubscribers: features(type: NEW_SUBSCRIBER) {
      ...FeatureInfo
    }
  }

  ${FeatureFragment}
`;

const GET_ACTION_FEATURES = gql`
  query GetActionFeatures {
    actionFeatures: features(type: ACTION) {
      ...FeatureInfo
    }
  }
  ${FeatureFragment}
`;

const CLEAR_UNSEEN_FEATURES = gql`
  mutation ClearUnseenFeatures($featureIds: [ID!]!) {
    clearUnseenFeatures(featureIds: $featureIds) {
      errors
    }
  }
`;

const SET_PRODUCT_TOUR_SEEN = gql`
  mutation SetProductTourSeen {
    setProductTourSeen {
      errors
    }
  }
`;

export { GET_FEATURES, GET_ACTION_FEATURES, CLEAR_UNSEEN_FEATURES, SET_PRODUCT_TOUR_SEEN };
