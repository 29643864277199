import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    paddingVertical: 11,
    backgroundColor: '#FAFAFA',
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  title: {
    fontSize: 17,
    color: '#333333',
    marginRight: 8,
  },
  switch: {
    height: 31,
    width: 51,
    marginLeft: 'auto',
  },
  helperText: {
    marginTop: 11,
    marginRight: 77,
    fontSize: 15,
    lineHeight: 20,
    color: '#7A7A7B',
  },
});

export default styles;
