import { useEffect } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus';

import { deserializeNavigation } from 'src/util/urlUtils';
import { getLoggedOutUserUUID } from 'src/util/userUtils';
import { resetNavigation } from 'src/util/navigationUtils';
import { hideSplashScreen } from 'src/util/splashScreenUtils';
import PlatformStorage from 'src/storage';
import {
  CONSUME_MAGIC_LINK,
  GET_CURRENT_USER,
  REPLACE_LOGGED_OUT_POST_VIEWS,
} from 'src/graphql/User';

const LinkLogInScreen = ({ navigation }) => {
  const client = useApolloClient();
  const [consumeMagicLink] = useMutation(CONSUME_MAGIC_LINK);
  const [replaceLoggedOutPostViews] = useMutation(REPLACE_LOGGED_OUT_POST_VIEWS);
  const { networkStatus: currentUserNetworkStatus } = useQuery(GET_CURRENT_USER);

  useEffect(() => {
    // TODO(charlie): client.resetStore() below only refetches completed
    // queries, not in-flight ones. Because of that, we can get into a "half
    // logged in state" if we hit this code in the middle of fetching the
    // current user elsewhere: we'll set the login token, but that current
    // user query won't refetch the current user right now, even if we request
    // it explicitly with getCurrentUser.refetch().
    //
    // I filed an issue about this on @apollo/client: we should remove this
    // when https://github.com/apollographql/apollo-client/issues/7589 is
    // resolved.
    if (isNetworkRequestInFlight(currentUserNetworkStatus)) return;

    async function getToken() {
      try {
        const {
          data: { consumeMagicLink: response },
        } = await consumeMagicLink({
          variables: { token: navigation.getParam('token') },
        });

        const {
          userToken: { token },
          redirectUrl,
        } = response;
        await PlatformStorage.set('token', token);
        // eslint-disable-next-line no-console
        console.log('[D] LinkLoginScreen: Token value is:', navigation.getParam('token'));
        await client.resetStore();

        await replaceLoggedOutPostViews({
          variables: { loggedOutUserUuid: await getLoggedOutUserUUID() },
        });

        if (redirectUrl) {
          const { routeName, parameters: params } = deserializeNavigation(redirectUrl);
          resetNavigation(navigation, { routeName, params });
        } else {
          resetNavigation(navigation, { routeName: 'Home' });
        }
      } catch (e) {
        // Don't take any action: this may be the result of a user clicking an
        // old login link.
        resetNavigation(navigation, { routeName: 'Home' });
      }
    }

    getToken();
  }, [currentUserNetworkStatus]);

  useEffect(() => {
    return () => {
      hideSplashScreen();
    };
  }, []);

  return null;
};

export default LinkLogInScreen;
