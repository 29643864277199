import { useQuery } from '@apollo/client';

import { GET_FEATURE_FLAGS } from 'src/graphql/FeatureFlag';

function isFeatureEnabled(features, featureName) {
  if (!features) return false;

  const feature = features.find((row) => row.name === featureName);
  if (!feature) return false;

  return feature.value;
}

const FeatureFlags = {
  FEATURE_EXPLANATION: 'feature_explanation',
  DEMO_POST_INTERACTIONS: 'demo_post_interactions',
  DEMO_CHANNELS: 'demo_channels',
  WHATSAPP_LOGIN: 'whatsapp_login',
  WARP_LOGIN: 'warp_login',
  ROOT_REDIRECT: 'root_redirect',
  BACK_ARROW_ON_CHANNELS: 'back_arrow_on_channels',
  ALPHA_WALLET_PROVIDERS: 'alpha_wallet_providers',
  TEST_WALLET_PROVIDER: 'test_wallet_provider',
  YOU_TUBE_EMBED: 'you_tube_embed',
  VIDEO_SEEK_HOTKEYS: 'video_seek_hotkeys',
};

// Queries the backend to determine whether a feature should be enabled.
//
// Sample usage:
//   const { loading: giftingLoading, enabled: giftingEnabled } = useFeatureFlag(FeatureFlags.GIFTING);
//   ...
//   return (
//     ...
//       { giftingEnabled && <SendGiftButton /> }
//     ...
//  );
function useFeatureFlag(name) {
  const { loading, data } = useQuery(GET_FEATURE_FLAGS);
  const enabled = !loading && data && isFeatureEnabled(data.featureFlags, name);

  return { loading, enabled };
}

// Component that only renders its children if the feature flag is enabled.
const WithFeatureFlag = ({ children, flag, fallback = null }) => {
  const { enabled, loading } = useFeatureFlag(flag);

  if (loading) return null;

  return enabled ? children : fallback;
};

export { isFeatureEnabled, FeatureFlags, useFeatureFlag, WithFeatureFlag };
