import React, { useState, createContext } from 'react';

const PostModalContext = createContext({
  openPostModal: () => {},
  closePostModal: () => {},
  postModalState: { postModalOpen: false, post: null },
});

export const PostModalContextProvider = ({ children }) => {
  const [postModalState, setPostModalState] = useState({
    postModalOpen: false,
    post: null,
  });

  const openPostModal = ({ post = null }) => {
    setPostModalState({
      postModalOpen: true,
      post,
    });
  };

  const closePostModal = () => {
    setPostModalState({
      postModalOpen: false,
      post: null,
    });
  };

  return (
    <PostModalContext.Provider
      value={{
        postModalState,
        openPostModal,
        closePostModal,
      }}
    >
      {children}
    </PostModalContext.Provider>
  );
};

export default PostModalContext;
