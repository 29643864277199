import React from 'react';
import { string, bool } from 'prop-types';

import webStyles from 'src/components/Notification.module.css';
import clsx from 'clsx';

const Notification = ({ text, visible }) => (
  <div className={clsx(webStyles.notification, visible && webStyles.visible)}>{text}</div>
);

Notification.propTypes = {
  text: string.isRequired,
  visible: bool.isRequired,
};

export default Notification;
