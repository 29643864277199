// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FeatureModalNavigation-module__icon__383xq {\n    font-size: 19px;\n    color: #0279FF;\n}\n.FeatureModalNavigation-module__nextIcon__2psXL {\n    transform: rotate(180deg);\n}\n.FeatureModalNavigation-module__closeIcon__3vC8z {\n    font-size: 19px;\n}\n", ""]);
// Exports
exports.locals = {
	"icon": "FeatureModalNavigation-module__icon__383xq",
	"nextIcon": "FeatureModalNavigation-module__nextIcon__2psXL",
	"closeIcon": "FeatureModalNavigation-module__closeIcon__3vC8z"
};
module.exports = exports;
