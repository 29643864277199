import { gql } from '@apollo/client';

export const PostFragment = gql`
  fragment PostInfo on Post {
    id
    aspectRatio
    caption
    truncatedCaption
    wasCaptionTruncated
    shortTruncatedCaption
    height
    pinned
    paid
    timeStamp: timestamp
    publishedAt
    draft
    publicUid
    text
    type
    url(width: 1500)
    visibleForUser
    shareImage
    thumbnail
    poster
    width
    likeCount
    username
    isReady
    isWelcome
    isReplacementPending
    notificationsEnabled
    author {
      id
      commentsEmail
      commentsPhone
      commentsWhatsapp
      commentsCustom1
      commentsCustom2
      commentsCustom3
      commentsRequireSubscription
    }
    collection {
      id
      slug
      name
    }
    youTubeEmbedUrl
    vttUrl
  }
`;

export const PostNavigationFragment = gql`
  fragment PostNavigationInfo on Post {
    ...PostInfo
    beginningOfMonth
    nextPost {
      ...PostInfo
      beginningOfMonth
    }
    previousPost {
      ...PostInfo
      beginningOfMonth
    }
  }
  ${PostFragment}
`;

export const NonprofitFragment = gql`
  fragment NonprofitInfo on Nonprofit {
    website
    image
    name
    slug
    description
  }
`;

export const FeatureFragment = gql`
  fragment FeatureInfo on Feature {
    id
    title
    tagline
    description
    subtext
    fileUrl
    videoHtml
    link
    linkText
    linkTextWeight
    continueText
    continueTextWeight
    imageWidth
    imageHeight
    triggerNewFeatureModal
    uid
  }
`;

export const CollectionFragment = gql`
  fragment CollectionInfo on Collection {
    id
    name
    slug
    description
    public
  }
`;

export const UserFragment = gql`
  fragment UserInfo on User {
    id
    username
    commentsEmail
    commentsPhone
    commentsWhatsapp
    commentsCustom1
    commentsCustom2
    commentsCustom3
    commentsRequireSubscription
    thumbnailProfileImage: profileImage(width: 64)
    regularProfileImage: profileImage(width: 400)
    bigProfileImage: profileImage(width: 1200)
    profileBanner(width: 3500)
    desktopProfileBanner(width: 3500)
    isSubscribed
    isSelf
    tagline
    realName
    nonprofit {
      id
      name
      slug
    }
  }
`;
