import React from 'react';
import { StyleSheet, Text } from 'react-native';

import StylePropType from 'src/util/StylePropType';
import ChildrenPropType from 'src/util/ChildrenPropType';

const styles = StyleSheet.create({
  text: {
    fontSize: 17,
    lineHeight: 22,
    fontWeight: 600,
    textAlign: 'center',
  },
});

const ConfirmationHeader = ({ children, style }) => (
  <Text style={[styles.text, style]}>{children}</Text>
);

ConfirmationHeader.propTypes = {
  style: StylePropType,
  children: ChildrenPropType.isRequired,
};

ConfirmationHeader.defaultProps = {
  style: {},
};
export default ConfirmationHeader;
