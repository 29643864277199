// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileTagline-module__tagline__QNPwT {\n  font-size: 15px;\n  line-height: 20px;\n  color: rgba(255, 255, 255, 1)\n}\n.ProfileTagline-module__tagline__QNPwT.ProfileTagline-module__inverted__gOLGv {\n    color: rgba(0, 0, 0, 0.9);\n  }\n", ""]);
// Exports
exports.locals = {
	"tagline": "ProfileTagline-module__tagline__QNPwT",
	"inverted": "ProfileTagline-module__inverted__gOLGv"
};
module.exports = exports;
