import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 388,
    height: 45,
    marginTop: 8,
    marginBottom: 21.5,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.54)',
    borderRadius: 10,
    paddingHorizontal: 7,
    paddingVertical: 15,
    alignSelf: 'center',
    backdropFilter: 'blur(20px)',
    cursor: 'text',
  },

  text: {
    fontFamily: 'System',
    fontSize: 17,
    fontWeight: 600,
    lineHeight: 20,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  inverted: {
    backgroundColor: 'rgba(0, 0, 0, 0.48)',
  },
  invertedText: {
    color: '#FFF',
  },
});

export default styles;
