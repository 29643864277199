import { View, Text } from 'react-native';
import React, { useCallback } from 'react';

import BaseInput from 'src/components/BaseInput';

// E.g. <iframe width="1143" height="643" src="https://www.youtube.com/embed/-AOnzXe2JDA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
const IFRAME_EMBED_REGEXP = /<iframe.*src="([^"]+)".*<\/iframe>/;

// E.g. https://youtu.be/-AOnzXe2JDA
const YOUTUBE_SHARE_URL = /https:\/\/youtu\.be\/(.+)/;

// E.g. https://www.youtube.com/watch?v=-AOnzXe2JDA
const YOUTUBE_WATCH_URL = /https:\/\/www\.youtube\.com\/watch\?v=([^/]+)/;

const YouTubeEmbedForm = ({ embed, setEmbed }) => {
  const fixUpEmbed = useCallback(() => {
    setEmbed((currentEmbed) => {
      const iframeHtmlMatch = currentEmbed?.match(IFRAME_EMBED_REGEXP);
      if (iframeHtmlMatch?.length > 1) {
        // The YouTube player makes it really easy to copy the entire iframe
        // HTML code for a video, but sort of a pain to copy just the embed URL
        // for the video.
        //
        // To help alleviate this annoyance, if someone pastes the iframe HTML
        // code for the video into this text box, we replace it with the video
        // URL on blur.
        return iframeHtmlMatch[1];
      }

      const shareUrlMatch = currentEmbed?.match(YOUTUBE_SHARE_URL);
      if (shareUrlMatch?.length > 1) {
        return `https://www.youtube.com/embed/${shareUrlMatch[1]}`;
      }

      const watchUrlMatch = currentEmbed?.match(YOUTUBE_WATCH_URL);
      if (watchUrlMatch?.length > 1) {
        return `https://www.youtube.com/embed/${watchUrlMatch[1]}`;
      }

      return currentEmbed;
    });
  }, [setEmbed]);

  return (
    <View style={{ flex: 1, marginHorizontal: '10px' }}>
      <View
        style={{
          marginHorizontal: '20px',
          marginTop: '20px',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 24,
        }}
      >
        {/* eslint-disable react/no-unescaped-entities */}
        <Text style={{ lineHeight: '16px', fontSize: '13px', color: 'rgb(122, 122, 123)' }}>
          Or
        </Text>
        <Text
          style={{
            textAlign: 'left',
            color: 'rgb(122, 122, 123)',
            lineHeight: '16px',
            fontSize: '13px',
          }}
        >
          {'\n'}
          Share Livestream (Beta){'\n'}
          {'\n'}
          Step 1: Copy/paste your Youtube livestream link below. (Be sure to set your youtube video
          to "Unlisted" in Youtube, since this is intended only for paying subscribers){'\n'}
          {'\n'}
          Step 2: Click the <Text style={{ fontStyle: 'italic' }}>"Choose Image"</Text> button above
          to choose a thumbnail image. This is what your subscribers will see in their notification
          when you go live.{'\n'}
          {'\n'}
          Step 3: Write a caption (above). This describes the livestream.{'\n'}
          {'\n'}
          Step 4: Choose what collection you'd like this livestream to appear in, by clicking{' '}
          <Text style={{ fontStyle: 'italic' }}>"Post to:"</Text> below.{'\n'}
          {'\n'}
          Step 5: Click <Text style={{ fontStyle: 'italic' }}>"Preview a draft of this post"</Text>
          or click <Text style={{ fontStyle: 'italic' }}>"Share"</Text>. Once you click{' '}
          <Text style={{ fontStyle: 'italic' }}>"Share"</Text>, your subscribers will be notified
          that you're live!{'\n'}
          {'\n'}
        </Text>
      </View>
      <BaseInput
        isOnlyInput
        focus={false}
        testID="youtube-embed-url"
        onChange={setEmbed}
        onBlur={fixUpEmbed}
        value={embed}
        placeholder="ex: https://youtu.be/c1T575T-jzE"
        style={{
          borderColor: 'rgb(122, 122, 123)',
          width: '75%',
          alignSelf: 'center',
          fontSize: '11px',
        }}
      />
    </View>
  );
};

export default YouTubeEmbedForm;
