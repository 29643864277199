import { StyleSheet } from 'react-native';
import Colors from 'src/styles/colors';

const styles = StyleSheet.create({
  input: {
    fontSize: 17,
    width: '100%',
    lineHeight: 20,
    paddingRight: 16,
    backgroundColor: Colors.inputBackgroundColor,
    borderRadius: 10,
    paddingVertical: 22,
    paddingLeft: 24,
    marginBottom: 16,
    boxSizing: 'border-box',
    borderColor: Colors.mainBackground,
    borderWidth: 1,
  },
});

export default styles;
