// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CollectionPickerRow-module__checkmarkIcon__6wNed {\n    font-size: 20px;\n    color: #333333;\n}\n.CollectionPickerRow-module__actionIcon__10Gfi {\n    font-size: 16px;\n}\n.CollectionPickerRow-module__editCollectionIcon__1mOkC {\n    color: #1D89FF;\n}\n", ""]);
// Exports
exports.locals = {
	"checkmarkIcon": "CollectionPickerRow-module__checkmarkIcon__6wNed",
	"actionIcon": "CollectionPickerRow-module__actionIcon__10Gfi",
	"editCollectionIcon": "CollectionPickerRow-module__editCollectionIcon__1mOkC"
};
module.exports = exports;
