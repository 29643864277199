import { useEffect } from 'react';
import { useAnalytics } from 'use-analytics';

// Rewrites the notification location (e.g. /curtiswiklund/abc123/p) into a form
// that Google Analytics can break down traffic by (UTM medium and source).
//
// These paths are of the form:
//
//   - The artist's username (a string)
//   - The public_uid of the post (a string)
//   - Either `e` or `p`, indicating either an email or phone notification.
//
// Accepts the window.location and returns an object with entries for:
//
// {
//   href, (full URL)
//   pathname, (URL excluding origin and query string, e.g. /settings/notifications)
// }
const rewriteLocationForUtmMedium = (location) => {
  const notificationRegex = /\/\w+\/.+\/(?<medium>[ep])$/;
  if (!notificationRegex.test(location.pathname)) return location;

  const matches = location.pathname.match(notificationRegex);
  if (!matches) return location;

  // Parse medium type
  const utmMedium = (() => {
    switch (matches.groups.medium) {
      case 'e':
        return 'email';
      case 'p':
        return 'phone';
      case 'share':
        return 'self';
      default:
        return null;
    }
  })();
  if (!utmMedium) return location;

  // Generate the UTM query parameters required for Google Analytics.
  const search = `${
    location.search ? '&' : '?'
  }utm_source=notification&utm_medium=${utmMedium}&utm_campaign=launch`;
  // Strip the trailing /e or /p, which is not stored in utm_source and
  // utm_medium.
  const newPathname = location.pathname.slice(0, -2);
  return {
    href: `${location.origin}${newPathname}${search}`,
    // Remove the trailing /e or /p.
    pathname: newPathname,
  };
};

const PageTracker = ({ route }) => {
  const { page } = useAnalytics();
  useEffect(() => {
    const location = rewriteLocationForUtmMedium(window.location);
    page({
      url: location.href,
      path: location.pathname,
    });
  }, [route]);

  // This component exists only for side-effects and has no UI.
  return null;
};
export default PageTracker;
