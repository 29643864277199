import React from 'react';
import { Text, View } from 'react-native';
import { bool, func, string } from 'prop-types';

import styles from 'src/components/SelectCollectionFlow/CollectionPickerRow.styles';
import webStyles from 'src/components/SelectCollectionFlow/CollectionPickerRow.module.css';
import { TouchableOpacity } from 'src/components/Touchable';
import HorizontalHairlineStroke from 'src/components/HorizontalHairlineStroke';
import ChevronUpSvg from 'assets/images/chevron-up.svg';
import PencilSvg from 'assets/images/pencil.svg';
import CheckmarkSvg from 'assets/images/checkmark.svg';
import clsx from 'clsx';

const CollectionPickerRow = ({ name, onPress, selected, onEditPress, onMoveCollectionHigher }) => (
  <>
    <TouchableOpacity style={styles.collectionRow} onPress={onPress}>
      <View style={styles.selectedBox}>
        {selected && <CheckmarkSvg className={webStyles.checkmarkIcon} />}
      </View>
      <Text style={styles.collectionText}>{name}</Text>

      <View style={styles.actionsContainer}>
        {onMoveCollectionHigher && (
          <TouchableOpacity style={styles.rowAction} onPress={onMoveCollectionHigher}>
            <ChevronUpSvg className={webStyles.actionIcon} />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          testID="edit-collection-in-modal-button"
          style={styles.rowAction}
          onPress={onEditPress}
        >
          <PencilSvg className={clsx(webStyles.actionIcon, webStyles.editCollectionIcon)} />
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
    <HorizontalHairlineStroke color="#C4C4C4" />
  </>
);

CollectionPickerRow.propTypes = {
  name: string.isRequired,
  onPress: func.isRequired,
  onEditPress: func.isRequired,
  selected: bool.isRequired,
};

export default CollectionPickerRow;
