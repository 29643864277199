import { StyleSheet } from 'react-native';

// TODO(zbrukas): Add colors to colors.js

const styles = StyleSheet.create({
  button: {
    borderRadius: 999,
    backgroundColor: '#0070C9',
    backgroundImage: 'linear-gradient(180deg, #42A1EC 0%, #0070C9 100%)',
    borderWidth: 1,
    borderColor: '#0077CC',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  largeButton: {
    borderRadius: 10,
    fontSize: 17,
    lineHeight: 53,
    maxHeight: 53,
  },
  hoverButton: {
    backgroundColor: '#147BCD',
    backgroundImage: 'linear-gradient(180deg, #51A9EE 0%, #147BCD 100%)',
    borderColor: '#1482D0',
  },
  pressedButton: {
    backgroundColor: '#006DBC',
    backgroundImage: 'linear-gradient(180deg, #3D94D9 0%, #0067B9 100%)',
    borderColor: '#006DBC',
  },
  disabledButton: {
    opacity: 0.5,
    cursor: 'default',
  },
  buttonText: {
    color: '#FEFFFE',
    fontSize: 17,
    lineHeight: 33,
    textAlign: 'center',
  },
  largeButtonText: {
    lineHeight: 53,
  },
});

export default styles;
