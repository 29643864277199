import React, { createContext, useState } from 'react';

const WarpLoginContext = createContext({
  warpLoginOpen: null,
  setWarpLoginOpen: () => {},
});

export const WarpLoginContextProvider = ({ children }) => {
  const [warpLoginOpen, setWarpLoginOpen] = useState(null);

  return (
    <WarpLoginContext.Provider value={{ warpLoginOpen, setWarpLoginOpen }}>
      {children}
    </WarpLoginContext.Provider>
  );
};

export default WarpLoginContext;
