import React from 'react';
import { Text } from 'react-native';

import ChildrenPropType from 'src/util/ChildrenPropType';
import StylePropType from 'src/util/StylePropType';
import styles from 'src/components/FlowModal/FlowDescriptionLarge.styles';

const FlowDescriptionLarge = ({ children, style = {}, ...rest }) => (
  <Text {...rest} testID="modal-description" style={[styles.text, style]}>
    {children}
  </Text>
);

FlowDescriptionLarge.propTypes = {
  children: ChildrenPropType.isRequired,
  style: StylePropType,
};

export default FlowDescriptionLarge;
