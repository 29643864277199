import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#3C3C434A',
    justifyContent: 'space-between',
    boxSizing: 'content-box',
    marginBottom: 20,
    paddingHorizontal: 40,
  },
});

export default styles;
