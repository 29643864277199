// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FileUploader-module__previewDraftIcon__2uhxN {\n    height: 13px;\n    color: rgba(255, 255, 255, 0.9);\n    margin-right: 8px;\n}\n.FileUploader-module__buttonTextDisabled__1dERP {\n    color: rgba(255, 255, 255, 0.3);\n}\n.FileUploader-module__removeFileIcon__2NKrK {\n    font-size: 28px;\n    color: #FFFFFF;\n    margin: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"previewDraftIcon": "FileUploader-module__previewDraftIcon__2uhxN",
	"buttonTextDisabled": "FileUploader-module__buttonTextDisabled__1dERP",
	"removeFileIcon": "FileUploader-module__removeFileIcon__2NKrK"
};
module.exports = exports;
