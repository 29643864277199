import React from 'react';
import { func, string, bool } from 'prop-types';
import { Text, View, Pressable, Easing } from 'react-native';

import StylePropType from 'src/util/StylePropType';
import ChildrenPropType from 'src/util/ChildrenPropType';
import Modal from 'src/components/Modal';
import { TouchableWithoutFeedback } from 'src/components/Touchable';
import HorizontalHairlineStroke from 'src/components/HorizontalHairlineStroke';
import VerticalHairlineStroke from 'src/components/VerticalHairlineStroke';
import styles from 'src/components/ConfirmationModal/ConfirmationModal.styles';

const ANIMATION_IN_TIMING_MS = 266;
const ANIMATION_IN = {
  easing: Easing.bezier(0.25, 1, 0.5, 1),
  0: {
    scale: 1.18,
    opacity: 1,
  },
  // We intentionally animate in the opacity to 100% faster than the scale. This
  // is to circumvent a Chromium bug (https://crbug.com/1229795) where the
  // backdrop filter doesn't apply until the overlay reaches 100% opacity. If
  // we animate the scale in at the same speed at the opacity, there's a "jump"
  // as the background overlay kicks in right at the end of the animation. This
  // jump is much less apparent if we move it earlier in the animation (in this
  // case, at 55%). For the scale value specified here, we just use the
  // appropriate Y value 55% along the Bezier curve:
  //
  //   bezier(0.25, 1, 0.5, 1)(0.55) = 0.95
  //   .95 * (1 - 1.18) + 1.18
  //
  // https://cubic-bezier.com/#.25,1,.5,1
  0.55: {
    scale: 1.009,
    opacity: 1,
  },
  1: {
    scale: 1,
    opacity: 1,
  },
};

const ConfirmationModal = ({
  onClose,
  onConfirm,
  children,
  closeText,
  confirmText,
  alertModal,
  style,
  topContainerStyle,
  ...props
}) => (
  <Modal
    animationIn={ANIMATION_IN}
    animationInTiming={ANIMATION_IN_TIMING_MS}
    style={styles.modalContent}
    onBackdropPress={onClose}
    {...props}
  >
    <TouchableWithoutFeedback style={styles.backDrop} onPress={onClose} />
    <View style={styles.boxContainer}>
      <View style={[styles.base, style]}>
        <View style={[styles.topContainer, topContainerStyle]}>{children}</View>
        <HorizontalHairlineStroke color="rgb(60, 60, 67, 0.33)" direction="top" />
        <View style={styles.bottomContainer}>
          {onClose && (
            <>
              <Pressable
                testID="confirmation-modal-cancel"
                onPress={onClose}
                style={({ pressed }) => [
                  styles.buttonContainer,
                  pressed ? styles.pressedButton : {},
                ]}
              >
                <Text style={styles.buttonText}>{closeText}</Text>
              </Pressable>
              <VerticalHairlineStroke color="rgba(60, 60, 67, 0.33)" />
            </>
          )}
          <Pressable
            testID="confirmation-modal-confirm"
            onPress={onConfirm}
            style={({ pressed }) => [styles.buttonContainer, pressed ? styles.pressedButton : {}]}
          >
            <Text
              style={[
                styles.buttonText,
                alertModal ? styles.confirmTextAlertModal : styles.confirmText,
              ]}
            >
              {confirmText}
            </Text>
          </Pressable>
        </View>
      </View>
    </View>
  </Modal>
);

ConfirmationModal.defaultProps = {
  closeText: 'Cancel',
  confirmText: 'Confirm',
  alertModal: false,
  style: {},
  topContainerStyle: {},
  onClose: null,
};

ConfirmationModal.propTypes = {
  onClose: func,
  onConfirm: func.isRequired,
  title: string.isRequired,
  closeText: string,
  confirmText: string,
  alertModal: bool,
  children: ChildrenPropType.isRequired,
  style: StylePropType,
  topContainerStyle: StylePropType,
};

export default ConfirmationModal;
