import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    marginLeft: 15,
  },
  channelTitle: {
    textTransform: 'uppercase',
    color: '#B5B5B5',
    fontSize: 12,
    lineHeight: 19,
    fontWeight: 700,
    marginBottom: 16,
    letterSpacing: 0.1,
  },
  listContainer: {
    minHeight: 33,
  },
  channelStyle: {
    marginVertical: 0,
  },
});

export default styles;
