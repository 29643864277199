// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BackButton-module__smallIcon__TFcDR {\n    width: 11px;\n    height: 19px;\n    margin-left: 12px;\n    margin-right: 5px;\n    font-size: 19px;\n    color: #FFFFFF;\n    display: flex;\n    justify-content: center;\n}\n.BackButton-module__withoutTextIcon__2QJS6 {\n    font-size: 24px;\n    color: #FFFFFF;\n}\n", ""]);
// Exports
exports.locals = {
	"smallIcon": "BackButton-module__smallIcon__TFcDR",
	"withoutTextIcon": "BackButton-module__withoutTextIcon__2QJS6"
};
module.exports = exports;
