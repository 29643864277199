import { useEffect, useState } from 'react';

// Hook that, given a lazy loaded component `LazyComponent` and a trigger condition
// that prompts the lazy component to load, `useLazyMonitor` returns a boolean
// that remains true until the component has loaded.
//
// This is useful because it allows you to render a loading indicator in a
// part of the component tree that's distant from where the lazy component will
// actually be rendered.
//
// Usage:
//
//   const ConnectWalletFlow = retryingLazy(() => import('./../ConnectWalletFlow/ConnectWalletFlow'));
//   ...
//   const { connectWalletFlowOpen } = useContext(ConnectWalletFlowContext);
//   const { loading: connectWalletFlowLoading } = useLazyMonitor(
//     ConnectWalletFlow,
//     connectWalletFlowOpen,
//   );
//
// Using a portal from the place that you need the loading indicator is a tempting
// option to achieve the same thing with less hassle. However, using a portal
// ties the lifecycle of the lazily loaded component (e.g. a modal flow) to the
// lifecycle of the place where the loading indicator is rendered. This is a
// problem when you lazy load a subscribe flow, for example, but the subscribe
// button where the loading indicator displays disappears before the end of the
// flow (e.g. when a "Success!" screen is rendered).
//
// I'm not at all convinced that this is the best way to achieve this, but it's
// the best I could come up with right now. It's definitely a little clunky.
//
// NOTE: passing triggerCondition === true will immediately cause the component
// to load, even if it's not yet being rendered in the component tree.
// Because of this, it's important to only pass a true triggerCondition if
// the component would otherwise be rendering in order to avoid unnecessarily
// lazy loading the chunk
const useLazyMonitor = (LazyComponent, triggerCondition) => {
  const [loadingStarted, setLoadingStarted] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    if (triggerCondition && !loadingStarted) {
      setLoadingStarted(true);

      // Ew - calling internal functions.
      // _ctor() is what's called under the hood the first time that the
      // component tries to render. By calling it explicitly, we gain access
      // to the underlying import promise.
      // eslint-disable-next-line no-underscore-dangle
      LazyComponent._payload._result().then(() => {
        setLoadingComplete(true);
      });
    }
  }, [LazyComponent, triggerCondition]);

  return {
    loading: loadingStarted && !loadingComplete,
  };
};

export default useLazyMonitor;
