import { StyleSheet } from 'react-native';

import Colors from 'src/styles/colors';
import { TOP_BAR_HEIGHT_PX } from 'src/constants/Sizes';

const styles = StyleSheet.create({
  main: {
    backgroundColor: Colors.mainBackground,
    flex: 1,
    height: '100%',
    width: '100%',
    position: 'relative',
    overflowX: 'hidden',
    marginTop: TOP_BAR_HEIGHT_PX,
  },
  withNegativeMarginTop: {
    marginTop: -TOP_BAR_HEIGHT_PX,
  },
  centerColumn: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    minHeight: `calc((100 * var(--vh)) - ${TOP_BAR_HEIGHT_PX}px)`,
    width: '100%',
    maxWidth: 750,
    minWidth: 250,
  },
  content: {
    flex: 1,
    backgroundColor: Colors.primary,
  },
  verticalHairlineStroke: {
    width: '1px',
  },
  verticalStroke: {
    backgroundColor: Colors.defaultCenterColumnBorder,
  },
});

export default styles;
