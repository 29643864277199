import React from 'react';
import { View, StyleSheet } from 'react-native';
import { number } from 'prop-types';

const styles = StyleSheet.create({
  progressBar: {
    height: 3,
    backgroundColor: '#1D89FF',
  },
});

const ProgressBar = ({ progress }) => (
  <View style={[styles.progressBar, { width: `${progress}%` }]} />
);

ProgressBar.propTypes = {
  progress: number.isRequired,
};

export default ProgressBar;
