import { differenceBy } from 'lodash';

import PlatformStorage from 'src/storage';
import { FEATURE_EXPLANATION_LOCAL_STORAGE_KEY } from 'src/components/hocs/WithFeatureExplanation';

export function isEmpty(val) {
  if (typeof val === 'string') {
    return val.trim() === '';
  }

  if (Array.isArray(val)) {
    return val.length === 0;
  }

  return Object.entries(val).length === 0 && val.constructor === Object;
}

export function onLogOut() {
  PlatformStorage.clear();
  // eslint-disable-next-line no-restricted-globals
  location.reload();
}

export function trimEnd(text) {
  return text.replace(/\s+$/, '');
}

export function isAndroid() {
  return /(android)/i.test(navigator.userAgent);
}

// Got from https://stackoverflow.com/questions/9038625
export function isIOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function isMobile() {
  return isIOS() || isAndroid();
}

export async function copyToClipboard(text) {
  await navigator.clipboard.writeText(text);
}

export async function getUnseenActionFeatures(features) {
  const shownFeatures = await PlatformStorage.get(FEATURE_EXPLANATION_LOCAL_STORAGE_KEY);
  const shownFeaturesJson = JSON.parse(shownFeatures || '[]');

  return differenceBy(features, shownFeaturesJson, 'uid');
}

export async function setActionFeatureAsSeen(uid) {
  const previousFeatures = await PlatformStorage.get(FEATURE_EXPLANATION_LOCAL_STORAGE_KEY);
  const shownFeatures = JSON.parse(previousFeatures || '[]');
  shownFeatures.push({ uid });
  PlatformStorage.set(FEATURE_EXPLANATION_LOCAL_STORAGE_KEY, JSON.stringify(shownFeatures));
}

export function getFileTypeFromURL(url) {
  const extension = url.split('.').pop();

  // Replace this with ReactPlayer.canPlay once we can lazy load the create post flow.
  if (extension.match(/(mp4|mov|m3u8|wmv|avi)$/i)) {
    return 'video';
  }

  return 'image';
}
