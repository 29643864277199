import React, { useState } from 'react';
import { View, Text } from 'react-native';

import { hexToRgba } from 'src/util/colorUtils';
import { TouchableOpacity } from 'src/components/Touchable';
import ProgressiveImage from 'src/components/ProgressiveImage';
import styles from 'src/components/ChannelHeader/MobileChannelHeader.styles';
import webStyles from 'src/components/ChannelHeader/MobileChannelHeader.module.css';
import SubscribeButton from 'src/components/ChannelHeader/SubscribeButton';
import SecondarySubscribeButton from 'src/components/ChannelHeader/SecondarySubscribeButton';
import ArtistChannelButton from 'src/components/ChannelHeader/ArtistChannelButton';
import ProfileTagline from 'src/components/ChannelHeader/ProfileTagline.web';
import ChannelLogo from 'src/components/ChannelHeader/ChannelLogo';
import TvRating from 'src/components/ChannelHeader/TvRating';
import ShieldPopup from 'src/components/ChannelHeader/ShieldPopup';
import ConnectWalletButton from 'src/components/ChannelHeader/ConnectWalletButton';
import ShieldFooterSvg from 'assets/images/shield-footer.svg';

const MobileChannelHeader = ({ artist, loading, isArtist }) => {
  const {
    profileBanner,
    profileBannerAspectRatio,
    channelSubscribeButtonIcon,
    tagline,
    nonprofit,
    isSubscribed,
    channelLogo,
    channelLogoAspectRatio,
    channelRating,
    channelRatingAspectRatio,
    channelType,
    channelSubscribeButtonText,
    channelTitle,
    channelSubtitle,
    channelStartDate,
    channelInvertedColors: invertedColors,
    channelMainColor = '#000000',
    channelLoadingColor = '#FFFFFF',
    channelSecondarySubscribeButtonText,
    channelSecondarySubscribeButtonIcon,
    channelSecondarySubscribeButtonSubtext,
    channelSecondarySubscribeButtonFeatureUid,
    channelSecondarySubscribeButtonPreviewUid,
    channelSubscribeButtonTextSubscribed,
    channelSubscribeButtonSubscribedIcon,
    channelSubscribeButtonPostUid,
    ethCollectionSlug,
    ethButtonText,
    ethButtonIcon,
    ethButtonSubtext,
    username,
  } = artist || {};
  const [nonprofitDetailsOpen, setNonprofitDetailsOpen] = useState(false);
  const [height, setHeight] = useState(window.innerWidth * (16 / 9));

  const onLayout = (event) => {
    const { width: viewWidth } = event.nativeEvent.layout;
    setHeight(Math.min(viewWidth * (16 / 9), window.innerHeight));
  };

  const bottomGradientColor = hexToRgba(channelMainColor, 0);

  return (
    <>
      <View
        style={[styles.container, { height, backgroundColor: channelLoadingColor }]}
        onLayout={onLayout}
      >
        <ProgressiveImage
          imageSource={{ uri: profileBanner }}
          resizeMode="cover"
          style={styles.backgroundImage}
          placeholderColor="transparent"
          endPlaceholderColor="transparent"
          dataSet={{ 'background-position-top': true }}
          forceRender
          ratio={profileBannerAspectRatio}
          imageFadeDuration={425}
          imageWebStyle={{ objectPosition: 'top' }}
        />
        {!loading && (
          <View style={styles.bottomContainer}>
            {channelLogo ? (
              <View style={styles.logoContainer}>
                <ChannelLogo
                  logo={channelLogo}
                  ratio={channelLogoAspectRatio}
                  maxHeight={64}
                  style={styles.logo}
                />
              </View>
            ) : (
              <View style={styles.titleContainer}>
                <Text style={[styles.title, invertedColors ? styles.invertedText : {}]}>
                  {channelTitle}
                </Text>
              </View>
            )}
            {(channelType || channelStartDate) && (
              <View style={styles.titleContainer}>
                <Text
                  style={[styles.channelInfo, invertedColors ? styles.channelInfoInverted : {}]}
                >
                  {channelType} {channelType && channelStartDate && '·'} {channelStartDate}
                </Text>
              </View>
            )}
            <View style={styles.bottomInnerContainer}>
              <View style={styles.colorDivs}>
                <View
                  style={[
                    styles.bottomOverlay,
                    {
                      backgroundImage: `linear-gradient(0deg, ${channelMainColor} 0%, ${bottomGradientColor} 100%)`,
                    },
                  ]}
                />
                <View style={[styles.bottomSolidColor, { background: channelMainColor }]} />
              </View>
              <View style={styles.buttonContainer}>
                {isArtist && !channelSubscribeButtonTextSubscribed ? (
                  <ArtistChannelButton inverted={invertedColors} />
                ) : (
                  <>
                    {!!channelSecondarySubscribeButtonText && !isSubscribed && (
                      <SecondarySubscribeButton
                        text={channelSecondarySubscribeButtonText}
                        subtitle={channelSecondarySubscribeButtonSubtext}
                        inverted={invertedColors}
                        featureUid={channelSecondarySubscribeButtonFeatureUid}
                        previewUid={channelSecondarySubscribeButtonPreviewUid}
                        additionalButtonText={channelSubscribeButtonText || ethCollectionSlug}
                        icon={channelSecondarySubscribeButtonIcon}
                      />
                    )}
                    {ethCollectionSlug && !isSubscribed && (
                      <ConnectWalletButton
                        ethCollectionSlug={ethCollectionSlug}
                        text={ethButtonText}
                        subtitle={ethButtonSubtext}
                        inverted={invertedColors}
                        username={username}
                        icon={ethButtonIcon}
                        additionalButtonText={channelSubscribeButtonText}
                      />
                    )}
                    {(channelSubscribeButtonText || isSubscribed) && (
                      <SubscribeButton
                        inverted={invertedColors}
                        isSubscribed={isSubscribed}
                        text={channelSubscribeButtonText}
                        subtitle={channelSubtitle}
                        subscribedText={channelSubscribeButtonTextSubscribed}
                        postToRedirect={channelSubscribeButtonPostUid}
                        icon={channelSubscribeButtonIcon}
                        subscribedIcon={channelSubscribeButtonSubscribedIcon}
                      />
                    )}
                  </>
                )}
              </View>
              {tagline && (
                <View style={styles.taglineContainer}>
                  <ProfileTagline tagline={tagline} inverted={invertedColors} />
                </View>
              )}
              <View style={styles.charityRow}>
                {channelRating && (
                  <TvRating uri={channelRating} aspectRatio={channelRatingAspectRatio} />
                )}
                {nonprofit && (
                  <>
                    <ShieldFooterSvg className={webStyles.shieldIcon} />
                    <Text
                      style={[styles.charityText, invertedColors ? styles.charityInvertedText : {}]}
                    >
                      This channel supports{' '}
                      <TouchableOpacity
                        style={[
                          styles.charityLink,
                          invertedColors ? styles.charityInvertedText : {},
                        ]}
                        onPress={() => setNonprofitDetailsOpen(true)}
                        hitSlop={{ top: 10, left: 10, bottom: 10, right: 10 }}
                      >
                        <Text>{nonprofit.name}</Text>
                      </TouchableOpacity>
                      .
                    </Text>
                  </>
                )}
              </View>
            </View>
          </View>
        )}
      </View>
      {nonprofitDetailsOpen && (
        <ShieldPopup onClose={() => setNonprofitDetailsOpen(false)} nonprofit={nonprofit} />
      )}
    </>
  );
};

export default MobileChannelHeader;
