import React, { useEffect } from 'react';
import { Text, View } from 'react-native';
import { useQuery } from '@apollo/client';
import { partition } from 'lodash';
import { bool } from 'prop-types';

import { shortFormTimeStamp } from 'src/util/dateUtils';
import FlowDescriptionLarge from 'src/components/FlowModal/FlowDescriptionLarge';
import UserRow from 'src/components/UserRow';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { postShape } from 'src/constants/Shapes';
import { GET_POST_VIEWS } from 'src/graphql/Post';
import styles from 'src/components/PostInformationModal/SeenByInformation.styles';

const REFETCH_DELAY_MS = 650;

// Tab of the post information modal describing who has seen a post.
//
// This tab has two possible "loading states":
//
//   1) The tab is loading for the first time: display only the loading indicator.
//   2) The tab has data, but is refetching it. Display the old data alongside a
//      loading indicator.
const SeenByInformation = ({ post, visible }) => {
  const {
    loading: postWithViewsLoading,
    data: postWithViews,
    refetch: refetchPostWithViews,
  } = useQuery(GET_POST_VIEWS, {
    variables: {
      publicUid: post?.publicUid,
      // Determined experimentally by science!
      minDuration: 650,
    },
    notifyOnNetworkStatusChange: true,
  });
  const views = postWithViews?.post?.views;

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        refetchPostWithViews();
      }, REFETCH_DELAY_MS);
    }
  }, [visible]);

  const [loggedOutViews, loggedInViews] = partition(views, { user: null });

  return (
    <View style={visible ? {} : styles.hidden}>
      {views ? (
        <>
          <FlowDescriptionLarge style={styles.viewsCount}>
            <View>{views.length} views</View>
            {postWithViewsLoading && <LoadingIndicator style={styles.refetchLoadingIndicator} />}
          </FlowDescriptionLarge>
          {loggedInViews.map(({ user, createdAt }) => (
            <View testID="views-row" key={user.id} style={styles.userRowContainer}>
              <UserRow {...user} />
              <Text style={styles.seenByTimestamp}>{shortFormTimeStamp(createdAt)}</Text>
            </View>
          ))}
          {!!loggedOutViews.length && <Text>{loggedOutViews.length} public views</Text>}
        </>
      ) : (
        <LoadingIndicator style={styles.initialLoadingIndicator} />
      )}
    </View>
  );
};

SeenByInformation.propTypes = {
  post: postShape.isRequired,
  visible: bool.isRequired,
};

export default SeenByInformation;
