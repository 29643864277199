import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  charactersLeftContainer: {
    height: 15,
  },
  charactersLeft: {
    textAlign: 'right',
    fontSize: 12,
    color: '#1D89FF',
  },
});
export default styles;
