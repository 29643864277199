import React from 'react';
import { arrayOf, func, number } from 'prop-types';
import { View, Text, Linking } from 'react-native';
import { useTrack } from 'use-analytics';

import { NEXT_FEATURE_PRESSED } from 'src/util/analyticsEvents';
import { featureShape } from 'src/constants/Shapes';

import { TouchableOpacity } from 'src/components/Touchable';
import HorizontalHairlineStroke from 'src/components/HorizontalHairlineStroke';
import styles from 'src/components/Features/FeatureModal/FeatureModalOptions.styles';

const FeatureModalOptions = ({ features, feature, currentIndex, onNext, onClose, onContinue }) => {
  const track = useTrack();

  const handleNextPressed = () => {
    track(NEXT_FEATURE_PRESSED);
    onNext();
  };

  const isOnlyFeature = features.length === 1;
  const hasLinkText = feature.linkText;

  return (
    <View style={styles.container}>
      <HorizontalHairlineStroke style={styles.hairline} />
      {currentIndex < features.length - 1 && (
        <>
          <TouchableOpacity
            onPress={handleNextPressed}
            testID="next-feature-modal-button"
            style={styles.buttonContainer}
          >
            <Text
              style={[
                styles.buttonText,
                hasLinkText ? styles.featureOptionDefault : styles.featureOptionPriority,
              ]}
            >
              Next feature
            </Text>
          </TouchableOpacity>
          <HorizontalHairlineStroke style={styles.hairline} />
        </>
      )}
      {!!feature?.link && (
        <>
          <TouchableOpacity
            onPress={() => Linking.openURL(feature.link)}
            style={styles.buttonContainer}
          >
            <Text
              testID="feature-link-modal-button"
              style={[
                styles.buttonText,
                hasLinkText ? styles.featureOptionPriority : styles.featureOptionDefault,
                feature?.linkTextWeight !== null ? { fontWeight: feature?.linkTextWeight } : {},
              ]}
            >
              {feature.linkText || 'Learn More...'}
            </Text>
          </TouchableOpacity>
          <HorizontalHairlineStroke style={styles.hairline} />
        </>
      )}
      <TouchableOpacity
        onPress={onContinue ?? onClose}
        testID="close-modal-button"
        style={styles.buttonContainer}
      >
        <Text
          style={[
            styles.buttonText,
            isOnlyFeature && !hasLinkText ? styles.featureOptionPriority : {},
            feature?.continueTextWeight !== null ? { fontWeight: feature?.continueTextWeight } : {},
          ]}
        >
          {isOnlyFeature ? feature?.continueText : 'Close'}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

FeatureModalOptions.propTypes = {
  features: arrayOf(featureShape).isRequired,
  feature: featureShape.isRequired,
  onNext: func.isRequired,
  onClose: func.isRequired,
  currentIndex: number.isRequired,
  onContinue: func,
};

FeatureModalOptions.defaultProps = {
  onContinue: null,
};

export default FeatureModalOptions;
