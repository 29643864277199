import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    minWidth: 167,
    height: 30,
    borderRadius: 15,
    backgroundColor: '#F4F4F5',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 28,
  },
  postToText: {
    color: '#7A7A7B',
    fontWeight: 600,
    paddingLeft: 12,
    paddingRight: 10,
    lineHeight: 20,
  },
  optionSelected: {
    color: '#222327',
    fontWeight: 600,
    paddingRight: 4,
    lineHeight: 20,
  },
  defaultOption: {
    color: '#1d89ff',
  },
  dropdownIcon: {
    paddingTop: 2,
    fontSize: 14,
    color: '#7A7A7B',
    paddingRight: 12,
    marginLeft: 'auto',
  },
});

export default styles;
