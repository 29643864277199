import React from 'react';
import { Text, View } from 'react-native';
import { string, oneOf } from 'prop-types';

import StylePropType from 'src/util/StylePropType';
import styles from 'src/components/Avatar.styles';
import SvgImage from 'src/components/SvgImage';
import ProgressiveImage from 'src/components/ProgressiveImage';

const UserIcon = require('assets/images/avatar-transparent.svg?url');

const Avatar = ({ username, profileImage, size, style }) => {
  if (profileImage) {
    return (
      <View style={[styles(size).avatar, style]}>
        <ProgressiveImage
          ratio={1}
          imageSource={{ uri: profileImage }}
          placeholderColor="rgba(255, 255, 255, 0.02)"
          style={styles(size).avatarImage}
        />
        <View style={styles(size).avatarImageBorder} />
      </View>
    );
  }

  return (
    <View style={[styles(size).avatar, styles(size).letterAvatar, style]}>
      {username && username.trim() !== '' ? (
        <Text style={styles(size).avatarLetter} excludeFromFS>
          {username.trim().length ? username[0].toUpperCase() : ''}
        </Text>
      ) : (
        <SvgImage source={UserIcon} style={styles(size).avatarIcon} alt="Avatar circle" />
      )}
    </View>
  );
};

Avatar.propTypes = {
  username: string.isRequired,
  profileImage: string,
  size: oneOf(['small', 'medium', 'large']),
  style: StylePropType,
};

Avatar.defaultProps = {
  size: 'medium',
  style: {},
};

export default Avatar;
