import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    marginTop: 8,
    marginLeft: 20,
  },
  button: {
    width: 'fit-content',
    backgroundColor: 'transparent',
    borderRadius: 6,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 11,
    paddingRight: 11,
    marginRight: 13,
  },
  text: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: 18,
    color: '#000',
    textAlign: 'center',
  },
});
export default styles;
