import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { useFeatureFlag, FeatureFlags } from 'src/util/featureFlags';
import { redirectToRoot, resetNavigation } from 'src/util/navigationUtils';
import { GET_CURRENT_USER } from 'src/graphql/User';

// - It's useful to an "in-app" root route, even when the root redirect is enabled, because that way places like
//   the back button can redirect to the root screen, which can then make the app do a hard refresh to the Webflow root.
//   You can't do this by redirecting to /home (and have that redirect to Webflow root) because that would break the
//   back button and the person would be stuck on the Webflow root.
//
// - The back button redirects to root on the user screen in order to support demo's "go back to the demo landing page
//   functionality.
//
// - Curtis cares about the fact that when you click on "Home" in the left bar or the footer, you get a flash of the
//   old branding (logged out home screen) before it redirects to the Webflow.
//
// - Having ROOT_REDIRECT enabled causes really weird behavior at root on non-AWS-Lambda instances.
const RootScreen = ({ navigation }) => {
  const { data: { me } = {}, loading: loadingUser } = useQuery(GET_CURRENT_USER);
  const { enabled: rootRedirectEnabled, loading: rootRedirectLoading } = useFeatureFlag(
    FeatureFlags.ROOT_REDIRECT,
  );
  useEffect(() => {
    if (loadingUser || rootRedirectLoading) return;

    if (rootRedirectEnabled && !me) {
      redirectToRoot();
      return;
    }

    resetNavigation(navigation, { routeName: 'Home' });
  }, [me, loadingUser, rootRedirectEnabled, rootRedirectLoading]);

  return null;
};
export default RootScreen;
