/* eslint-disable no-param-reassign */
import { DirectUpload } from '@rails/activestorage';

import { DIRECT_UPLOAD_URL } from 'env';
import PlatformStorage from 'src/storage';

export class UploadAbortedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UploadAbortedError';
  }
}

// https://edgeguides.rubyonrails.org/active_storage_overview.html#integrating-with-libraries-or-frameworks
const directUpload = async (file, onProgress, onCompleted, signal) => {
  const token = await PlatformStorage.get('token');

  const directUploadDidProgress = ({ loaded, total }) => {
    if (onProgress) onProgress((loaded / total) * 100);
  };

  const uploader = new DirectUpload(file, DIRECT_UPLOAD_URL, {
    directUploadWillCreateBlobWithXHR: (request) => {
      request.setRequestHeader('authorization', `Bearer ${token}`);
    },
    directUploadWillStoreFileWithXHR: (request) => {
      if (signal) {
        // If the request is aborted, then invalidate the whole request
        if (signal.aborted) {
          request.send = () => {};
        }
        signal.addEventListener('abort', () => {
          request.abort();
        });
      }
      request.upload.addEventListener('abort', () => onProgress(0));
      request.upload.addEventListener('progress', (event) => directUploadDidProgress(event));
    },
  });
  // TODO: Sachin - callback type returns needs to have first argument as error
  // This needs to be changed to onCompleted(error, data)
  uploader.create((error, blob) => {
    if (error) throw error;

    onCompleted(blob.signed_id);
  });
};

export const directUploadPromise = (file, onProgress) =>
  new Promise((resolve) => {
    directUpload(file, onProgress, (blobId) => resolve(blobId));
  });

export const directUploadCancelablePromise = (file, onProgress) => {
  const controller = new AbortController();
  const { signal } = controller;

  return {
    promise: new Promise((resolve, reject) => {
      signal.addEventListener('abort', () => {
        reject(new UploadAbortedError('Aborted'));
      });
      directUpload(file, onProgress, (blobId) => resolve(blobId), signal);
    }),
    abort: () => {
      controller.abort();
    },
  };
};

export default directUpload;
