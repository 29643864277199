import React from 'react';
import { View, Text } from 'react-native';

import { timestampFormat, localTimezone } from 'src/util/dateUtils';
import styles from 'src/components/PostModal/TimestampSelector.styles';

const TimestampSelector = ({ timestamp, onChange }) => {
  const handleChange = ({ target: { value } }) => onChange(value);

  return (
    <View style={styles.container}>
      <Text style={styles.text}>Post Date:</Text>
      <input
        data-testid="timestamp-input"
        type="datetime-local"
        value={timestampFormat(timestamp)}
        max={timestampFormat(new Date())}
        onChange={handleChange}
        style={{
          background: '#E9E9EB',
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 8,
          paddingBottom: 8,
          fontFamily:
            'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
          fontSize: 18,
          lineHeight: 24,
          borderRadius: 7,
          minWidth: 250,
          width: 'fit-content',
          height: 40,
          boxSizing: 'border-box',
          border: 'none',
          outline: 'none',
        }}
      />
      <Text style={[styles.text, styles.timezone]}>Time zone: {localTimezone()}</Text>
    </View>
  );
};

export default TimestampSelector;
