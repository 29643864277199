import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 'fit-content',
    maxWidth: 388,
    marginTop: 8,
    marginBottom: 21,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.91)',
    borderRadius: 10,
    paddingVertical: 12,
    alignSelf: 'center',
    backdropFilter: 'blur(20px)',
    paddingHorizontal: '8%',
  },
  withSubtitle: {
    marginBottom: 0,
  },
  inverted: {
    backgroundColor: 'rgba(0, 0, 0, 0.80)',
  },
  text: {
    fontFamily: 'System',
    fontSize: 17,
    fontWeight: 600,
    lineHeight: 20,
    color: '#000',
    textAlign: 'center',
    position: 'relative',
  },
  invertedText: {
    color: '#FFF',
  },
  subscribed: {
    backgroundColor: 'rgba(255, 255, 255, 0.54)',
  },
  invertedAndSubscribed: {
    backgroundColor: 'rgba(0, 0, 0, 0.48)',
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 15,
    marginTop: 4.5,
    marginBottom: 13.5,
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.67)',
  },
  subtitleInverted: {
    color: 'rgba(0, 0, 0, 0.67)',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    height: 20,
    width: 20,
    marginRight: 9,
    objectFit: 'contain',
  },
});

export default styles;
