// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProgressiveImage-module__image__1HS4X {\n    width: 100%;\n    height: 100%;\n    opacity: 0;\n    transition-timing-function: ease-in-out\n}\n\n.ProgressiveImage-module__image__1HS4X.ProgressiveImage-module__loaded__S5X7J {\n        opacity: 1;\n    }\n\n.ProgressiveImage-module__placeholder__1We9B {\n    background-color: #D8D8D8;\n}\n", ""]);
// Exports
exports.locals = {
	"image": "ProgressiveImage-module__image__1HS4X",
	"loaded": "ProgressiveImage-module__loaded__S5X7J",
	"placeholder": "ProgressiveImage-module__placeholder__1We9B"
};
module.exports = exports;
