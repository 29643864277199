import { Dimensions } from 'react-native';

const dimensions = {
  fullHeight: Dimensions.get('window').height,
  fullWidth: Dimensions.get('window').width,
};

const DEFAULT_AVATAR_SIZE = 36;
const PROFILE_HEADER_AVATAR_SIZE = 80;

const defaultAvatar = {
  size: DEFAULT_AVATAR_SIZE,
  radius: DEFAULT_AVATAR_SIZE / 2,
};

const mediumAvatar = {
  size: PROFILE_HEADER_AVATAR_SIZE,
  radius: PROFILE_HEADER_AVATAR_SIZE / 2,
};

const heights = {
  bottomBar: 45,
  topBar: 50,
};

export { defaultAvatar, mediumAvatar, dimensions, heights };
