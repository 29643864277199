import { StyleSheet } from 'react-native';
import Colors from 'src/styles/colors';

const styles = StyleSheet.create({
  textInput: {
    width: '100%',
    height: 62,
    borderRadius: 10,
    borderColor: Colors.mainBackground,
    borderWidth: 1,
    flexDirection: 'row',
    marginBottom: 16,
    paddingVertical: 16,
    paddingLeft: 24,
    boxSizing: 'border-box',
  },
});

export default styles;
