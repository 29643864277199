import { StyleSheet } from 'react-native';
import Colors from 'src/styles/colors';

const styles = StyleSheet.create({
  contentContainer: {
    alignSelf: 'center',
    paddingHorizontal: '9%',
    width: '100%',
    maxWidth: 448,
  },
  mainContainer: {
    zIndex: 1,
    paddingBottom: 24,
  },
  header: {
    marginTop: 24,
  },
  headerSignedOut: {
    marginTop: 24,
    textAlign: 'center',
  },
  tabs: {
    marginBottom: 24,
  },
  createAccount: {
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 24,
  },
  errorMessage: {
    marginBottom: 15,
    justifyContent: 'flex-start',
  },
  messageFootnote: {
    textAlign: 'center',
    marginBottom: 0,
  },
  connectWalletHairline: {
    marginTop: 0,
    marginBottom: 24,
  },
  connectWalletHeader: {
    textAlign: 'center',
  },
  connectWalletButton: {
    backgroundColor: '#7026ED',
    marginBottom: 32,
  },
  connectWalletButtonHover: { backgroundColor: '#7e3cef' },
  connectWalletButtonPressed: { backgroundColor: '#6113e4' },
  paymentHairline: {
    marginBottom: 16,
  },
  recentlyLoggedOutText: {
    marginTop: 4,
    marginBottom: 16,
  },
  recentlyLoggedOutHairline: {
    marginVertical: 8,
  },
  username: {
    textAlign: 'center',
    marginTop: 8,
    fontWeight: 600,
    marginBottom: 24,
    textTransform: 'uppercase',
  },
  paymentOptions: {
    marginBottom: 8,
  },
  welcome: {
    textAlign: 'center',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginBottom: 24,
  },
  amount: {
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 0,
  },
  whatsappButton: {
    backgroundColor: '#14171a',
    backgroundImage: 'none',
    borderColor: '#14171a',
  },
  whatsappButtonHover: {
    backgroundColor: '#24282D',
    backgroundImage: 'none',
    borderColor: '#24282D',
  },
  whatsappButtonPressed: {
    backgroundColor: '#000000',
    backgroundImage: 'none',
    borderColor: '#000000',
    opacity: 0.34,
  },
  whatsAppIcon: {
    height: 22,
    width: 22,
    marginRight: 3,
    marginLeft: 1,
  },
  whatsAppButtonIcon: {
    height: 22,
    width: 22,
    marginRight: 7,
    alignSelf: 'center',
    marginBottom: 3,
  },
  ethereumIcon: {
    position: 'absolute',
    height: 20,
    width: 20,
    top: 0,
    bottom: 0,
    left: 23,
    marginVertical: 'auto',
  },
  link: {
    color: Colors.accentColor,
  },
  switchMobileLink: {
    flexDirection: 'row',
  },
  mobileButtonText: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export default styles;
