import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingTop: '54%',
  },
  content: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  gradient: {
    width: '100%',
    height: '100%',
    backgroundImage:
      'linear-gradient(180deg, #A0A9B2 0%, #979FA8 7.8%, #89929A 21.62%, #7F878F 35.14%, #757E85 51.77%, #70787F 69.37%, #6E767E 83.91%, #6E777E 88.56%, #6F787F 96.69%, #717A80 100%)',
  },
  button: {
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.9)',
    lineHeight: 18,
    paddingVertical: 12,
    paddingHorizontal: 32,
    fontWeight: 600,
    margin: 'auto',
    borderRadius: 25,
    flexDirection: 'row',
    alignItems: 'center',
  },
  saveButtonDisabled: {
    borderColor: '#777777',
  },
  buttonText: {
    color: 'rgba(255, 255, 255, 0.9)',
    fontWeight: 600,
  },
  buttonTextDisabled: {
    color: 'rgba(255, 255, 255, 0.3)',
  },
  changeFileOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.34) 0%, rgba(0, 0, 0, 0.6) 100%)',
  },
  removeFileButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    borderRadius: 14,
    width: 29,
  },
  imageOverlayButton: {
    backdropFilter: 'blur(56px)',
  },
});

export default styles;
