import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
    width: '100%',
    fontSize: 15,
    lineHeight: 18,
    color: '#7F7F7F',
    marginBottom: 32,
    paddingHorizontal: 8,
  },
});

export default styles;
