import React, { useState, useRef } from 'react';
import { View, ScrollView, Animated, Easing, PanResponder } from 'react-native';
import { func, arrayOf } from 'prop-types';

import wrapAnimationDuration from 'src/util/wrapAnimationDuration';
import { isSwipeIntentionTowardsRight, isSwipeIntentionTowardsLeft } from 'src/util/swipeGesture';
import { featureShape } from 'src/constants/Shapes';
import Modal from 'src/components/Modal';
import { TouchableWithoutFeedback } from 'src/components/Touchable';
import Feature from 'src/components/Features/FeatureModal/Feature';
import styles from 'src/components/Features/FeatureModal/FeatureModal.styles';
import FeatureModalOptions from 'src/components/Features/FeatureModal/FeatureModalOptions';
import FeatureModalNavigation from 'src/components/Features/FeatureModal/FeatureModalNavigation';

const FeatureModal = ({ onClose, onContinue, features }) => {
  const instanceVariable = useRef({
    touchStarted: false,
    swipeFunctionToCall: null,
  }).current;
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const [isUserPanning, setIsUserPanning] = useState(false);
  const featureOpacity = useRef(new Animated.Value(1)).current;

  const goToPreviousFeature = () => {
    setCurrentFeatureIndex((oldFeatureIndex) => {
      if (oldFeatureIndex > 0) {
        return oldFeatureIndex - 1;
      }
      return oldFeatureIndex;
    });
  };

  const goToNextFeature = () => {
    setCurrentFeatureIndex((oldFeatureIndex) => {
      if (oldFeatureIndex < features.length - 1) {
        return oldFeatureIndex + 1;
      }
      return oldFeatureIndex;
    });
  };

  const setOpacity = (toValue, duration = 0) => {
    Animated.timing(featureOpacity, {
      toValue,
      duration,
      easing: Easing.bezier(0, 0, 0.58, 1),
    }).start();
  };

  const swipeIntention = (swipeFunction) => {
    instanceVariable.swipeFunctionToCall = swipeFunction;
  };

  const handleMovement = (gestureState) => {
    // Definition: dy, dx refers to changed in y, x coordinates from your last touch
    const { dy, dx } = gestureState;
    const radians = Math.atan(dy / dx);
    const degrees = (radians * 180) / Math.PI;
    // If the user touched the screen but scrolling vertically
    if (instanceVariable.touchStarted && !instanceVariable.swipeFunctionToCall) {
      setOpacity(1);
    }
    if (!instanceVariable.touchStarted) {
      if (isSwipeIntentionTowardsRight(degrees, dy)) {
        swipeIntention(goToNextFeature);
        if (!isUserPanning) {
          setIsUserPanning(true);
        }
        instanceVariable.touchStarted = true;
      } else if (isSwipeIntentionTowardsLeft(degrees, dy)) {
        swipeIntention(goToPreviousFeature);
        if (!isUserPanning) {
          setIsUserPanning(true);
        }
        instanceVariable.touchStarted = true;
      }
      // If the user has the intention to swipe
      if (instanceVariable.swipeFunctionToCall) {
        setOpacity(0.6, 150);
      }
    }
  };

  const onLeaveTouch = () => {
    setOpacity(1);
    instanceVariable.touchStarted = false;
    if (instanceVariable.swipeFunctionToCall) {
      instanceVariable.swipeFunctionToCall();
      setIsUserPanning(false);
    }
    swipeIntention(null);
  };

  const panResponder = useRef(
    PanResponder.create({
      // Ask to be the responder:
      onStartShouldSetPanResponder: () => true,
      onStartShouldSetPanResponderCapture: () => true,
      onMoveShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponderCapture: () => true,
      onPanResponderTerminationRequest: () => true,
      onPanResponderMove: (evt, gestureState) => {
        // The most recent move distance is gestureState.move{X,Y}
        // The accumulated gesture distance since becoming responder is
        // gestureState.d{x,y}
        handleMovement(gestureState);
      },
      onPanResponderRelease: (evt, gestureState) => {
        // The user has released all touches while this view is the
        // responder. This typically means a gesture has succeeded
        handleMovement(gestureState);
      },
      onPanResponderTerminate: (evt, gestureState) => {
        // Another component has become the responder, so this gesture
        // should be cancelled
        handleMovement(gestureState);
      },
    }),
  ).current;

  return (
    <Modal
      style={styles.modal}
      backdropTransitionInTiming={wrapAnimationDuration(205)}
      backdropTransitionOutTiming={1}
      animationIn="slideInUp"
      animationInTiming={wrapAnimationDuration(205)}
      animationOutTiming={wrapAnimationDuration(205)}
      testID="feature-modal"
    >
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
        style={{
          overflowY: isUserPanning ? 'hidden' : 'auto',
        }}
      >
        <TouchableWithoutFeedback style={styles.backDrop} onPress={onClose} />
        <View style={styles.sizeBoxTop} />
        <View style={styles.container}>
          <View style={styles.content}>
            <FeatureModalNavigation
              features={features}
              currentIndex={currentFeatureIndex}
              onNext={goToNextFeature}
              onBack={goToPreviousFeature}
              onClose={onClose}
            />
            <View {...panResponder.panHandlers} onMouseUp={onLeaveTouch} onTouchEnd={onLeaveTouch}>
              <View style={styles.featuresContainer}>
                <Animated.View style={{ opacity: featureOpacity }}>
                  <Feature feature={features[currentFeatureIndex]} />
                </Animated.View>
              </View>
            </View>
            <FeatureModalOptions
              onNext={goToNextFeature}
              features={features}
              currentIndex={currentFeatureIndex}
              feature={features[currentFeatureIndex]}
              onClose={onClose}
              onContinue={onContinue}
            />
          </View>
        </View>
        <View style={styles.sizeBoxBottom} />
      </ScrollView>
    </Modal>
  );
};

FeatureModal.propTypes = {
  onContinue: func,
  onClose: func.isRequired,
  features: arrayOf(featureShape).isRequired,
};

FeatureModal.defaultProps = {
  onContinue: null,
};

export default FeatureModal;
