import React from 'react';
import { View, TextInput } from 'react-native';
import { func, string } from 'prop-types';
import { useQuery } from '@apollo/client';

import { GET_CURRENT_USER } from 'src/graphql/User';
import ProgressiveImage from 'src/components/ProgressiveImage';
import styles from 'src/components/PostModal/CaptionEditor.styles';

const CaptionEditor = ({ caption, onChange, onFocus, onBlur }) => {
  const {
    data: { me },
  } = useQuery(GET_CURRENT_USER);

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <ProgressiveImage
          style={styles.profileImage}
          imageSource={{ uri: me.thumbnailProfileImage }}
          borderRadius={999}
          ratio={1}
        />
        <View style={styles.imageBorder} />
      </View>
      <View style={styles.divider} />
      <TextInput
        multiline
        placeholder="Write a caption..."
        value={caption.replace(/<br>/g, '\n')}
        onChangeText={onChange}
        numberOfLines={3}
        style={styles.captionInput}
        onFocus={onFocus}
        onBlur={onBlur}
        testID="caption-input"
      />
    </View>
  );
};

CaptionEditor.propTypes = {
  caption: string.isRequired,
  onChange: func.isRequired,
  onFocus: func.isRequired,
  onBlur: func.isRequired,
};

export default CaptionEditor;
