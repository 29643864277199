import React, { useState, createContext } from 'react';

import Notification from 'src/components/Notification';

const DURATION_MS = 2500;

const NotificationsContext = createContext({
  showNotification: () => {},
});

export const NotificationsContextProvider = ({ children }) => {
  const [notificationText, setNotificationText] = useState();
  const [visible, setVisible] = useState(false);

  const showNotification = (text, duration = DURATION_MS) => {
    setVisible(true);
    setNotificationText(text);

    setTimeout(() => {
      setNotificationText();
      setVisible(false);
    }, duration);
  };

  return (
    <NotificationsContext.Provider value={{ showNotification }}>
      {children}
      <Notification visible={visible} text={notificationText} />
    </NotificationsContext.Provider>
  );
};

export default NotificationsContext;
