import React from 'react';
import { func, string, shape } from 'prop-types';
import { Text, View } from 'react-native';
import { withNavigation } from 'react-navigation';
import styles from 'src/components/ChannelHeader/ShieldPopup.styles';
import webStyles from 'src/components/ChannelHeader/ShieldPopup.module.css';
import Modal from 'src/components/Modal';
import { TouchableWithoutFeedback } from 'src/components/Touchable';
import HorizontalHairlineStroke from 'src/components/HorizontalHairlineStroke';
import { navigationShape } from 'src/constants/Shapes';
import ShieldSvg from 'assets/images/shieldIcon.svg';

const ShieldPopup = ({ onClose, nonprofit, navigation }) => {
  let name = '';

  if (nonprofit) {
    name = nonprofit.name;
  }

  const goToNonprofit = () => {
    onClose();
    navigation.push('Nonprofit', { slug: nonprofit.slug });
  };

  return (
    <Modal
      style={styles.modalContent}
      onBackdropPress={onClose}
      backdropColor="rgba( 0, 0, 0, 0.20 )"
      backdropOpacity={1}
      backdropTransitionInTiming={100}
      animationIn="fadeIn"
      animationOut="fadeOut"
      animationInTiming={10}
      animationOutTiming={1}
    >
      <TouchableWithoutFeedback style={styles.backDrop} onPress={onClose} />
      <View style={styles.boxContainer}>
        <View style={styles.base}>
          <ShieldSvg className={webStyles.shieldIcon} />
          <View style={styles.topContainer}>
            <Text style={styles.topline}>This channel supports</Text>
            <Text style={styles.middleLine}>{name}</Text>
          </View>
          <View>
            <HorizontalHairlineStroke />
            <TouchableWithoutFeedback onPress={goToNonprofit} testID="shield-open-button">
              <Text style={styles.bottomLine}>Learn more</Text>
            </TouchableWithoutFeedback>
          </View>
          <View>
            <HorizontalHairlineStroke />
            <TouchableWithoutFeedback onPress={onClose}>
              <Text style={styles.cancel}>Cancel</Text>
            </TouchableWithoutFeedback>
          </View>
        </View>
      </View>
    </Modal>
  );
};

ShieldPopup.propTypes = {
  navigation: navigationShape.isRequired,
  onClose: func.isRequired,
  nonprofit: shape({
    name: string.isRequired,
    slug: string.isRequired,
  }),
};

ShieldPopup.defaultProps = {
  nonprofit: null,
};

export default withNavigation(ShieldPopup);
