import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    maxWidth: 448,
    marginBottom: 24,
  },
  header: {
    marginBottom: 0,
    alignSelf: 'center',
  },
  content: {
    paddingHorizontal: 48,
  },
});

export default styles;
