import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 40,
    alignItems: 'center',
    cursor: 'default',
  },
  text: {
    textAlign: 'center',
  },
  subtext: {
    marginBottom: 24,
    color: '#8E8E92',
    paddingHorizontal: 0,
  },
  imageContainer: {
    width: '100%',
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%',
    marginBottom: 16,
    boxSizing: 'border-box',
  },
  video: {
    marginBottom: 24,
  },
  headerText: {
    color: '#1D1D1F',
    marginBottom: 12,
  },
  descriptionText: {
    fontSize: 15,
    lineHeight: 18,
    color: '#8E8E92',
    marginBottom: 20,
  },
});

export default styles;
