import { StyleSheet } from 'react-native';

import Colors from 'src/styles/colors';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 66,
    backgroundColor: Colors.transparent,
    borderRadius: 10,
    flexDirection: 'row',
    marginBottom: 16,
    boxSizing: 'border-box',
    borderColor: '#868686',
    borderWidth: 1,
  },
  expandedContainer: {
    borderColor: Colors.darkGray,
    paddingLeft: 0,
    backgroundColor: Colors.inputBackgroundColor,
    paddingTop: 0,
    zIndex: 1,
  },
  countryPicker: {
    flex: 2,
    paddingHorizontal: 16,
    marginTop: 0,
    height: '100%',
    justifyContent: 'center',
    backgroundColor: '#fbfbfb',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  input: {
    flex: 7,
    borderWidth: 0,
    paddingLeft: 16,
    marginBottom: 0,
    height: '100%',
    paddingTop: 11,
    paddingBottom: 10,
    lineHeight: 20,
    backgroundColor: Colors.transparent,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderLeftColor: '#f3f3f3',
    borderLeftWidth: 1,
  },
  hide: {
    width: 0,
    height: 0,
    visible: false,
  },
});

export default styles;
