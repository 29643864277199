import React from 'react';

import StylePropType from 'src/util/StylePropType';
import ChildrenPropType from 'src/util/ChildrenPropType';
import HoverableButton from 'src/components/HoverableButton';
import styles from 'src/components/FlowModal/FlowButtonFlat.styles';

const FlowButtonFlat = ({ children, style, pressedStyle, hoverStyle, textStyle, ...props }) => (
  <HoverableButton
    style={[styles.button, style]}
    pressedStyle={[styles.pressed, pressedStyle]}
    hoverStyle={[styles.hover, hoverStyle]}
    textStyle={[styles.buttonText, textStyle]}
    {...props}
  >
    {children}
  </HoverableButton>
);

FlowButtonFlat.defaultProps = {
  style: {},
  pressedStyle: {},
  hoverStyle: {},
  textStyle: {},
};

FlowButtonFlat.propTypes = {
  children: ChildrenPropType.isRequired,
  style: StylePropType,
  pressedStyle: StylePropType,
  hoverStyle: StylePropType,
  textStyle: StylePropType,
};

export default FlowButtonFlat;
