/**
 * Returns a new promise that resolves after a duration.
 */
export function delay(durationMs: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, durationMs);
  });
}

/**
 * Retries a function a specified number of times until it no longer throws,
 * waiting the specified interval between attempts.
 *
 * @remarks
 * Recommended by https://gist.github.com/briancavalier/842626/c0eb8816486a95d907ca1cd754c9f6e17f68d589#gistcomment-2703073
 */
export function retry<Type>(
  fn: () => Promise<Type>,
  tries: number,
  intervalMs: number,
): Promise<Type> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (tries === 1) {
            reject(error);
            return;
          }

          retry(fn, intervalMs, tries - 1).then(resolve, reject);
        }, intervalMs);
      });
  });
}
