import React from 'react';
import { func } from 'prop-types';

import styles from 'src/components/FloatingPlusButton.styles';
import webStyles from 'src/components/FloatingPlusButton.module.css';
import { Button } from 'src/components/Touchable';
import PlusIconSvg from 'assets/images/plus-icon.svg';

const FloatingPlusButton = ({ onClick }) => (
  <Button testID="create-post-button" style={styles.floatingPlusButton} onPress={onClick}>
    <PlusIconSvg className={webStyles.icon} />
  </Button>
);

FloatingPlusButton.propTypes = {
  onClick: func.isRequired,
};

export default FloatingPlusButton;
