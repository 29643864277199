import React, { useEffect, useState } from 'react';
import { Image } from 'react-native';
import { string, bool } from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { find } from 'lodash';

import { useFeatureFlag, FeatureFlags } from 'src/util/featureFlags';
import { getUnseenActionFeatures, setActionFeatureAsSeen } from 'src/util/functionUtils';
import FeatureModal from 'src/components/Features/FeatureModal/FeatureModal';
import { GET_CURRENT_USER } from 'src/graphql/User';
import { CLEAR_UNSEEN_FEATURES, GET_ACTION_FEATURES } from 'src/graphql/Feature';
import { NEXT_MODAL_DELAY_MS } from 'src/constants/Delays';

/*
  Provides the wrapped component the ability to show an explanation modal.
  The HOC accepts the UID for the Feature that's being explained. If the feature with
  the specified UID doesn't exist in the database, the function has no effect.

 Sample usage:
   const Like = ({ openFeatureExplanation }) => (
     ...
   )
   export default WithFeatureExplanation('likes')(ToggledLike);
*/

export const FEATURE_EXPLANATION_LOCAL_STORAGE_KEY = 'actionFeatures';

const WithFeatureExplanation = (defaultUid) => (WrappedComponent) => {
  const Component = (props) => {
    const { enabled: explanationEnabled, loading: explanationEnabledLoading } = useFeatureFlag(
      FeatureFlags.FEATURE_EXPLANATION,
    );
    const [feature, setFeature] = useState();
    const [onContinue, setOnContinue] = useState(() => {});
    const [takeAction, setTakeAction] = useState(false);
    const [skipIfSeen, setSkipIfSeen] = useState(false);
    const [uid, setUid] = useState(defaultUid);

    const { data: { me } = {}, loading: meLoading } = useQuery(GET_CURRENT_USER);
    const { data: { actionFeatures = [] } = {}, loading: actionFeaturesLoading } =
      useQuery(GET_ACTION_FEATURES);
    const [clearUnseenFeatures] = useMutation(CLEAR_UNSEEN_FEATURES, {
      refetchQueries: [{ query: GET_CURRENT_USER }],
    });

    const handleOnContinue = () => {
      onContinue && onContinue();
    };

    useEffect(() => {
      if (!takeAction || meLoading || actionFeaturesLoading || explanationEnabledLoading || !uid) {
        return;
      }

      // Everything's loaded: take the action now.
      setTakeAction(false);

      if (!explanationEnabled) {
        handleOnContinue();
        return;
      }

      (async () => {
        const unseenActionFeatures = await getUnseenActionFeatures(
          me?.unseenActionFeatures || actionFeatures,
        );

        const featureExplanation = find(unseenActionFeatures, { uid });

        if (featureExplanation) {
          setFeature(featureExplanation);
        } else {
          handleOnContinue();
        }
      })();
    }, [
      takeAction,
      setFeature,
      actionFeatures,
      actionFeaturesLoading,
      explanationEnabled,
      explanationEnabledLoading,
      me,
      meLoading,
      onContinue,
      uid,
    ]);

    const openFeatureExplanation = ({
      handleContinue = () => {},
      skipIfSeen: skipIfSeenArg = false,
      uid: featureUid,
    } = {}) => {
      if (featureUid) {
        setUid(featureUid);
      }
      if (typeof handleContinue === 'function') {
        setOnContinue(() => handleContinue);
      }
      setSkipIfSeen(skipIfSeenArg);

      // All data to determine the next step may not be loaded yet: indicate
      // that we should take the action as soon as it is.
      setTakeAction(true);
    };

    const markFeatureAsSeen = async (actionFeature) => {
      if (me) {
        clearUnseenFeatures({ variables: { featureIds: [actionFeature.id] } });
      } else {
        setActionFeatureAsSeen(actionFeature.uid);
      }
    };

    const closeFeatureExplanation = (shouldContinue) => {
      setFeature();
      if (shouldContinue) {
        setTimeout(() => {
          handleOnContinue();
        }, NEXT_MODAL_DELAY_MS);
      }

      if (skipIfSeen) {
        markFeatureAsSeen(feature);
      }
    };

    useEffect(() => {
      if (!actionFeatures) return;

      actionFeatures.forEach((actionFeature) => {
        if (actionFeature.fileUrl) Image.prefetch(actionFeature.fileUrl);
      });
    }, [actionFeatures]);

    return (
      <>
        <WrappedComponent {...props} openFeatureExplanation={openFeatureExplanation} />
        {feature && (
          <FeatureModal
            features={[feature]}
            onClose={() => closeFeatureExplanation(false)}
            onContinue={() => closeFeatureExplanation(true)}
          />
        )}
      </>
    );
  };

  return Component;
};

WithFeatureExplanation.propTypes = {
  uid: string,
  skipIfSeen: bool.isRequired,
};

export default WithFeatureExplanation;
