/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Notification {
  EMAIL = "EMAIL",
  SMS = "SMS",
  WHATSAPP = "WHATSAPP",
}

export enum PhoneEnum {
  sms = "sms",
  whatsapp = "whatsapp",
}

export enum SubscriptionMethod {
  payment = "payment",
  token = "token",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
