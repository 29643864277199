import { gql } from '@apollo/client';

const GET_FEATURE_FLAGS = gql`
  query GetFeatureFlags {
    featureFlags {
      id
      name
      value
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_FEATURE_FLAGS };
