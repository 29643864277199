import React, { useContext } from 'react';
import { oneOfType, string, func, bool } from 'prop-types';
import { View, Text } from 'react-native';
import { useQuery } from '@apollo/client';
import ChildrenPropType from 'src/util/ChildrenPropType';
import { FeatureFlags, WithFeatureFlag } from 'src/util/featureFlags';
import { GET_CURRENT_USER } from 'src/graphql/User';
import WarpLoginContext from 'src/contexts/WarpLoginContext';
import styles from 'src/components/TopBar.styles';
import { TouchableWithoutFeedback } from 'src/components/Touchable';

const TopBar = ({
  navigation,
  headerCenter,
  headerLeft,
  headerRight,
  loggedOutHeaderLeft,
  loggedOutHeaderRight,
  loggedOutHeaderCenter,
  withHeaderUnderlay,
  hideTopBarIfLoggedIn,
  isFullWidthPage,
}) => {
  const { data: { me } = {}, loading } = useQuery(GET_CURRENT_USER);
  const { setWarpLoginOpen } = useContext(WarpLoginContext);

  let leftHeader = null;
  let centerHeader = null;
  let rightHeader = null;

  const renderComponent = (component) => {
    if (React.isValidElement(component)) {
      return component;
    }
    if (typeof component === 'string') {
      return <Text style={styles.text}>{component}</Text>;
    }
    if (typeof component === 'function') {
      return component();
    }

    return null;
  };

  if (me && hideTopBarIfLoggedIn) return null;

  if (me) {
    leftHeader = renderComponent(headerLeft, navigation);
    centerHeader = renderComponent(headerCenter, navigation);
    rightHeader = renderComponent(headerRight, navigation);
  } else {
    leftHeader = renderComponent(loggedOutHeaderLeft || headerLeft, navigation);
    centerHeader = renderComponent(loggedOutHeaderCenter || headerCenter, navigation);
    rightHeader = renderComponent(loggedOutHeaderRight || headerRight, navigation);
  }

  return (
    <View style={styles.container}>
      {/* This underlay sits under the header and is in the page's flow,
        whereas the real header is fixed at the top of the page. We set its
        height and background to the same as the actual header. This technique
        ensures that there's not a color "gap" when top overscrolling equal to
        the height of the header. */}
      {withHeaderUnderlay && <View style={styles.headerUnderlay} />}
      <View style={styles.headerContainer}>
        <View style={[styles.resizeContainer, isFullWidthPage ? styles.fullWidth : {}]}>
          {!loading && (
            <>
              <View style={styles.leftColumn}>{leftHeader}</View>
              <View
                style={[styles.centerColumn, isFullWidthPage ? styles.fullWidthCenterColumn : {}]}
              >
                {centerHeader}
                <WithFeatureFlag flag={FeatureFlags.WARP_LOGIN}>
                  <TouchableWithoutFeedback
                    onPress={() => setWarpLoginOpen(true)}
                    style={styles.openWarpLoginButton}
                    testID="open-warp-login"
                  />
                </WithFeatureFlag>
              </View>
              <View style={styles.rightColumn}>{rightHeader}</View>
            </>
          )}
        </View>
      </View>
    </View>
  );
};

TopBar.propTypes = {
  headerCenter: oneOfType([string, func, ChildrenPropType]),
  headerLeft: ChildrenPropType,
  headerRight: ChildrenPropType,
  loggedOutHeaderLeft: ChildrenPropType,
  loggedOutHeaderCenter: oneOfType([string, func, ChildrenPropType]),
  loggedOutHeaderRight: ChildrenPropType,
  withHeaderUnderlay: bool,
  hideTopBarIfLoggedIn: bool,
  isFullWidthPage: bool,
};

TopBar.defaultProps = {
  headerCenter: '',
  headerLeft: null,
  headerRight: null,
  loggedOutHeaderLeft: null,
  loggedOutHeaderCenter: null,
  loggedOutHeaderRight: null,
  withHeaderUnderlay: true,
  hideTopBarIfLoggedIn: false,
  isFullWidthPage: false,
};

export default TopBar;
