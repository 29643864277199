/* eslint-disable import/prefer-default-export */

// Recommended by https://stackoverflow.com/a/5624139/8150390
export function hexToRgba(hex, opacity = 1) {
  if (!hex) return null;

  const color = hex.substring(0, 7);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);

  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16,
      )}, ${opacity})`
    : null;
}
