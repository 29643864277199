// Constants for our production frontend code.
import getStripePublishableKey from 'StripeEnvKeyHelper';

export const API_URL = `https://api.${window.location.hostname}/graphql`;
export const DIRECT_UPLOAD_URL = `https://api.${window.location.hostname}/direct_uploads`;
export const SENTRY_DSN = 'https://4614ebe490bb4bd092892131c5eb55dc@sentry.io/1788438';
export const SENTRY_ORG_SLUG = 'channels-dot-org';
export const STRIPE_PUBLISHABLE_KEY = getStripePublishableKey();
export const ENABLE_ANIMATIONS = true;
export const INFURA_ID = '13b97f66085b46109b259f8dff4b58f6';
