import { StyleSheet } from 'react-native';

import { zIndex } from 'src/styles/zIndex';

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    zIndex: zIndex.modal,
  },
  sizeBox: {
    minHeight: 40,
  },
  sizeBoxTop: {
    height: '13vh',
  },
  sizeBoxBottom: {
    flex: 1,
  },
  backDrop: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    cursor: 'default',
    zIndex: 1,
  },
  container: {
    maxWidth: 448,
    minWidth: 275,
    width: '100%',
    paddingHorizontal: 24,
    boxSizing: 'border-box',
    zIndex: 2,
    alignSelf: 'center',
  },
  contentContainer: {
    flexGrow: 1,
  },
  content: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 18,
    overflow: 'hidden',
  },
  navigation: {
    height: 50,
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    marginVertical: 'auto',
    left: 20,
    top: 18,
  },
  title: {
    fontSize: 17,
    fontWeight: 600,
    margin: 'auto',
  },
  collectionRow: {
    height: 52,
    flexDirection: 'row',
    paddingHorizontal: 17,
    alignItems: 'center',
  },
  collectionText: {
    fontWeight: 400,
    fontSize: 17,
    lineHeight: 22,
  },
  selectedBox: {
    width: 22,
    marginRight: 8,
  },
  checkmarkIcon: {
    fontSize: 20,
    color: '#333333',
  },
  createCollectionText: {
    fontSize: 17,
    fontWeight: 600,
    color: '#1D89FF',
  },
});

export default styles;
