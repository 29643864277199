import { useEffect } from 'react';
import { differenceInMinutes } from 'date-fns';

import WithFeatureExplanation from 'src/components/hocs/WithFeatureExplanation';
import { useFeatureFlag, FeatureFlags } from 'src/util/featureFlags';
import PlatformStorage from 'src/storage';

const MODAL_DELAY_MS = 1200;
const EXPIRATION_TIME_MIN = 15;
const DEMO_WARNING_LAST_SEEN_LOCAL_STORAGE_KEY = 'demo_warning_last_seen';

const DemoWarning = ({ openFeatureExplanation }) => {
  const { enabled } = useFeatureFlag(FeatureFlags.DEMO_CHANNELS);

  const getLastShown = async () => PlatformStorage.get(DEMO_WARNING_LAST_SEEN_LOCAL_STORAGE_KEY);

  const markAsSeen = () => {
    PlatformStorage.set(DEMO_WARNING_LAST_SEEN_LOCAL_STORAGE_KEY, new Date());
  };

  const showWarning = () => {
    setTimeout(() => {
      openFeatureExplanation();
    }, MODAL_DELAY_MS);
  };

  const shouldRender = async () => {
    const lastShown = await getLastShown();
    const timePassed = differenceInMinutes(new Date(), new Date(lastShown));

    return timePassed >= EXPIRATION_TIME_MIN;
  };

  useEffect(() => {
    if (enabled) {
      (async () => {
        const shouldShowWarning = await shouldRender();
        if (shouldShowWarning) {
          showWarning();
          markAsSeen();
        }
      })();
    }
  }, [enabled]);

  return null;
};

export default WithFeatureExplanation('demo-warning')(DemoWarning);
