import React from 'react';

import ResponsiveView from 'src/components/ResponsiveView';
import MobileChannelHeader from 'src/components/ChannelHeader/MobileChannelHeader';
import DesktopChannelHeader from 'src/components/ChannelHeader/DesktopChannelHeader';

const ChannelHeader = (props) => (
  <ResponsiveView breakPoint={850} breakComponent={<DesktopChannelHeader {...props} />}>
    <MobileChannelHeader {...props} />
  </ResponsiveView>
);

export default ChannelHeader;
