import { StyleSheet } from 'react-native';
import Colors from 'src/styles/colors';

const styles = StyleSheet.create({
  container: {
    bottom: 0,
    width: '100%',
    backgroundColor: Colors.footer,
    paddingTop: 8,
    alignItems: 'center',
    marginTop: 'auto',
  },
  textContainer: {
    alignItems: 'center',
  },
  text: {
    color: '#D4D4D4',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 14,
    paddingVertical: 8,
    paddingHorizontal: 20,
  },
  linksRow: {
    flexDirection: 'row',
  },
  link: {
    color: '#7C7D7D',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 14,
    padding: 8,
  },
  brand: {
    color: '#7C7D7D',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 14,
    marginTop: 8,
    marginBottom: 12,
  },
});

export default styles;
