// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UsernameScreen-module__clearIcon__2v_t2 {\n    font-size: 18px;\n    color: rgba(60, 60, 67, 0.33);\n}\n", ""]);
// Exports
exports.locals = {
	"clearIcon": "UsernameScreen-module__clearIcon__2v_t2"
};
module.exports = exports;
