import React from 'react';
import { View } from 'react-native';

import { TouchableOpacity } from 'src/components/Touchable';
import styles from 'src/components/FlowModal/FlowModalNavigation.styles';
import LeftArrowSvg from 'assets/images/left-arrow.svg';
import webStyles from 'src/components/FlowModal/FlowModalNavigation.module.css';
import SvgImage from 'src/components/SvgImage';

const FlowModalNavigation = ({ onClose, onBack, style, action }) => (
  <View style={[styles.header, style]} testID="flow-header">
    {onBack && (
      <TouchableOpacity testID="go-back-modal-button" onPress={onBack} style={styles.button}>
        <LeftArrowSvg className={webStyles.backIcon} />
      </TouchableOpacity>
    )}
    {onClose && (
      <TouchableOpacity
        testID="close-modal-button"
        onPress={onClose}
        style={[styles.button, styles.exitButton, action && styles.actionButton]}
      >
        <SvgImage
          source={require('assets/images/cross.svg?url')}
          style={styles.crossIcon}
          alt="Close button"
        />
      </TouchableOpacity>
    )}
    {action}
  </View>
);

FlowModalNavigation.defaultProps = {
  style: {},
};

export default FlowModalNavigation;
