import React, { useContext } from 'react';
import { Text, TouchableOpacity } from 'react-native';

import AuthFlowContext from 'src/contexts/AuthFlowContext';
import styles from 'src/components/LogInButton.styles';
import { AuthSteps } from 'src/components/AuthFlow/AuthFlow';

const LogInButton = () => {
  const { toggleAuthFlow } = useContext(AuthFlowContext);

  return (
    <TouchableOpacity
      testID="navbar-log-in-button"
      style={styles.buttonStyle}
      onPress={() => {
        toggleAuthFlow({ authStep: AuthSteps.Contact });
      }}
    >
      <Text style={styles.buttonText}>Log in</Text>
    </TouchableOpacity>
  );
};

export default LogInButton;
