import React from 'react';
import { Switch as RNSwitch } from 'react-native-web';
import { bool, func, string } from 'prop-types';

import StylePropType from 'src/util/StylePropType';

const Switch = ({ value, activeTrackColor, onChange, style, testID, ...props }) => (
  <RNSwitch
    activeThumbColor="#FFFFFF"
    activeTrackColor={activeTrackColor}
    thumbColor="#FFFFFF"
    trackColor="#E7E8EA"
    onValueChange={onChange}
    value={value}
    style={style}
    testID={testID}
    {...props}
  />
);

Switch.propTypes = {
  value: bool.isRequired,
  onChange: func.isRequired,
  style: StylePropType,
  testID: string,
  activeTrackColor: string,
};

Switch.defaultProps = {
  style: {},
  testID: 'switch',
  activeTrackColor: '#31C85A',
};

export default Switch;
