import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { func } from 'prop-types';

import styles from 'src/components/PostModal/DeletePostButton.styles';
import { TouchableOpacity } from 'src/components/Touchable';
import HorizontalHairlineStroke from 'src/components/HorizontalHairlineStroke';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import ConfirmationHeader from 'src/components/ConfirmationModal/ConfirmationHeader';
import ConfirmationParagraphContainer from 'src/components/ConfirmationModal/ConfirmationParagraphContainer';
import ConfirmationParagraph from 'src/components/ConfirmationModal/ConfirmationParagraph';

const DeletePostButton = ({ onConfirm }) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleConfirm = () => {
    setIsConfirmationModalOpen(false);
    onConfirm();
  };

  return (
    <View style={styles.container}>
      <HorizontalHairlineStroke color="#CCCCCC" />
      <TouchableOpacity onPress={() => setIsConfirmationModalOpen(true)} style={styles.button}>
        <Text style={styles.text}>Delete Post</Text>
      </TouchableOpacity>
      <HorizontalHairlineStroke color="#CCCCCC" />
      {isConfirmationModalOpen && (
        <ConfirmationModal
          onClose={() => setIsConfirmationModalOpen(false)}
          onConfirm={handleConfirm}
          alertModal
        >
          <ConfirmationHeader>Are you sure you want to delete this post?</ConfirmationHeader>
          <ConfirmationParagraphContainer>
            <ConfirmationParagraph>This cannot be undone.</ConfirmationParagraph>
          </ConfirmationParagraphContainer>
        </ConfirmationModal>
      )}
    </View>
  );
};

DeletePostButton.propTypes = {
  onConfirm: func.isRequired,
};

export default DeletePostButton;
