import { string, number, bool, shape, arrayOf, func } from 'prop-types';

export const postShape = shape({
  id: string.isRequired,
  url: string,
  thumbnail: string,
  caption: string,
  aspectRatio: number,
  username: string.isRequired,
  timeStamp: number.isRequired,
  draft: bool,
  text: string,
  type: string.isRequired,
  author: shape({
    profileImage: string,
  }),
  paid: bool.isRequired,
  pinned: bool.isRequired,
  showPinnedCaption: bool,
});

export const monthShape = shape({
  beginningOfMonth: number.isRequired,
  posts: arrayOf(postShape).isRequired,
  postCount: number.isRequired,
});

export const nonprofitShape = shape({
  id: string.isRequired,
  website: string,
  image: string,
  name: string.isRequired,
  slug: string.isRequired,
  description: string,
});

export const userShape = shape({
  username: string.isRequired,
  realName: string,
  profileBanner: string,
  profileImage: string,
  tagline: string,
  nonprofit: shape({
    id: string.isRequired,
    slug: string.isRequired,
  }),
  isSubscribed: bool.isRequired,
});

export const featureShape = shape({
  title: string.isRequired,
  description: string.isRequired,
  fileUrl: string.isRequired,
  fileType: string.isRequired,
  tagline: string,
});

export const navigationShape = shape({ getParam: func.isRequired, navigate: func.isRequired });

export const collectionShape = shape({
  id: string.isRequired,
  name: string.isRequired,
  slug: string.isRequired,
  description: string.isRequired,
});
