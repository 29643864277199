import React, { useEffect, useState, useContext } from 'react';
import { Image, View } from 'react-native';
import { useMutation, useQuery } from '@apollo/client';
import { map } from 'lodash';
import { useTrack } from 'use-analytics';

import {
  LOGGED_OUT_PRODUCT_TOUR,
  NEW_SUBSCRIBER_PRODUCT_TOUR,
  UNSEEN_FEATURE_MODAL,
  ALL_FEATURE_MODAL,
} from 'src/util/analyticsEvents';
import { GET_CURRENT_USER } from 'src/graphql/User';
import { GET_FEATURES, CLEAR_UNSEEN_FEATURES, SET_PRODUCT_TOUR_SEEN } from 'src/graphql/Feature';
import FeaturesContext from 'src/contexts/FeaturesContext';
import FeatureModal from 'src/components/Features/FeatureModal/FeatureModal';

const FEATURE_MODAL_DELAY_MS = 1200;

const FeaturesRoot = () => {
  const [features, setFeatures] = useState([]);
  const { featureModalOpen, toggleFeatureModal } = useContext(FeaturesContext);
  const [trackEvent, setTrackEvent] = useState();
  const [showFeaturesCalculated, setShowFeaturesCalculated] = useState(false);
  const track = useTrack();

  const { data: { me } = {}, loading: currentUserLoading } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
  });
  const {
    data: { newFeatures, featuresForLoggedOutUsers, featuresForNewSubscribers } = {},
    loading: featuresLoading,
  } = useQuery(GET_FEATURES);

  const [clearUnseenFeatures] = useMutation(CLEAR_UNSEEN_FEATURES, {
    refetchQueries: [{ query: GET_CURRENT_USER }],
    awaitRefetchQueries: true,
  });

  const [setProductTourSeen] = useMutation(SET_PRODUCT_TOUR_SEEN, {
    refetchQueries: [{ query: GET_CURRENT_USER }],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (currentUserLoading || featuresLoading) return;

    setShowFeaturesCalculated(true);
    if (!me) {
      setFeatures(featuresForLoggedOutUsers);
      setTrackEvent(LOGGED_OUT_PRODUCT_TOUR);
      return;
    }

    if (me.showProductTour) {
      setFeatures(featuresForNewSubscribers);
      setTrackEvent(NEW_SUBSCRIBER_PRODUCT_TOUR);
      return;
    }

    if (me.unseenFeatures?.length) {
      setFeatures(me.unseenFeatures);
      setTrackEvent(UNSEEN_FEATURE_MODAL);
      setTimeout(() => {
        toggleFeatureModal();
      }, FEATURE_MODAL_DELAY_MS);
      return;
    }

    setFeatures(newFeatures);
    setTrackEvent(ALL_FEATURE_MODAL);
  }, [me, currentUserLoading, featuresLoading]);

  useEffect(() => {
    features?.forEach(({ fileUrl }) => {
      if (fileUrl) Image.prefetch(fileUrl);
    });
  }, [features]);

  const handleClose = () => {
    if (featureModalOpen) {
      toggleFeatureModal();
    }

    if (me) {
      if (me.showProductTour) {
        setProductTourSeen();
      } else if (me.unseenFeatures.length) {
        clearUnseenFeatures({ variables: { featureIds: map(me.unseenFeatures, 'id') } });
      }
    }
  };

  useEffect(() => {
    if (trackEvent && !featuresLoading && featureModalOpen) {
      track(trackEvent.open);
      if (!features || features.length === 0) {
        handleClose();
      }
    }
  }, [trackEvent, features, featuresLoading, featureModalOpen]);
  return (
    <>
      {/* Acts as a sentinel for tests to indicate that the notification bar
          has either chosen to appear, not appear, or appear after a delay. */}
      {showFeaturesCalculated && (
        <View testID="show-features-calculated" style={{ display: 'none' }} />
      )}
      {featureModalOpen && features?.length > 0 && (
        <FeatureModal features={features} onClose={handleClose} />
      )}
    </>
  );
};

export default FeaturesRoot;
