import React from 'react';
import { useTrack } from 'use-analytics';

import { copyToClipboard } from 'src/util/functionUtils';
import useNotification from 'src/util/useNotification';
import { TouchableOpacity } from 'src/components/Touchable';
import styles from 'src/components/Share/ShareChannelButton.styles';
import webStyles from 'src/components/Share/ShareChannelButton.module.css';
import ShareChannelSvg from 'assets/images/share-channel.svg';

const ShareChannelButton = () => {
  const url = window.location.href;
  const track = useTrack();
  const showNotification = useNotification();

  const onError = (err) => {
    track('error', { label: err.message });
  };

  // Attempts to share the URL via the Share Web API and returns a boolean
  // indicating whether the share was successfully completed.
  //
  // Note that we're unable to distinguish between the user cancelling the share
  // modal and a bug in iOS 14 where the share modal can only open the first
  // time on a page.
  //
  // https://developer.apple.com/forums/thread/662629
  const webApiShare = async () => {
    try {
      await navigator.share({ url });
      return true;
    } catch (err) {
      onError(err);
    }

    return false;
  };

  // Shares the URL via the clipboard.
  const clipboardShare = async () => {
    await copyToClipboard(url);
    showNotification('Copied to clipboard!');
  };

  const onPress = async () => {
    try {
      (await webApiShare()) || (await clipboardShare());
    } catch (err) {
      onError(err);
    }
  };

  return (
    <TouchableOpacity style={styles.button} onPress={onPress}>
      <ShareChannelSvg className={webStyles.icon} />
    </TouchableOpacity>
  );
};

export default ShareChannelButton;
