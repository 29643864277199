import { StyleSheet } from 'react-native';

import { zIndex } from 'src/styles/zIndex';
import { FLOW_MODAL_MIN_HEIGHT } from 'src/constants/Sizes';

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    overflowY: 'auto',
    zIndex: zIndex.modal,
  },
  sizeBoxTop: {
    height: 'calc(-200px + 15vh + 15vw)',
    minHeight: 16,
    maxHeight: '9vh',
  },
  sizeBoxBottom: {
    minHeight: 64,
  },
  backDrop: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    cursor: 'default',
    zIndex: 1,
    width: `100%`,
  },
  popupContainer: {
    width: '100%',
    flex: 1,
    backgroundColor: 'white',
    borderTopLeftRadius: 18,
    borderTopRightRadius: 18,
    marginTop: 4,
    overflow: 'hidden',
  },
  popupContainerBreak: {
    backgroundColor: 'white',
    borderRadius: 18,
    width: 448,
    minHeight: FLOW_MODAL_MIN_HEIGHT,
    zIndex: 2,
    overflow: 'hidden',
  },
  lightweightPopupContainerBreak: {
    width: '94%',
    maxWidth: 448,
    minWidth: 275,
    marginHorizontal: '3%',
  },
  content: {
    zIndex: 2,
    flex: 1,
    pointerEvents: 'all',
  },
  sizer: {
    alignSelf: 'center',
    flex: 1,
    width: '100%',
    minWidth: 260,
    zIndex: 2,
  },
  sizerBreak: {
    alignSelf: 'center',
    alignItems: 'center',
    zIndex: 2,
    width: '100%',
    pointerEvents: 'none',
  },
});

export default styles;
