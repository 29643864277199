/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Pressable } from 'react-native';
import { func, string } from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { includes } from 'lodash';

import { GET_USERNAME_ERRORS } from 'src/graphql/User';
import ErrorMessage from 'src/components/ErrorMessage';
import Link from 'src/components/Link';
import BaseInput from 'src/components/BaseInput';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import ConfirmationHeader from 'src/components/ConfirmationModal/ConfirmationHeader';
import ConfirmationParagraphContainer from 'src/components/ConfirmationModal/ConfirmationParagraphContainer';
import ConfirmationParagraph from 'src/components/ConfirmationModal/ConfirmationParagraph';
import LoadingIndicator, { MIN_LOADING_DURATION_MS } from 'src/components/LoadingIndicator';
import styles from 'src/components/AuthFlow/UsernameScreen.styles';
import webStyles from 'src/components/AuthFlow/UsernameScreen.module.css';
import ClearIconSvg from 'assets/images/clear-icon.svg';

const UsernameScreen = ({
  onSubmit,
  onClose,
  artistUsername,
  artistName,
  username,
  setUsername,
  toggleIsSignup,
}) => {
  const [minLoadingOnProgress, setMinLoadingOnProgress] = useState(false);
  const [errors, setErrors] = useState([]);
  const usernameInput = useRef(null);

  const [
    validateUsername,
    { loading: loadingUsername, called, data: { usernameErrors = [] } = {} },
  ] = useLazyQuery(GET_USERNAME_ERRORS);

  const description = artistUsername
    ? `This is how ${artistName || `@${artistUsername}`} will see you. You can change it later.`
    : `This is how you'll be seen on channels.org. You can change it later.`;

  const handleSubmit = async () => {
    setErrors([]);
    if (!username.trim()) {
      setErrors(['Username cannot be empty']);
      return;
    }

    setMinLoadingOnProgress(true);
    validateUsername({ variables: { username } });
    setTimeout(() => {
      setMinLoadingOnProgress(false);
    }, MIN_LOADING_DURATION_MS);
  };

  const focus = () => {
    usernameInput.current.focus();
  };

  useEffect(() => {
    if (called && !minLoadingOnProgress && !loadingUsername) {
      if (usernameErrors.length) {
        setErrors(usernameErrors);
        focus();
      } else {
        onSubmit(username);
      }
    }
  }, [minLoadingOnProgress, loadingUsername, usernameErrors, called]);

  const loading = minLoadingOnProgress || loadingUsername;

  const onChangeText = (text) => {
    setUsername(text.replace('@', ''));
  };

  const clearUsername = () => {
    onChangeText('');
    focus();
  };

  const showLogin = includes(errors, 'Username has already been taken.');

  return (
    <ConfirmationModal
      topContainerStyle={styles.modalTopContainer}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmText="Next"
    >
      <View style={styles.confirmationHeaderContainer}>
        <ConfirmationHeader>Choose a username.</ConfirmationHeader>
      </View>
      <ConfirmationParagraphContainer>
        <ConfirmationParagraph>{description}</ConfirmationParagraph>
      </ConfirmationParagraphContainer>
      <View style={styles.inputContainer}>
        <BaseInput
          isOnlyInput
          focus
          ref={usernameInput}
          style={styles.input}
          onChange={onChangeText}
          value={`@${username}`}
          testID="username-input"
          autoCorrect={false}
          autoCapitalize="none"
          returnKeyType="next"
          onSubmitEditing={handleSubmit}
        />
        <View style={styles.rightInputComponent}>
          {loading && <LoadingIndicator />}
          {!loading && username && (
            <Pressable
              onPressOut={clearUsername}
              style={({ pressed }) => (pressed ? styles.clearButtonPressed : styles.clearButton)}
            >
              <ClearIconSvg className={webStyles.clearIcon} />
            </Pressable>
          )}
        </View>
      </View>
      <View style={styles.errorMessageContainer}>
        {errors.map((error) => (
          <ErrorMessage
            style={styles.errorMessage}
            textStyle={styles.errorMessageText}
            key={error}
            text={error}
            showPrefix={false}
          />
        ))}
      </View>
      {showLogin && (
        <ConfirmationParagraphContainer style={styles.signedUpParagraph}>
          <ConfirmationParagraph>
            <View style={styles.loginContainer}>
              <Text>Already signed up? </Text>
              <Link onPress={toggleIsSignup} testID="login-link">
                Log in
              </Link>
            </View>
          </ConfirmationParagraph>
        </ConfirmationParagraphContainer>
      )}
    </ConfirmationModal>
  );
};

UsernameScreen.defaultProps = {
  artistUsername: null,
};

UsernameScreen.propTypes = {
  onSubmit: func.isRequired,
  onClose: func.isRequired,
  artistUsername: string,
  toggleIsSignup: func.isRequired,
};

export default UsernameScreen;
