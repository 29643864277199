import React from 'react';
import { Text, TextInput, View } from 'react-native';
import { string, func, number, bool } from 'prop-types';

import StylePropType from 'src/util/StylePropType';
import styles from 'src/components/CharacterLimitedInputField.styles';

const CharacterLimitedInputField = ({
  text,
  onChange,
  maxLength,
  style,
  inputStyle,
  numberOfLines,
  disabled,
  ...props
}) => {
  const { length } = text;

  return (
    <View style={style}>
      <TextInput
        multiline
        value={text}
        onChangeText={onChange}
        style={inputStyle}
        maxLength={maxLength}
        numberOfLines={numberOfLines}
        editable={!disabled}
        {...props}
      />
      <View style={styles.charactersLeftContainer}>
        {!!length && !disabled && <Text style={styles.charactersLeft}>{maxLength - length}</Text>}
      </View>
    </View>
  );
};

CharacterLimitedInputField.propTypes = {
  text: string,
  numberOfLines: number,
  onChange: func,
  maxLength: number.isRequired,
  disabled: bool,
  style: StylePropType,
  inputStyle: StylePropType,
};

CharacterLimitedInputField.defaultProps = {
  text: '',
  numberOfLines: 1,
  disabled: false,
  onChange: () => {},
  style: {},
  inputStyle: {},
};

export default CharacterLimitedInputField;
