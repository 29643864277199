import { StyleSheet } from 'react-native';
import { zIndex } from 'src/styles/zIndex';

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    overflowY: 'hidden',
    zIndex: zIndex.modal,
  },
  sizeBox: {
    minHeight: 40,
  },
  sizeBoxTop: {
    height: '13vh',
  },
  sizeBoxBottom: {
    flex: 1,
  },
  backDrop: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    cursor: 'default',
    zIndex: 1,
  },
  container: {
    maxWidth: 448,
    minWidth: 275,
    width: '100%',
    paddingHorizontal: 24,
    boxSizing: 'border-box',
    zIndex: 2,
    alignSelf: 'center',
  },
  content: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 18,
  },
  contentContainerStyle: {
    flexGrow: 1,
  },
  navigation: {
    height: 50,
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  button: {
    width: 50,
  },
  title: {
    fontSize: 17,
    fontWeight: 600,
    margin: 'auto',
  },
  actionText: {
    color: '#1D89FF',
    fontSize: 17,
    lineHeight: 22,
    fontWeight: 600,
  },
  actionButton: {
    alignItems: 'flex-end',
  },
  loadingContainer: {
    width: 50,
    alignItems: 'flex-end',
  },
  loading: {
    marginRight: 0,
  },
  inputContainer: {
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  label: {
    fontWeight: 600,
    fontSize: 17,
    lineHeight: 22,
    color: '#333333',
    marginBottom: 8,
  },
  input: {
    color: '#333333',
    fontSize: 17,
    lineHeight: 22,
  },
  disabledInput: {
    color: '#7A7A7B',
    cursor: 'default',
  },
  informationContainer: {
    paddingVertical: 24,
    paddingHorizontal: 20,
    alignItems: 'center',
    flexDirection: 'row',
  },
  informationText: {
    fontSize: 14,
    color: '#7A7A7B',
  },
  switch: {
    height: 31,
    width: 51,
    marginLeft: 'auto',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  publicLabel: {
    marginBottom: 0,
  },
});

export default styles;
