// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MobileChannelHeader-module__shieldIcon__2FPa_ {\n    color: #FFFFFF;\n    opacity: 0.7;\n    margin-right: 7px;\n    font-size: 16px;\n    filter: drop-shadow(-1.5px 2px 1.5px rgba(0 0 0 / 0.26));\n}\n", ""]);
// Exports
exports.locals = {
	"shieldIcon": "MobileChannelHeader-module__shieldIcon__2FPa_"
};
module.exports = exports;
