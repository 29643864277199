import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  collectionRow: {
    height: 52,
    flexDirection: 'row',
    paddingHorizontal: 17,
    alignItems: 'center',
  },
  collectionText: {
    fontWeight: 400,
    fontSize: 17,
    lineHeight: 22,
  },
  selectedBox: {
    width: 22,
    marginRight: 8,
  },
  actionsContainer: { marginLeft: 'auto', flexDirection: 'row', alignItems: 'center' },
  rowAction: {
    width: 40,
    alignItems: 'flex-end',
  },
});

export default styles;
