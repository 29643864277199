// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CollectionPickerModal-module__closeIcon__3ncFw {\n    font-size: 15px;\n}\n.CollectionPickerModal-module__plusIcon__2BuAd {\n    color: #1D89FF;\n    font-size: 15px;\n    margin-top: 1px;\n}\n", ""]);
// Exports
exports.locals = {
	"closeIcon": "CollectionPickerModal-module__closeIcon__3ncFw",
	"plusIcon": "CollectionPickerModal-module__plusIcon__2BuAd"
};
module.exports = exports;
