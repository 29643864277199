import { StyleSheet } from 'react-native';
import { zIndex } from 'src/styles/zIndex';

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    overflowY: 'hidden',
    zIndex: zIndex.modal,
  },
  sizeBoxTop: {
    height: 'calc(-200px + 15vh + 15vw)',
    minHeight: 16,
    maxHeight: '9vh',
  },
  sizeBoxBottom: {
    minHeight: 64,
  },
  backDrop: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    cursor: 'default',
    zIndex: 1,
  },
  container: {
    maxWidth: 512,
    minWidth: 275,
    width: '100%',
    paddingHorizontal: '3%',
    boxSizing: 'border-box',
    zIndex: 2,
    alignSelf: 'center',
  },
  content: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 18,
  },
  featuresContainer: {
    cursor: 'auto',
  },
});

export default styles;
