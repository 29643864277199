// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ShareChannelButton-module__icon__E4wT7 {\n    font-size: 21px;\n    color: rgba(255 255 255 / 0.95);\n    margin: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"icon": "ShareChannelButton-module__icon__E4wT7"
};
module.exports = exports;
