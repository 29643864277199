import React from 'react';

import { Text } from 'react-native';
import StylePropType from 'src/util/StylePropType';
import ChildrenPropType from 'src/util/ChildrenPropType';
import styles from 'src/components/FlowModal/FlowHeader.styles';

const FlowHeader = ({ children, style }) => (
  <Text testID="modal-title" style={[styles.text, style]}>
    {children}
  </Text>
);

FlowHeader.propTypes = {
  style: StylePropType,
  children: ChildrenPropType.isRequired,
};

FlowHeader.defaultProps = {
  style: {},
};
export default FlowHeader;
