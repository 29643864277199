import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  containerStyle: {
    height: 50,
    minWidth: 45,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    color: '#666666',
  },
  backText: {
    color: '#FFFFFF',
    fontSize: 16,
    fontFamily: 'System',
    lineHeight: 19,
  },
});

export default styles;
