import React, { useEffect } from 'react';
import WebModal from 'modal-enhanced-react-native-web';

import wrapAnimationDuration from 'src/util/wrapAnimationDuration';
import enableScroll from 'src/util/enableScroll';

WebModal.setAppElement('body');

const Modal = (props) => {
  useEffect(() => {
    enableScroll(false);

    return () => {
      enableScroll();
    };
  }, []);

  return (
    <WebModal
      isVisible
      backdropColor="rgba(0,0,0,0.24)"
      backdropOpacity={1}
      backdropTransitionInTiming={wrapAnimationDuration(100)}
      animationIn="fadeIn"
      animationOut="fadeOut"
      animationInTiming={wrapAnimationDuration(10)}
      animationOutTiming={wrapAnimationDuration(1)}
      {...props}
    />
  );
};

export default Modal;
