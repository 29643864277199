import React from 'react';
import { createBrowserApp } from '@react-navigation/web';
import { isEqual } from 'lodash';

import RootNavigator from 'src/navigation/MainNavigator';

// Gets the current screen from navigation state.
//
// Returns an object of the form:
//
// {
//   name: 'MyScreen,
//   params: { 'MyParam1': 'MyParamValue1', ... }
// }
function getActiveRoute(navigationState) {
  if (!navigationState) {
    return null;
  }

  const route = navigationState.routes[navigationState.index];
  // Dive into nested navigators.
  if (route.routes) {
    return getActiveRoute(route);
  }

  return {
    name: route.routeName,
    params: route.params,
  };
}

function createPlatformContainer(navigator) {
  const AppContainer = createBrowserApp(navigator);

  return () => (
    <AppContainer
      onNavigationStateChange={(prevState, currentState) => {
        const currentRoute = getActiveRoute(currentState);
        const prevRoute = getActiveRoute(prevState);

        if (!isEqual(currentRoute, prevRoute)) {
          window.scrollTo(0, 0);
        }
      }}
    />
  );
}

export default createPlatformContainer(RootNavigator);
