import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 3,
  },
  nonpressableListItem: {
    cursor: 'default',
  },
  username: {
    fontSize: 14,
    lineHeight: 19,
    fontWeight: 600,
    color: '#333333',
    marginBottom: 1,
  },
  badgeIcon: {
    color: '',
    width: 13,
    height: 13,
    marginLeft: 4,
    marginBottom: 1,
  },
  imageContainer: {
    width: 32,
    height: 32,
    borderRadius: 16,
    marginRight: 14,
  },
  profileImage: {
    width: '100%',
  },
  imageBorder: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    borderRadius: 16,
    width: '100%',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    zIndex: 1,
  },
});

export default styles;
