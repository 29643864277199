import React, { useContext, useState } from 'react';
import { View, Image, TouchableOpacity, Text } from 'react-native';
import { func, bool, string, arrayOf, number } from 'prop-types';
import { startCase } from 'lodash';

import ErrorMessage from 'src/components/ErrorMessage';
import PhoneInput from 'src/components/PhoneInput';
import TabsSwitch from 'src/components/TabsSwitch';
import Link from 'src/components/Link';
import HorizontalHairlineStroke from 'src/components/HorizontalHairlineStroke';
import FlowDescriptionLarge from 'src/components/FlowModal/FlowDescriptionLarge';
import FlowDescriptionSmall from 'src/components/FlowModal/FlowDescriptionSmall';
import FlowButtonFlat from 'src/components/FlowModal/FlowButtonFlat';
import FlowHeader from 'src/components/FlowModal/FlowHeader';
import EmailInput from 'src/components/EmailInput';
import DotsPagination from 'src/components/DotsPagination';
import PaymentOptions from 'src/components/PaymentOptions';
import { useFeatureFlag, FeatureFlags } from 'src/util/featureFlags';
import styles from 'src/components/AuthFlow/ContactScreen.styles';
import webStyles from 'src/components/AuthFlow/ContactScreen.module.css';
import ConnectWalletFlowContext from 'src/contexts/ConnectWalletFlowContext';
import AuthFlowContext from 'src/contexts/AuthFlowContext';
import { NEXT_MODAL_DELAY_MS } from 'src/constants/Delays';
import { delay } from 'src/util/promiseUtils';

const ContactMethods = {
  Phone: 'phone',
  Email: 'email',
};

const PhoneTypes = {
  SMS: 'sms',
  WhatsApp: 'whatsapp',
};
const hairlineColor = '#CECECE';

const sources = {
  SMS: require('assets/images/text-message-22x22.svg?url'),
  WhatsApp: require('assets/images/whatsapp-22x22.png?url'),
};

const ContactScreen = ({
  errors,
  email,
  setEmail,
  phone,
  setPhone,
  username,
  artistName,
  onSubmit,
  isSignUp,
  onToggleIsSignUp,
  setErrors,
  showPaymentOptions,
  totalSteps,
  showLoggedOutMessage,
  onClose,
}) => {
  const [authMethod, setAuthMethod] = useState(email ? ContactMethods.Email : ContactMethods.Phone);
  const [isSMS, setSMS] = useState(true);
  const { toggleAuthFlow } = useContext(AuthFlowContext);
  const { openConnectWalletFlow } = useContext(ConnectWalletFlowContext);

  const changeAuthMethod = (newAuthMethod) => {
    if (newAuthMethod === ContactMethods.Phone) {
      setEmail('');
    } else {
      setPhone('');
    }
    setErrors([]);
    setAuthMethod(newAuthMethod);
  };

  const onWeb3SignIn = async () => {
    toggleAuthFlow();
    await delay(NEXT_MODAL_DELAY_MS);
    openConnectWalletFlow();
  };

  const authOptions = [
    { label: 'phone', value: ContactMethods.Phone },
    { label: 'email', value: ContactMethods.Email },
  ];

  const { enabled: isWhatsappLoginEnabled } = useFeatureFlag(FeatureFlags.WHATSAPP_LOGIN);

  const isPhone = ContactMethods.Phone === authMethod;
  const contactVerb = isPhone ? 'text' : 'email';

  const smallText = (
    <ul className={webStyles.smallText}>
      {isPhone ? (
        <>
          <li>We never share phone numbers. Ever.</li>
          <li>
            Get a text message when new content {artistName ? `from ${artistName} ` : ''}is
            published.
          </li>
          <li>You can switch to email or opt-out at any time.</li>
        </>
      ) : (
        <>
          <li>We never share emails. Ever.</li>
          <li>
            Get an email when new content {artistName ? `from ${artistName} ` : ''}is published.
          </li>
          <li>You can switch to text messages or opt-out at any time.</li>
          <li>
            For additional anonymity, you can use an email masking service like Apple’s{' '}
            <em>Hide My Email</em> or SimpleLogin.
          </li>
        </>
      )}
    </ul>
  );

  return (
    <>
      {isSignUp && (
        <>
          <FlowDescriptionSmall style={styles.welcome}>WELCOME @{username}!</FlowDescriptionSmall>
          <DotsPagination totalSteps={totalSteps} currentStep={2} />
        </>
      )}
      {showLoggedOutMessage && (
        <>
          <FlowDescriptionLarge style={[styles.contentContainer, styles.recentlyLoggedOutText]}>
            Looks like you may have gotten logged out. This shouldn’t occur often. Log in below or{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link onPress={onClose}>continue without logging in</Link>.
          </FlowDescriptionLarge>
          <HorizontalHairlineStroke
            style={styles.recentlyLoggedOutHairline}
            color={hairlineColor}
          />
        </>
      )}
      <View style={[styles.contentContainer, styles.mainContainer]}>
        <FlowHeader style={isSignUp ? styles.headerSignedOut : styles.header}>
          {isSignUp ? 'Get a link to log in' : 'Log in'}
        </FlowHeader>
        <TabsSwitch
          style={styles.tabs}
          tabs={authOptions}
          activeTab={authMethod}
          onTabPressed={changeAuthMethod}
          showHelpText={isSignUp}
        />
        <FlowDescriptionLarge>
          We&apos;ll {contactVerb} you a link to log in – no password necessary.
        </FlowDescriptionLarge>
        {isPhone ? (
          <PhoneInput
            isOnlyInput
            placeholder="Phone number"
            onChange={setPhone}
            value={phone}
            excludeFromFS
          />
        ) : (
          <EmailInput
            isOnlyInput
            onChange={setEmail}
            value={email}
            placeholder="Your email"
            testID="email"
          />
        )}
        {errors.map((error) => (
          <ErrorMessage key={error} text={error} style={styles.errorMessage} />
        ))}
        {/* I, Curtis, am commenting out this section (the list of information typically listed below
        phone/email input), in order to to clean up the visual appearance of the onboarding flow.
        {isSignUp && <FlowDescriptionSmall>{smallText}</FlowDescriptionSmall>}
        */}
        {isPhone ? (
          <>
            <FlowButtonFlat
              style={isSMS ? {} : styles.whatsappButton}
              hoverStyle={isSMS ? {} : styles.whatsappButtonHover}
              pressedStyle={isSMS ? {} : styles.whatsappButtonPressed}
              textStyle={styles.mobileButtonText}
              onPress={() => (isSMS ? onSubmit(PhoneTypes.SMS) : onSubmit(PhoneTypes.WhatsApp))}
            >
              {!isSMS && (
                <Image
                  source={sources.WhatsApp}
                  resizeMode="contain"
                  style={styles.whatsAppButtonIcon}
                />
              )}
              {isSMS ? `${startCase(contactVerb)} me a link` : `Message me a link`}
            </FlowButtonFlat>
            <FlowDescriptionSmall style={styles.messageFootnote}>
              {isSMS ? `Message and data rates may apply.` : `Messages are sent via WhatsApp.`}
            </FlowDescriptionSmall>
            {isWhatsappLoginEnabled && (
              <FlowDescriptionLarge style={styles.createAccount}>
                {'or '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <TouchableOpacity
                  style={[styles.switchMobileLink, styles.link]}
                  onPress={() => setSMS(!isSMS)}
                  testID="toggle-sign-up-link"
                >
                  {isSMS ? (
                    <>
                      use WhatsApp
                      <Image
                        source={sources.WhatsApp}
                        resizeMode="contain"
                        style={styles.whatsAppIcon}
                      />
                      instead.
                    </>
                  ) : (
                    'use SMS Text Message instead.'
                  )}
                </TouchableOpacity>
              </FlowDescriptionLarge>
            )}
          </>
        ) : (
          <FlowButtonFlat onPress={() => onSubmit('')}>
            {startCase(contactVerb)} me a link
          </FlowButtonFlat>
        )}
      </View>
      {!isSignUp && (
        <>
        {/* I, Curtis, am commenting out the wallet connect section from the Login modal,
        in order to clean up the presentation.
          <HorizontalHairlineStroke
            color={hairlineColor}
            text="Or"
            style={styles.connectWalletHairline}
          />
          <View style={styles.contentContainer}>
            <FlowHeader style={styles.connectWalletHeader}>Connect wallet</FlowHeader>
            <FlowButtonFlat
              style={styles.connectWalletButton}
              hoverStyle={styles.connectWalletButtonHover}
              pressedStyle={styles.connectWalletButtonPressed}
              onPress={onWeb3SignIn}
            >
              <Image
                source={require('assets/images/ethereum-icon.png')}
                resizeMode="contain"
                style={styles.ethereumIcon}
              />
              Sign in with web3 wallet
            </FlowButtonFlat>
          </View>
          */}
        </>
      )}
      {showPaymentOptions && isSignUp && (
        <>
        {/* I, Curtis, am commenting out the payment options section on the phone/email input modal, 
        to visually simplify our onboarding presentation. 
          <HorizontalHairlineStroke style={styles.paymentHairline} color={hairlineColor} />
          <View style={styles.contentContainer}>
            <PaymentOptions
              title="Step 3: Available payment methods."
              style={styles.paymentOptions}
            />
            <FlowDescriptionSmall style={styles.amount}>
              Amount will be $1.29/month + applicable tax. Cancel anytime.
            </FlowDescriptionSmall>
          </View>
          */}
        </>
      )}
      {onToggleIsSignUp && (
        <>
          <HorizontalHairlineStroke color={hairlineColor} />
          <View style={styles.contentContainer}>
            <FlowDescriptionLarge style={styles.createAccount}>
              {isSignUp ? 'Already have an account? ' : "Don't have an account? "}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <TouchableOpacity
                style={styles.link}
                onPress={onToggleIsSignUp}
                testID="toggle-sign-up-link"
              >
                <Text>{isSignUp ? 'Log in' : 'Sign up'}</Text>
              </TouchableOpacity>
            </FlowDescriptionLarge>
          </View>
        </>
      )}
    </>
  );
};

ContactScreen.defaultProps = {
  errors: [],
  email: '',
  phone: '',
  onToggleIsSignUp: null,
  showPaymentOptions: false,
  username: null,
  artistName: null,
};

ContactScreen.propTypes = {
  onSubmit: func.isRequired,
  isSignUp: bool.isRequired,
  errors: arrayOf(string),
  email: string,
  phone: string,
  setErrors: func.isRequired,
  setEmail: func.isRequired,
  setPhone: func.isRequired,
  onToggleIsSignUp: func,
  showPaymentOptions: bool,
  totalSteps: number.isRequired,
  username: string,
  artistName: string,
  showLoggedOutMessage: bool.isRequired,
  onClose: func.isRequired,
};

export default ContactScreen;
