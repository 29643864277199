import { useEffect } from 'react';
import { Platform } from 'react-native';
import { useQuery } from '@apollo/client';
import { withNavigation } from 'react-navigation';

import { getCurrentRouteName } from 'src/util/urlUtils';
import { hideSplashScreen } from 'src/util/splashScreenUtils';
import { GET_CURRENT_USER } from 'src/graphql/User';

const UserLoadingSplashScreen = ({ navigation }) => {
  const { loading } = useQuery(GET_CURRENT_USER);
  const activeRoute = getCurrentRouteName(navigation.state);
  const blacklistScreens = ['LinkLogIn', 'Post', 'HomeLoginScreen'];

  useEffect(() => {
    if (!loading && Platform.OS === 'web' && !blacklistScreens.includes(activeRoute)) {
      hideSplashScreen();
    }
  }, [loading, activeRoute]);

  // Used for side-effects only.
  return null;
};

export default withNavigation(UserLoadingSplashScreen);
