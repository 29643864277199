// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PinnedButton-module__icon__1D7Hu {\n    font-size: 14px;\n}\n.PinnedButton-module__unselected__32X-u {\n    color: #7A7A7B;\n}\n.PinnedButton-module__selected__1jFfR {\n    color: #1D89FF;\n}\n", ""]);
// Exports
exports.locals = {
	"icon": "PinnedButton-module__icon__1D7Hu",
	"unselected": "PinnedButton-module__unselected__32X-u",
	"selected": "PinnedButton-module__selected__1jFfR"
};
module.exports = exports;
