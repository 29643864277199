import React from 'react';
import { StyleSheet, Text } from 'react-native';

import StylePropType from 'src/util/StylePropType';
import ChildrenPropType from 'src/util/ChildrenPropType';

const styles = StyleSheet.create({
  text: {
    fontSize: 13,
    lineHeight: 16,
    marginTop: 2,
    marginBottom: 6,
    textAlign: 'center',
  },
});

const ConfirmationParagraph = ({ children, style }) => (
  <Text style={[styles.text, style]}>{children}</Text>
);

ConfirmationParagraph.propTypes = {
  style: StylePropType,
  children: ChildrenPropType.isRequired,
};

ConfirmationParagraph.defaultProps = {
  style: {},
};
export default ConfirmationParagraph;
