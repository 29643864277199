import React from 'react';
import { withNavigation } from 'react-navigation';

import styles from 'src/components/ChannelPoster.styles';
import ProgressiveImage from 'src/components/ProgressiveImage';
import { TouchableOpacity } from 'src/components/Touchable';

const ChannelPoster = ({ url, username, navigation, onPress, loadingColor }) => {
  const handlePress = () => {
    if (onPress) {
      onPress(username);
    } else {
      navigation.push('User', { username });
    }
  };

  return (
    <TouchableOpacity
      onPress={handlePress}
      style={styles.container}
      testID={`channel-poster-${username}`}
    >
      <ProgressiveImage
        ratio={16 / 9}
        style={styles.image}
        imageWebStyle={{
          boxShadow: 'rgb(21 25 31 / 51%) 0px 16px 16px -16px',
        }}
        imageSource={{ uri: url }}
        borderRadius={23}
        dataSet={{ 'background-position-top': true }}
        placeholderColor={loadingColor}
      />
    </TouchableOpacity>
  );
};

export default withNavigation(ChannelPoster);
