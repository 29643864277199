import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 94,
    paddingHorizontal: 20,
  },
  imageContainer: {
    width: 48,
    height: 'fit-content',
    marginTop: 20,
    borderRadius: 24,
  },
  profileImage: {
    width: '100%',
  },
  imageBorder: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    borderRadius: 24,
    width: '100%',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    zIndex: 1,
  },
  divider: {
    width: 20,
  },
  captionInput: {
    flex: 1,
    paddingTop: 34,
    paddingBottom: 30,
    outline: 'none',
  },
});

export default styles;
