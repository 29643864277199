import { StyleSheet } from 'react-native';

import Colors from 'src/styles/colors';

const styles = StyleSheet.create({
  link: {
    color: Colors.accentColor,
    cursor: 'pointer',
    textDecorationLine: 'none',
  },
  hover: {
    textDecorationLine: 'underline',
  },
});

export default styles;
