import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  dotsContainer: {
    width: 'fit-content',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
});

export default styles;
