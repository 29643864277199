import React from 'react';
import { View } from 'react-native';
import { bool } from 'prop-types';

import StylePropType from 'src/util/StylePropType';
import wrapAnimationDuration from 'src/util/wrapAnimationDuration';
import styles from 'src/components/LoadingIndicator.styles';
import SvgImage from 'src/components/SvgImage';

// The minimum amount of time to keep a loading indicator on the page.
export const MIN_LOADING_DURATION_MS = wrapAnimationDuration(750);

const LoadingIndicator = ({ style, inverted }) => (
  <View style={[styles.loadingContainer, style]} testID="loading-indicator">
    {inverted ? (
      <SvgImage
        source={require(`assets/images/loading-inverted.svg?url`)}
        style={styles.loading}
        alt="Loading indicator"
      />
    ) : (
      <SvgImage
        source={require(`assets/images/loading.svg?url`)}
        style={styles.loading}
        alt="Loading indicator"
      />
    )}
  </View>
);

LoadingIndicator.propTypes = {
  style: StylePropType,
  inverted: bool,
};

LoadingIndicator.defaultProps = {
  style: {},
  inverted: false,
};

export default LoadingIndicator;
