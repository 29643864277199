// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DotsPagination-module__dotIcon__2tzGy {\n    font-size: 10px;\n    margin: 0 5px;\n}\n.DotsPagination-module__emptyDot__epGMy {\n    color: #E5E5E5;\n}\n.DotsPagination-module__fullDot__dAnRO {\n    color: #0279FF;\n}\n", ""]);
// Exports
exports.locals = {
	"dotIcon": "DotsPagination-module__dotIcon__2tzGy",
	"emptyDot": "DotsPagination-module__emptyDot__epGMy",
	"fullDot": "DotsPagination-module__fullDot__dAnRO"
};
module.exports = exports;
