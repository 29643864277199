import React, { forwardRef } from 'react';
import { string, bool, func } from 'prop-types';

import styles from 'src/components/EmailInput.styles';
import BaseInput from 'src/components/BaseInput';

const EmailInput = forwardRef(
  (
    {
      isOnlyInput,
      focus,
      onChange,
      onSubmit,
      placeholder,
      placeholderTextColor,
      value,
      style,
      ...props
    },
    ref,
  ) => {
    return (
      <BaseInput
        /*
         * Note the React Native docs say that `autoCompleteType` is the right
         * prop to control autocomplete, but `autoComplete` is the one that
         * actually has an effect.
         */
        autoComplete="email"
        keyboardType="email-address"
        isOnlyInput
        ref={ref}
        focus={focus}
        inputStyle={[styles.textInput, style]}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        excludeFromFS
        {...props}
      />
    );
  },
);

EmailInput.propTypes = {
  placeholder: string,
  placeholderTextColor: string,
  focus: bool,
  onChange: func.isRequired,
  value: string,
};

EmailInput.defaultProps = {
  placeholder: '',
  placeholderTextColor: '#3C3C4399',
  focus: false,
  value: '',
};

export default EmailInput;
