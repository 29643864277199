import React, { Suspense } from 'react';

import LoadingScreen from 'src/components/LazyLoading/LoadingScreen';

/*
  Lazy loads the wrapped component.
  It also shows the splash screen while it loads.
*/

const WithLazyLoading = (WrappedComponent, { loadingScreen = <LoadingScreen /> } = {}) => {
  const Component = (props) => (
    <Suspense fallback={loadingScreen}>
      <WrappedComponent {...props} />
    </Suspense>
  );

  return Component;
};

export default WithLazyLoading;
