import { StyleSheet } from 'react-native';

import { heights } from 'src/styles/base';
import { zIndex } from 'src/styles/zIndex';
import { TOP_BAR_HEIGHT_PX } from 'src/constants/Sizes';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  headerUnderlay: {
    width: '100%',
    height: heights.topBar,
    backgroundColor: '#222327',
    position: 'absolute',
    top: 0,
  },
  headerContainer: {
    width: '100vw',
    height: heights.topBar,
    backgroundImage:
      'linear-gradient(180deg, #222327 0%, rgba(34, 35, 39, 0.74) 37%, #22232700 100%)',
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    zIndex: zIndex.topBar,
    position: 'fixed',
    backdropFilter: 'saturate(50%) blur(20px)',
  },
  text: {
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: -0.15,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'center',
    maxWidth: '100%',
    cursor: 'default',
  },
  resizeContainer: {
    minWidth: 250,
    maxWidth: 750,
    width: '100%',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    height: TOP_BAR_HEIGHT_PX,
  },
  leftColumn: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 'calc(3% - 20px)',
  },
  centerColumn: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: 'calc(100% - 156px)',
    margin: 'auto',
  },
  fullWidthCenterColumn: {
    maxWidth: 'calc(100% - 220px)',
  },
  rightColumn: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 'calc(3% - 20px)',
  },
  openWarpLoginButton: {
    position: 'absolute',
    right: 0,
    width: 50,
    height: '100%',
    left: 'calc(100% + 20px)',
    cursor: 'default',
  },
  fullWidth: {
    maxWidth: 1680,
  },
});

export default styles;
