import React from 'react';
import { Text, View } from 'react-native';
import { arrayOf, bool, string, func } from 'prop-types';

import { userShape } from 'src/constants/Shapes';
import styles from 'src/components/ChannelPosterList.styles';
import ChannelPoster from 'src/components/ChannelPoster';
import LoadingIndicator from 'src/components/LoadingIndicator';

const ChannelPosterList = ({ title, channels, loading, emptyListText, onChannelPress, testID }) => (
  <View style={styles.container} testID={testID}>
    <Text style={styles.channelTitle}>{title}</Text>
    <View style={styles.listContainer}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          {channels.length === 0 ? (
            <View style={styles.emptyChannelList}>
              <Text style={styles.emptyChannelListText}>{emptyListText}</Text>
            </View>
          ) : (
            <>
              {channels.map(
                ({ channelPoster, desktopProfileBanner, username, channelLoadingColor }) => (
                  <ChannelPoster
                    key={username}
                    url={channelPoster || desktopProfileBanner}
                    username={username}
                    onPress={onChannelPress}
                    loadingColor={channelLoadingColor || undefined}
                  />
                ),
              )}
            </>
          )}
        </>
      )}
    </View>
  </View>
);

ChannelPosterList.propTypes = {
  title: string.isRequired,
  channels: arrayOf(userShape).isRequired,
  emptyListText: string,
  loading: bool,
  onChannelPress: func,
};

ChannelPosterList.defaultProps = {
  loading: false,
  onChannelPress: null,
  emptyListText: null,
};

export default ChannelPosterList;
