import React, { useState } from 'react';
import { func } from 'prop-types';
import { View } from 'react-native';

import { postShape } from 'src/constants/Shapes';
import FlowHeader from 'src/components/FlowModal/FlowHeader';
import FlowModalNavigation from 'src/components/FlowModal/FlowModalNavigation';
import FlowModal from 'src/components/FlowModal/FlowModal';
import styles from 'src/components/PostInformationModal/PostInformationModal.styles';
import Tabs, { TABS } from 'src/components/PostInformationModal/Tabs';
import SeenByInformation from 'src/components/PostInformationModal/SeenByInformation';
import LikesInformation from 'src/components/PostInformationModal/LikesInformation';

const PostInformationModal = ({ post, onClose }) => {
  const [selectedTab, setSelectedTab] = useState(TABS.SeenBy);

  return (
    <FlowModal onClose={onClose}>
      <FlowModalNavigation onClose={onClose} />
      <View style={styles.container}>
        <View style={styles.content}>
          <FlowHeader style={styles.header}>Post Information</FlowHeader>
        </View>
        <Tabs selectedTab={selectedTab} onChange={setSelectedTab} tabs={TABS} />
        <View style={styles.content}>
          <>
            {/* Render all tabs, just toggle their visibility. This makes it so
                that the tab is preloaded if we click on it. */}
            <SeenByInformation post={post} visible={selectedTab === TABS.SeenBy} />
            <LikesInformation post={post} visible={selectedTab === TABS.Likes} />
          </>
        </View>
      </View>
    </FlowModal>
  );
};

PostInformationModal.propTypes = {
  post: postShape.isRequired,
  onClose: func.isRequired,
};

export default PostInformationModal;
