import React from 'react';
import { Text } from 'react-native';
import { bool } from 'prop-types';

import styles from 'src/components/ChannelHeader/ArtistChannelButton.styles';
import { TouchableOpacity } from 'src/components/Touchable';

const ArtistChannelButton = ({ inverted }) => {
  return (
    <TouchableOpacity style={[styles.container, inverted ? styles.inverted : {}]} disabled>
      <Text style={[styles.text, inverted ? styles.invertedText : {}]}>Your Channel</Text>
    </TouchableOpacity>
  );
};

ArtistChannelButton.propTypes = {
  inverted: bool,
};

ArtistChannelButton.defaultProps = {
  inverted: false,
};

export default ArtistChannelButton;
