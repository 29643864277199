// NOTE: The strings in this file are used for analytics purposes and should be
// changed only after consulting with others about what the ramifications are
// on our analytics.
export const SUBSCRIBE_BUTTON_CLICKED = 'subscribe button clicked';
export const SUBSCRIBE_ACTION_CONFIRMED = 'subscribe action confirmed';
export const SUBSCRIBE_ACTION_CANCELLED = 'subscribe action cancelled';
export const SUBSCRIBE_FLOW_EXITED = 'subscribe flow exited';
export const SUBSCRIPTION_SUCCESSFUL = 'subscription successful';
export const PAYMENT_REQUEST_CLICKED = 'payment request clicked';
export const PAYMENT_REQUEST_CANCELLED = 'payment request cancelled';
export const PAYMENT_REQUEST_SUCCEEDED = 'payment request succeeded';
export const LOGGED_OUT_PRODUCT_TOUR = {
  open: 'logged out product tour opened',
  dismiss: 'logged out product tour dismissed',
};
export const NEW_SUBSCRIBER_PRODUCT_TOUR = {
  open: 'new subscriber product tour opened',
  dismiss: 'new subscriber product tour dismissed',
};
export const UNSEEN_FEATURE_MODAL = {
  open: 'unseen features modal opened',
  dismiss: 'unseen features modal dismissed',
};
export const ALL_FEATURE_MODAL = {
  open: 'all features modal opened',
  dismiss: 'all features modal dismissed',
};
export const SHARE_POST_PRESSED = 'share post pressed';
export const SHARE_POST_SUCCEEDED_WEB_API = 'share post succeeded with web API';
export const SHARE_POST_SUCCEEDED_CLIPBOARD = 'share post succeeded with clipboard';
export const SHARE_POST_FAILED = 'share post failed';

export const NEXT_FEATURE_PRESSED = 'next feature pressed';
export const NOTIFICATION_LOGIN_PROMPT = 'notification login shown';
