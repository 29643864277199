// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Finish-module__confirmationIcon__SvPRy {\n    font-size: 35px;\n    color: #218AF0;\n}\n", ""]);
// Exports
exports.locals = {
	"confirmationIcon": "Finish-module__confirmationIcon__SvPRy"
};
module.exports = exports;
