import { StyleSheet } from 'react-native';

import { zIndex } from 'src/styles/zIndex';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    flexDirection: 'column',
    borderRadius: 13,
    width: 250,
    backgroundColor: 'rgba(253, 253, 253, 0.8)',
    backdropFilter: 'blur(56px)',
    zIndex: zIndex.postActionsModal,
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.10)',
    overflow: 'hidden',
  },
  row: {
    paddingVertical: 12,
    overflow: 'hidden',
    height: 42,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 19,
  },
  delete: {
    color: 'rgb(236, 7, 48)',
  },
  divider: {
    backgroundImage:
      'linear-gradient(to top, rgba(60, 60, 67, 0.33) 0%, rgba(60, 60, 67, 0.33) 66%, transparent 67%)',
    height: 1,
    width: '100%',
  },
});

export default styles;
