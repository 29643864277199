import React, { useState, createContext } from 'react';

import { AuthSteps } from 'src/components/AuthFlow/AuthFlow';

const AuthFlowContext = createContext({
  toggleAuthFlow: () => {},
  authFlowOpen: false,
});

export const AuthFlowContextProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    authFlowOpen: false,
    defaultsToSignUp: false,
    authStep: AuthSteps.Welcome,
    action: null,
    showPaymentOptions: false,
    redirectUrl: null,
    showLoggedOutMessage: false,
    onClose: () => {},
    animationInTiming: null,
    totalSteps: null,
  });

  const toggleAuthFlow = ({
    defaultsToSignUp = false,
    authStep = AuthSteps.Welcome,
    showPaymentOptions = false,
    showLoggedOutMessage = false,
    action,
    redirectUrl,
    onClose = () => {},
    animationInTiming = 205,
    totalSteps = 2,
  } = {}) => {
    setAuthState((state) => ({
      defaultsToSignUp,
      authStep,
      action,
      showPaymentOptions,
      authFlowOpen: !state.authFlowOpen,
      redirectUrl,
      showLoggedOutMessage,
      onClose,
      animationInTiming,
      totalSteps,
    }));
  };

  return (
    <AuthFlowContext.Provider value={{ authState, toggleAuthFlow }}>
      {children}
    </AuthFlowContext.Provider>
  );
};

export default AuthFlowContext;
