import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { bool, func } from 'prop-types';

import SvgImage from 'src/components/SvgImage';
import { TouchableOpacity } from 'src/components/Touchable';
import HorizontalHairlineStroke from 'src/components/HorizontalHairlineStroke';
import wrapAnimationDuration from 'src/util/wrapAnimationDuration';
import { postShape } from 'src/constants/Shapes';
import styles from 'src/components/PostModal/PostModalNavigation.styles';

// The amount of time that we don't allow a save for after the caption editor is
// blurred. See the useEffect below for more details on why we do this.
const SAVE_DISABLED_AFTER_CAPTION_BLUR_MS = wrapAnimationDuration(250);

const PostModalNavigation = ({
  post,
  onClose,
  onSubmit,
  loading,
  shareButtonEnabled,
  captionFocused,
  isUploading,
}) => {
  const getModalTitle = () => {
    if (loading && isUploading) return 'Uploading...';

    if (post) {
      if (post.draft) {
        return 'Edit Draft';
      }

      return 'Edit Post';
    }

    return 'Create Post';
  };

  // We explicitly disable saving while in "caption editing mode" and for a
  // short time afterward.
  //
  // We do this because the blur event is actually processed before the touch
  // event on the action button. This causes a problem where the touch to exit
  // the caption editor is aimed at the "Done" button, but the "Done" button
  // has already been replaced with the "Save/Share" button, which causes the
  // post to be accidentally saved.
  //
  // To avoid this, we disable the save button altogether for a short time
  // after the blur event is processed.
  const [saveDisabled, setSaveDisabled] = useState(captionFocused);
  useEffect(() => {
    if (captionFocused) {
      // When the caption is focused, our internal state immediately reflects
      // that.
      setSaveDisabled(true);
      return () => {};
    }

    const id = setTimeout(() => {
      // When the caption is blurred, keep the action button disabled for
      // a short period afterwards.
      setSaveDisabled(false);
    }, SAVE_DISABLED_AFTER_CAPTION_BLUR_MS);

    return () => {
      clearTimeout(id);
    };
  }, [captionFocused, setSaveDisabled]);
  const saveButtonEnabled = shareButtonEnabled && !loading && !saveDisabled;

  return (
    <>
      <View style={styles.container}>
        <TouchableOpacity onPress={onClose} style={styles.exitButton} testID="close-post-modal">
          <SvgImage
            source={require('assets/images/close-icon-large.svg?url')}
            style={styles.closeIcon}
            alt="Close modal icon"
          />
        </TouchableOpacity>
        <Text style={styles.title}>{getModalTitle()}</Text>
        {captionFocused ? (
          <TouchableOpacity onPress={() => {}} style={styles.actionButton}>
            <Text style={styles.actionText}>Done</Text>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={onSubmit}
            disabled={!saveButtonEnabled}
            style={[styles.actionButton, saveButtonEnabled ? {} : styles.disabled]}
            dataSet={{ 'save-post-enabled': saveButtonEnabled }}
            testID="save-post-button"
          >
            <Text style={styles.actionText}>{!post || post?.draft ? 'Share' : 'Save'}</Text>
          </TouchableOpacity>
        )}
      </View>
      {!loading && <HorizontalHairlineStroke color="#00000033" direction="top" />}
    </>
  );
};

PostModalNavigation.propTypes = {
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  captionFocused: bool.isRequired,
  shareButtonEnabled: bool,
  isUploading: bool,
  post: postShape,
};

PostModalNavigation.defaultProps = {
  post: undefined,
  shareButtonEnabled: false,
  isUploading: false,
};

export default PostModalNavigation;
