import React from 'react';
import { Text } from 'react-native';
import { bool, func } from 'prop-types';

import styles from 'src/components/PostModal/PinnedButton.styles';
import webStyles from 'src/components/PostModal/PinnedButton.module.css';
import { TouchableOpacity } from 'src/components/Touchable';
import PinSvg from 'assets/images/pin.svg';
import clsx from 'clsx';

const PinnedButton = ({ pinned, onChange }) => (
  <TouchableOpacity style={styles.container} onPress={() => onChange(!pinned)}>
    <PinSvg className={clsx(webStyles.icon, pinned ? webStyles.selected : webStyles.unselected)} />
    <Text style={[styles.text, pinned ? styles.selected : styles.unselected]}>
      {pinned ? 'Pinned Post' : 'Pin this post'}
    </Text>
  </TouchableOpacity>
);

PinnedButton.propTypes = {
  pinned: bool.isRequired,
  onChange: func.isRequired,
};

export default PinnedButton;
