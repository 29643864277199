import React from 'react';
import { View } from 'react-native';

import styles from 'src/components/LazyLoading/NonprofitScreenLoading.styles';
import ScreenBase from 'src/components/ScreenBase';
import NonprofitLoading from 'src/components/NonProfitLoading';
import ShieldSvg from 'assets/images/shieldIcon.svg';
import nonProfitStyles from 'src/screens/NonprofitScreen.module.css';

const NonprofitScreenLoading = () => (
  <ScreenBase>
    <View style={styles.centeringColumn}>
      <View style={styles.shieldRow}>
        <ShieldSvg className={nonProfitStyles.shieldIcon} />
      </View>
    </View>

    <NonprofitLoading />
  </ScreenBase>
);

export default NonprofitScreenLoading;
