import React from 'react';
import { View, Text } from 'react-native';
import { func } from 'prop-types';

import StylePropType from 'src/util/StylePropType';
import Colors from 'src/styles/colors';
import styles from 'src/components/PostActionsModal.styles';
import { TouchableHighlight } from 'src/components/Touchable';

const PostActionsModal = ({ position, onPostInformation, onEdit, onDelete, onClose }) => (
  <View style={[styles.container, position]}>
    <TouchableHighlight underlayColor={Colors.selectedButton} onPress={onPostInformation}>
      <View style={styles.row}>
        <Text style={styles.text}>Post Information</Text>
      </View>
    </TouchableHighlight>
    <View style={styles.divider} />
    <TouchableHighlight underlayColor={Colors.selectedButton} onPress={onEdit}>
      <View style={styles.row}>
        <Text style={styles.text}>Edit Post</Text>
      </View>
    </TouchableHighlight>
    <View style={styles.divider} />
    <TouchableHighlight underlayColor={Colors.selectedButton} onPress={onDelete}>
      <View style={styles.row}>
        <Text style={[styles.text, styles.delete]}>Delete Post</Text>
      </View>
    </TouchableHighlight>
    <View style={styles.divider} />
    <TouchableHighlight underlayColor={Colors.selectedButton} onPress={onClose}>
      <View style={styles.row}>
        <Text style={styles.text}>Cancel</Text>
      </View>
    </TouchableHighlight>
  </View>
);

PostActionsModal.propTypes = {
  position: StylePropType.isRequired,
  onPostInformation: func.isRequired,
  onEdit: func.isRequired,
  onDelete: func.isRequired,
  onClose: func.isRequired,
};

export default PostActionsModal;
