import React from 'react';
import { View } from 'react-native';

import Colors from 'src/styles/colors';
import ScreenBase, { BORDER_TYPES } from 'src/components/ScreenBase';
import SmartLoading from 'src/components/SmartLoading';
import styles from 'src/components/LazyLoading/AccountScreenLoading.styles';
import { DELAY_MS } from 'src/components/LazyLoading/LoadingScreen';

const AccountScreenLoading = () => (
  <ScreenBase
    centerColumnBackgroundColor={Colors.accountCenterColumnBackground}
    centerColumnBorderColor={Colors.accountCenterColumnBorder}
    borderType={BORDER_TYPES.Gradient}
  >
    <View style={styles.container}>
      <SmartLoading
        loading
        loadingContainerStyles={styles.loadingContainer}
        loadingStyles={styles.loadingRow}
        loadingDelay={DELAY_MS}
      />
    </View>
  </ScreenBase>
);

export default AccountScreenLoading;
