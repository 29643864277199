import { StyleSheet } from 'react-native';
import Colors from 'src/styles/colors';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
    marginBottom: 16,
  },
  text: {
    color: Colors.error,
  },
});

export default styles;
