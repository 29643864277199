import React from 'react';
import { func, string } from 'prop-types';
import { View } from 'react-native';

import FlowHeader from 'src/components/FlowModal/FlowHeader';
import FlowDescriptionLarge from 'src/components/FlowModal/FlowDescriptionLarge';
import Link from 'src/components/Link';
import styles from 'src/components/AuthFlow/ConfirmationSentScreen.styles';

const ConfirmationSentScreen = ({ email, phone, phoneType, onResend }) => {
  const phoneVerb = phoneType === 'sms' ? 'texted' : 'messaged';
  const contactVerb = email ? 'emailed' : phoneVerb;

  return (
    <View style={styles.contentContainer}>
      <FlowHeader style={styles.sentHeader}>Cool! We just {contactVerb} you.</FlowHeader>
      {phone ? (
        <FlowDescriptionLarge excludeFromFS style={styles.smallText}>
          {phoneType === 'sms'
            ? `Check your text messages at${'\n'}${phone}`
            : `Open the link we sent via WhatsApp to${'\n'}${phone}`}
        </FlowDescriptionLarge>
      ) : (
        <FlowDescriptionLarge excludeFromFS style={styles.smallText}>
          {`Check ${email} for your link.`}
        </FlowDescriptionLarge>
      )}

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link onPress={() => onResend(phoneType)} style={styles.resendLink}>
        Resend link
      </Link>
    </View>
  );
};

ConfirmationSentScreen.defaultProps = {
  email: '',
  phone: '',
};

ConfirmationSentScreen.propTypes = {
  onResend: func.isRequired,
  phone: string,
  email: string,
};

export default ConfirmationSentScreen;
