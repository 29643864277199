import React from 'react';

import styles from 'src/components/LazyLoading/LoadingScreen.styles';
import ScreenBase from 'src/components/ScreenBase';
import SmartLoading from 'src/components/SmartLoading';

export const DELAY_MS = 2000;

const LoadingScreen = (props) => (
  <ScreenBase {...props}>
    <SmartLoading
      loading
      loadingContainerStyles={styles.loadingContainer}
      loadingStyles={styles.loadingRow}
      loadingDelay={DELAY_MS}
    />
  </ScreenBase>
);

export default LoadingScreen;
