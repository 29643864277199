import React from 'react';
import { View } from 'react-native';
import { withNavigation } from 'react-navigation';

import styles from 'src/components/Footer.styles';
import webStyles from 'src/components/Footer.module.css';
import WavesSvg from 'assets/images/footer-wave-32x20.svg';

const Footer = () => {
  return (
    <View style={styles.container} testID="footer">
      <WavesSvg className={webStyles.wavesIcon} />
    </View>
  );
};

export default withNavigation(Footer);
