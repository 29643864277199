import React, { useState, useRef, useEffect } from 'react';
import { View } from 'react-native';
import { func } from 'prop-types';
import { get } from 'lodash';
import { withNavigation } from 'react-navigation';
import { useMutation, useApolloClient, useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import { directUploadCancelablePromise, UploadAbortedError } from 'src/util/directUpload';
import { getCurrentRouteName } from 'src/util/urlUtils';
import { isMobile } from 'src/util/functionUtils';
import { timestampFormatForRequest } from 'src/util/dateUtils';
import { getFileType } from 'src/util/fileUtils';
import { TouchableWithoutFeedback } from 'src/components/Touchable';
import ErrorMessage from 'src/components/ErrorMessage';
import FlowModal from 'src/components/FlowModal/FlowModal';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import ConfirmationHeader from 'src/components/ConfirmationModal/ConfirmationHeader';
import YouTubeEmbedForm from 'src/components/Post/YouTubeEmbedForm';
import { FeatureFlags, useFeatureFlag } from 'src/util/featureFlags';
import { GET_CURRENT_USER } from 'src/graphql/User';
import { GET_MOST_RECENTLY_USED_COLLECTION } from 'src/graphql/Collection';
import { CREATE_POST_MUTATION, UPDATE_POST_MUTATION, DELETE_POST_MUTATION } from 'src/graphql/Post';
import { postShape } from 'src/constants/Shapes';
import styles from 'src/components/PostModal/PostModal.styles';
import PostModalNavigation from 'src/components/PostModal/PostModalNavigation';
import PostToButton from 'src/components/PostModal/PostToButton';
import FileUploader from 'src/components/PostModal/FileUploader.web';
import MoreOptions from 'src/components/PostModal/MoreOptions';
import PinnedButton from 'src/components/PostModal/PinnedButton';
import LoadingFooterInformation from 'src/components/PostModal/LoadingFooterInformation';
import ProgressBar from 'src/components/PostModal/ProgressBar';
import CustomThumbnailButton from 'src/components/PostModal/CustomThumbnailButton';
import CaptionEditor from 'src/components/PostModal/CaptionEditor';

const PostModal = ({ navigation, onClose, post }) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [caption, setCaption] = useState(get(post, 'caption', ''));
  const [file, setFile] = useState(post?.url);
  const [poster, setPoster] = useState(post?.poster);
  const [collectionId, setCollectionId] = useState(get(post, 'collection.id'));
  const [pinned, setPinned] = useState(get(post, 'pinned', false));
  const [welcome, setWelcome] = useState(get(post, 'isWelcome', false));
  const [publishedAt, setPublishedAt] = useState(get(post, 'publishedAt', null));
  const [youTubeEmbedUrl, setYouTubeEmbedUrl] = useState(post?.youTubeEmbedUrl);
  const [notificationsEnabled, setNotificationsEnabled] = useState(
    get(post, 'notificationsEnabled', true),
  );
  const [errors, setErrors] = useState([]);
  const [captionFocused, setCaptionFocused] = useState(false);
  const [isCancelUploadModalOpen, setIsCancelUploadModalOpen] = useState(false);

  const client = useApolloClient();
  const abortPromise = useRef();
  const idempotencyKey = useRef(post ? null : uuidv4()).current;
  const activeRoute = getCurrentRouteName(navigation.state);
  const youTubeEmbedFlag = useFeatureFlag(FeatureFlags.YOU_TUBE_EMBED);
  const includeyouTubeEmbedForm = youTubeEmbedFlag.enabled;

  const {
    data: { me },
  } = useQuery(GET_CURRENT_USER);

  const { data: { mostRecentlyUsedCollection } = {} } = useQuery(GET_MOST_RECENTLY_USED_COLLECTION);

  useEffect(() => {
    if (!collectionId && mostRecentlyUsedCollection?.id) {
      setCollectionId(mostRecentlyUsedCollection.id);
    }
  }, [mostRecentlyUsedCollection, collectionId]);

  // Wipe the cache entirely. Given the number of potential queries
  // affected by a new post and the complexity of those queries (e.g.
  // lazy loading), just wiping the entire Apollo cache is the easiest
  // course of action here.
  const [createPost] = useMutation(CREATE_POST_MUTATION, {
    update: async () => {
      await client.resetStore();
    },
    awaitRefetchQueries: true,
  });

  const [updatePost] = useMutation(UPDATE_POST_MUTATION, {
    update: async () => {
      await client.resetStore();
    },
    awaitRefetchQueries: true,
  });

  const [deletePost] = useMutation(DELETE_POST_MUTATION, {
    variables: { id: post?.id },
    update: async () => {
      await client.resetStore();
    },
    awaitRefetchQueries: true,
  });

  const goToPostPage = (publicUid) => {
    navigation.navigate('Post', {
      username: me.username,
      public_uid: publicUid,
    });
  };

  const goToArtistChannel = () => {
    navigation.navigate('User', { username: me.username });
  };

  const reloadPageIfNeeded = () => {
    if (activeRoute === 'Post') {
      // We have to force a reload on the Post Screen due to all instance variables set.
      window.location.reload();
    }
  };

  const collectionAssigned = !!collectionId && collectionId !== '0';

  const handleUpdatePost = async (blobId, posterBlobId, draft) =>
    updatePost({
      variables: {
        id: post.id,
        blobId,
        posterBlobId,
        caption,
        collectionId: collectionAssigned ? collectionId : null,
        pinned,
        paid: !collectionAssigned,
        draft,
        welcome,
        notificationsEnabled,
        publishedAt: publishedAt ? timestampFormatForRequest(publishedAt) : null,
        youTubeEmbedUrl,
      },
    });

  const handleCreatePost = async (blobId, posterBlobId, draft) =>
    createPost({
      variables: {
        blobId,
        posterBlobId,
        caption,
        collectionId: collectionAssigned ? collectionId : null,
        pinned,
        paid: !collectionAssigned,
        draft,
        welcome,
        notificationsEnabled,
        idempotencyKey,
        publishedAt: publishedAt ? timestampFormatForRequest(publishedAt) : null,
        youTubeEmbedUrl,
      },
    });

  const handleSubmit = async ({ draft = false }) => {
    setErrors([]);
    setCaptionFocused(false);

    if (!post && !file) {
      setErrors(['No file was selected to upload']);
      return;
    }
    if (!caption.trim()) {
      setErrors(['Caption is required']);
      return;
    }

    setLoading(true);
    try {
      let blobId;
      let posterBlobId = null;
      let postErrors = [];

      if (poster && typeof poster !== 'string') {
        // Update poster if it has changed
        const result = directUploadCancelablePromise(poster);
        posterBlobId = await result.promise;
      }

      if (post) {
        // Update Post
        if (file && typeof file !== 'string') {
          // Update blob if it has changed
          const result = directUploadCancelablePromise(file, setProgress);
          abortPromise.current = result;
          blobId = await result.promise;
        }

        const {
          data: {
            updatePost: { errors: updatePostErrors },
          },
        } = await handleUpdatePost(blobId, posterBlobId, draft);

        if (updatePostErrors) {
          postErrors = updatePostErrors;
        } else {
          reloadPageIfNeeded();
        }
      } else {
        // Create Post
        const result = directUploadCancelablePromise(file, setProgress);
        abortPromise.current = result;
        blobId = await result.promise;
        const {
          data: {
            createPost: { post: createdPost, errors: createPostErrors },
          },
        } = await handleCreatePost(blobId, posterBlobId, draft);

        if (createPostErrors) {
          postErrors = createPostErrors;
        } else {
          goToPostPage(createdPost.publicUid);
        }
      }

      if (postErrors.length) {
        setErrors(postErrors);
      } else {
        onClose();
      }
    } catch (e) {
      if (!(e instanceof UploadAbortedError)) throw e;
    } finally {
      setLoading(false);
      abortPromise.current = null;
    }
  };

  const handleDelete = async () => {
    await deletePost();
    onClose();

    if (activeRoute === 'Post') {
      goToArtistChannel();
    }
  };

  const cancelUpload = () => {
    abortPromise.current?.abort();
    setIsCancelUploadModalOpen(false);
  };

  const handleClose = () => {
    if (loading) {
      setIsCancelUploadModalOpen(true);
      return;
    }

    onClose();
  };

  const handleCaptionFocus = () => {
    if (isMobile()) {
      setCaptionFocused(true);
    }
  };

  // When the switch is on it means that the notifications should be disabled.
  const handleNotificationsEnabledChange = (disabled) => {
    setNotificationsEnabled(!disabled);
  };

  const clearFiles = () => {
    setFile(null);
    setPoster(null);
  };

  const postReadyToBeSaved = !!(file && caption && collectionId);
  const uploadingNewFile = file && typeof file !== 'string';
  const customThumbnailButtonVisible = post?.type === 'video' || getFileType(file) === 'video';

  return (
    <FlowModal
      onClose={handleClose}
      testID="post-modal"
      popupContainerStyle={styles.popupContainer}
      popupContainerBreakStyle={styles.popupContainerBreak}
    >
      <PostModalNavigation
        post={post}
        onClose={handleClose}
        onSubmit={() => handleSubmit({ draft: false })}
        loading={loading}
        shareButtonEnabled={postReadyToBeSaved}
        captionFocused={captionFocused}
        isUploading={uploadingNewFile}
      />
      {loading && (
        <>
          <View style={styles.loadingOverlay} />
          {uploadingNewFile && (
            <>
              <ProgressBar progress={progress} />
              <LoadingFooterInformation />
            </>
          )}
        </>
      )}
      <View style={styles.wrapper}>
        {errors.map((error) => (
          <ErrorMessage key={error} text={error} style={styles.errorMessage} />
        ))}
        <CaptionEditor
          caption={caption}
          onChange={setCaption}
          onFocus={handleCaptionFocus}
          onBlur={() => setCaptionFocused(false)}
        />
        {captionFocused && (
          <TouchableWithoutFeedback
            onPress={() => {}}
            style={styles.overlay}
            testID="caption-overlay"
          />
        )}
        <FileUploader
          file={poster || file}
          onChange={setFile}
          onClear={clearFiles}
          isNewRecord={!post}
          isDraft={post?.draft}
          handleSaveDraft={() => handleSubmit({ draft: true })}
          saveButtonEnabled={postReadyToBeSaved}
        />
        {customThumbnailButtonVisible && (
          <CustomThumbnailButton file={poster} onChange={setPoster} />
        )}
        {includeyouTubeEmbedForm && (
          <YouTubeEmbedForm setEmbed={setYouTubeEmbedUrl} embed={youTubeEmbedUrl} />
        )}
        <View style={styles.postOptionsContainer}>
          <PostToButton onChange={setCollectionId} collectionId={collectionId} />
          <View style={styles.postOptionsDivider} />
          <PinnedButton onChange={setPinned} pinned={pinned} />
        </View>
        <MoreOptions
          isWelcomePost={welcome}
          onWelcomePostChange={setWelcome}
          notificationsEnabled={notificationsEnabled}
          onNotificationsEnabledChange={handleNotificationsEnabledChange}
          showDelete={!!post}
          showNotificationsOption={!post || !!post?.draft}
          handleDelete={handleDelete}
          onTimestampChange={setPublishedAt}
          timestamp={publishedAt}
          defaultTimestamp={post?.publishedAt}
        />
        {isCancelUploadModalOpen && (
          <ConfirmationModal
            onClose={() => setIsCancelUploadModalOpen(false)}
            onConfirm={cancelUpload}
          >
            <ConfirmationHeader style={styles.cancelUploadText}>
              Do you want to cancel upload?
            </ConfirmationHeader>
          </ConfirmationModal>
        )}
      </View>
    </FlowModal>
  );
};

PostModal.propTypes = {
  onClose: func.isRequired,
  post: postShape,
};

PostModal.defaultProps = {
  post: undefined,
};

export default withNavigation(PostModal);
