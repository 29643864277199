import { ENABLE_ANIMATIONS } from 'env';

// Wraps any animation duration used within the app, allowing us to disable that
// animation (by setting the duration to zero) for pixel tests. Otherwise, we'll
// have to litter our pixel tests with arbitrary wait times in order to
// guarantee consistent screenshots. We use a duration of one here instead of
// zero because some component's APIs interpret zero as a sentinel value.
export default function wrapAnimationDuration(duration) {
  return ENABLE_ANIMATIONS ? duration : 1;
}
