import React from 'react';
import { View } from 'react-native';

import styles, { borderRadius } from 'src/components/NonProfitLoading/NonProfitLoading.styles';
import LoadingView from 'src/components/NonProfitLoading/LoadingView';

const NonProfitLoading = () => {
  return (
    <View style={styles.container}>
      <View style={styles.centeringContainer}>
        <View style={styles.thumbnailRow}>
          <LoadingView style={styles.thumbnail} borderRadius={borderRadius.thumbnail} />
        </View>
      </View>
    </View>
  );
};

export default NonProfitLoading;
