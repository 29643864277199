/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { View } from 'react-native';

import FlowHeader from 'src/components/FlowModal/FlowHeader';
import BaseInput from 'src/components/BaseInput';
import FlowDescriptionLarge from 'src/components/FlowModal/FlowDescriptionLarge';
import FlowDescriptionSmall from 'src/components/FlowModal/FlowDescriptionSmall';
import FlowButtonFlat from 'src/components/FlowModal/FlowButtonFlat';
import ErrorMessage from 'src/components/ErrorMessage';
import styles from 'src/components/EditUsernameFlow/EditUsernameForm.styles';

const EditUsernameForm = ({ username, setUsername, error, onSubmit, isArtist }) => (
  <View style={styles.contentContainer} testID="edit-username-form">
    <FlowHeader>Change username.</FlowHeader>
    <FlowDescriptionLarge>
      {isArtist
        ? 'This is how your subscribers will see you.'
        : "This is how channels you're subscribed to will see you."}
    </FlowDescriptionLarge>
    <BaseInput
      isOnlyInput
      focus
      value={username}
      onChange={setUsername}
      prefix="@"
      excludeFromFS
      testID="username-input"
    />
    {error && <ErrorMessage testID="edit-username-error-message" text={error} />}
    <FlowDescriptionSmall style={styles.centered}>
      {isArtist
        ? "WARNING: As a channel owner, changing your username will change your channel's URL and break all existing links to your channel and to your posts."
        : "Playful or real, it's up to you."}
    </FlowDescriptionSmall>
    <FlowButtonFlat testID="submit-username-btn" onPress={onSubmit}>
      Save
    </FlowButtonFlat>
  </View>
);

export default EditUsernameForm;
