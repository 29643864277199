import React from 'react';
import { StyleProp, Text, ViewStyle } from 'react-native';
import { useQuery } from '@apollo/client';

import { GET_USER } from 'src/graphql/User';
import topBarStyles from 'src/components/TopBar.styles';
import { GetUserQuery } from 'src/types/types';

interface ArtistNameHeaderProps {
  username: string;
}

const ArtistNameHeader = ({ username }: ArtistNameHeaderProps): JSX.Element | null => {
  const { data: { user } = {}, loading } = useQuery<GetUserQuery>(GET_USER, {
    variables: { username },
  });

  if (loading) return null;

  return (
    <Text style={topBarStyles.text as StyleProp<ViewStyle>}>{user?.realName || username}</Text>
  );
};

export default ArtistNameHeader;
