import { lazy } from 'react';
import { differenceInMinutes } from 'date-fns';

import PlatformStorage from 'src/storage';
import { delay } from 'src/util/promiseUtils';

const TOTAL_CHUNK_TRIES = 6;
const FIRST_DELAY_MS = 100;
const PAGE_REFRESHED_DATE_KEY = 'page_refreshed_on';
const REFRESH_EXPIRATION_TIME_MIN = 10;

const handleLoadFailed = async (err) => {
  const pageRefreshedDate = await PlatformStorage.get(PAGE_REFRESHED_DATE_KEY);
  const shouldRefresh =
    !pageRefreshedDate ||
    differenceInMinutes(new Date(), new Date(pageRefreshedDate)) > REFRESH_EXPIRATION_TIME_MIN;

  if (shouldRefresh) {
    PlatformStorage.set(PAGE_REFRESHED_DATE_KEY, new Date().toString());
    window.location.reload();
  } else {
    throw err;
  }
};

// Tries |fn| up to |TOTAL_CHUNK_TRIES| times with exponential backoff.
const retry = async (fn, remainingTries = TOTAL_CHUNK_TRIES, nextDelayMs = FIRST_DELAY_MS) => {
  try {
    return await fn();
  } catch (err) {
    if (remainingTries === 1) {
      handleLoadFailed(err);
      return () => {};
    }

    await delay(nextDelayMs);
    return retry(fn, remainingTries - 1, nextDelayMs * 2);
  }
};

// Drop-in replacement for React's |lazy| that automatically retries up to 5
// times with exponential backoff.

// Usage:
//   const Like = retryingLazy(() => import('./Like'));
export default function retryingLazy(importFn) {
  // eslint-disable-next-line ban/ban
  return lazy(() => retry(importFn));
}
