import React, { useState } from 'react';

const DrawerContext = React.createContext({
  leftDrawerOpen: false,
  rightDrawerOpen: false,
  closeDrawer: () => {},
  openDrawer: () => {},
});

export const DrawerContextProvider = ({ children }) => {
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(false);
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);

  const closeDrawer = (drawer = 'left') => {
    if (drawer === 'left') {
      setLeftDrawerOpen(false);
    } else if (drawer === 'right') {
      setRightDrawerOpen(false);
    }
  };

  const openDrawer = (drawer = 'left') => {
    if (drawer === 'left') {
      setLeftDrawerOpen(true);
    } else if (drawer === 'right') {
      setRightDrawerOpen(true);
    }
  };

  return (
    <DrawerContext.Provider value={{ leftDrawerOpen, rightDrawerOpen, closeDrawer, openDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerContext;
