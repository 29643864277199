import React from 'react';
import { View, Text } from 'react-native';
import { arrayOf, shape, string, number, func, oneOfType } from 'prop-types';

import StylePropType from 'src/util/StylePropType';
import styles from 'src/components/TabsSwitch.styles';
import { TouchableOpacity } from 'src/components/Touchable';

const TabsSwitch = ({ tabs, activeTab, onTabPressed, style }) => (
  <View style={[styles.tabsContainer, style]}>
    {tabs.map(({ label, value }, index) => {
      const active = value === activeTab;

      return (
        <TouchableOpacity
          style={[styles.option, active && styles.active, index === 0 && styles.firstOption]}
          key={value}
          onPress={() => onTabPressed(value)}
          testID={`${label}-tab`}
        >
          <Text style={[styles.text, active && styles.activeText]}>{label}</Text>
        </TouchableOpacity>
      );
    })}
  </View>
);

TabsSwitch.defaultProps = {
  style: {},
};

TabsSwitch.propTypes = {
  tabs: arrayOf(
    shape({
      label: string.isRequired,
      value: oneOfType([string, number]).isRequired,
    }),
  ).isRequired,
  activeTab: oneOfType([string, number]).isRequired,
  onTabPressed: func.isRequired,
  style: StylePropType,
};

export default TabsSwitch;
