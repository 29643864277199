import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  paymentBox: {
    alignItems: 'center',
  },
  paymentText: {
    textAlign: 'center',
    marginBottom: 0,
  },
  paymentOptions: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 12,
    height: 39,
    width: 216,
  },
  paymentIcon: {
    height: 39,
    width: 61,
  },
  creditCardIcon: {
    height: 39,
    width: 62,
  },
  notClickable: {
    cursor: 'auto',
  },
});

export default styles;
