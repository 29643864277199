import React, { createContext, useState } from 'react';

const VideoContext = createContext({
  videoPlaying: null,
  setVideoPlaying: () => {},
});

export const VideoContextProvider = ({ children }) => {
  const [videoPlaying, setVideoPlaying] = useState(null);

  return (
    <VideoContext.Provider value={{ videoPlaying, setVideoPlaying }}>
      {children}
    </VideoContext.Provider>
  );
};

export default VideoContext;
