import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { zIndex } from 'src/styles/zIndex';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 78,
    backgroundColor: '#000000',
    opacity: '80%',
    zIndex: zIndex.modalNavigation,
  },
  text: {
    paddingHorizontal: 20,
    paddingTop: 16,
    paddingBottom: 24,
    fontSize: 15,
    lineHeight: 19,
    color: '#FFFFFF',
  },
});

const LoadingFooterInformation = () => (
  <View style={styles.container}>
    <Text style={styles.text}>
      Your post is still uploading. Keep this browser tab open until uploading completes.
    </Text>
  </View>
);

export default LoadingFooterInformation;
