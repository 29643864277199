import React from 'react';
import { View } from 'react-native';

import styles from 'src/components/ProfileFeed/ProfileFeed.styles';
import ChannelHeader from 'src/components/ChannelHeader/ChannelHeader';
import ResponsiveView from 'src/components/ResponsiveView';
import { User } from 'src/types/types';

interface ProfileFeedProps {
  user?: User | null;
  me?: User | null;
  children?: JSX.Element | null;
  loadingUser: boolean;
}

const ProfileFeed = ({ user, me, children, loadingUser }: ProfileFeedProps): JSX.Element | null => {
  const isArtist = user?.username === me?.username;

  return (
    <View style={styles.contentBackground}>
      <ChannelHeader artist={user} me={me} loading={loadingUser} isArtist={isArtist} />
      <ResponsiveView style={styles.feedBackground} breakStyle={styles.feedBackground}>
        {children}
      </ResponsiveView>
    </View>
  );
};

ProfileFeed.defaultProps = {
  user: null,
  me: null,
  children: null,
};

export default ProfileFeed;
