import React, { useContext } from 'react';

import DrawerContext from 'src/contexts/DrawerContext';
import Drawer from 'src/components/Drawer';
import LeftSidebar from 'src/components/LeftSidebar';

const LeftDrawer = () => {
  const { leftDrawerOpen, closeDrawer } = useContext(DrawerContext);

  return (
    <Drawer
      width={300}
      height="100vh"
      open={leftDrawerOpen}
      onClose={() => closeDrawer('left')}
      handler={false}
      level={null}
      onHandleClick={() => closeDrawer('left')}
    >
      <LeftSidebar />
    </Drawer>
  );
};

export default LeftDrawer;
