import { StyleSheet } from 'react-native';

import Colors from 'src/styles/colors';

export const borderRadius = {
  title: 4,
  thumbnail: 0,
  description: 4,
  link: 4,
};
const thumbnailRatio = `${100 / (14 / 9)}%`;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    alignSelf: 'center',
    top: 0,
    width: '100%',
    height: '100%',
    paddingHorizontal: 30,
    backgroundColor: Colors.transparent,
  },
  centeringContainer: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 800,
    minWidth: 200,
  },
  titleRow: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 44,
    marginTop: 4,
  },
  title: {
    flexGrow: 1,
    height: 24,
    width: 200,
    backgroundColor: Colors.loading,
  },
  thumbnailRow: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 30,
  },
  thumbnail: {
    marginTop: 125,
    height: 0,
    width: '100%',
    paddingTop: thumbnailRatio,
    backgroundColor: Colors.loading,
  },
  descriptionRow: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12,
  },
  description: {
    height: 12,
    width: '100%',
    backgroundColor: Colors.loading,
  },
});

export default styles;
