import React, { useState, useEffect } from 'react';
import { number, string, bool, objectOf, oneOfType, object, func } from 'prop-types';
import { View } from 'react-native';
import clsx from 'clsx';

import { imageCache } from 'src/storage/session-cache';
import StylePropType from 'src/util/StylePropType';
import webStyles from 'src/components/ProgressiveImage.module.css';

const ProgressiveImage = ({
  borderRadius,
  ratio,
  imageSource,
  imageFadeDuration,
  style,
  imageWebStyle,
  resizeMode,
  forceRender,
  instantIfCached,
  placeholderColor,
  endPlaceholderColor,
  onLoad,
  testID,
}) => {
  // If forceRender is true, then image inside the InView component is going to render no matter if it's in view or not.
  const [loaded, setLoaded] = useState(false);
  const [imageFadeDurationImpl, setImageFadeDurationImpl] = useState();

  useEffect(() => {
    const cached = imageCache[imageSource?.uri];
    setImageFadeDurationImpl(cached && instantIfCached ? 1 : imageFadeDuration);
  }, []);

  const onLoadImage = () => {
    imageCache[imageSource.uri] = true;

    onLoad();
    setLoaded(true);
  };

  // The placeholder color animates from `placeholderColor` to `endPlaceholderColor`
  // after the image is loaded.
  return (
    {
      /* This wrapper holds the placeholder background color which animates to
         transparent after the image loads in. This is important for images that
         don't occupy the full container width with resizeMode: 'contain'
         because the placeholder color would otherwise show through on the
         container sides even after the image is loaded. */
    },
    (
      <View style={style}>
        <div
          className={webStyles.placeholder}
          style={{
            transition: loaded ? `background-color ${imageFadeDurationImpl}ms` : '0s',
            backgroundColor: loaded ? endPlaceholderColor : placeholderColor,
            borderRadius,
            paddingTop: `${100 / ratio}%`,
          }}
        >
          {/* The image opacity increases as the placeholder background fades out. */}
          <img
            className={clsx(webStyles.image, loaded && webStyles.loaded)}
            loading={forceRender ? null : 'lazy'}
            style={{
              transition: loaded ? `opacity ${imageFadeDuration}ms` : '0s',
              objectFit: resizeMode,
              aspectRatio: `${ratio}` ?? undefined,
              borderRadius,
              position: 'absolute',
              inset: 0,
              ...imageWebStyle,
            }}
            src={imageSource.uri}
            onLoad={onLoadImage}
            data-testid={testID}
          />
        </div>
      </View>
    )
  );
};

ProgressiveImage.propTypes = {
  borderRadius: number,
  ratio: number,
  imageSource: oneOfType([string, objectOf(string)]),
  imageFadeDuration: number,
  style: StylePropType,
  // eslint-disable-next-line react/forbid-prop-types
  imageWebStyle: object,
  resizeMode: string,
  forceRender: bool,
  instantIfCached: bool,
  // A null value indicates that the background should be transparent.
  placeholderColor: string,
  endPlaceholderColor: string,
  onLoad: func,
  testID: string,
};

ProgressiveImage.defaultProps = {
  borderRadius: 0,
  imageSource: null,
  imageFadeDuration: 250,
  style: {},
  imageWebStyle: {},
  resizeMode: 'cover',
  forceRender: false,
  instantIfCached: false,
  placeholderColor: 'rgba(216, 216, 216, 1)',
  endPlaceholderColor: 'rgba(216, 216, 216, 0)',
  onLoad: () => {},
  testID: null,
};

export default ProgressiveImage;
