import { gql } from '@apollo/client';

import { FeatureFragment, PostFragment, UserFragment } from 'src/graphql/Fragments';

const LOGIN = gql`
  mutation Login($emailOrUsername: String!, $password: String!) {
    login(emailOrUsername: $emailOrUsername, password: $password) {
      token
    }
  }
`;

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      id
      email
      unconfirmedEmail
      stripeDefaultPaymentMethodId
      stripeDefaultPaymentMethodCard
      phone
      username
      commentsEmail
      commentsPhone
      commentsWhatsapp
      thumbnailProfileImage: profileImage(width: 64)
      regularProfileImage: profileImage(width: 400)
      bigProfileImage: profileImage(width: 1200)
      tagline
      realName
      notifications
      showProductTour
      roles
      firstSignIn
      payoutsEnabled
      nonprofit {
        id
        name
      }
      unseenFeatures {
        ...FeatureInfo
      }
      unseenActionFeatures {
        ...FeatureInfo
      }
      gifts {
        id
        giverId
        recipientId
        artistId
        monthsLeft
        received
      }
      ethWalletAddress
    }
  }

  ${FeatureFragment}
`;

const GET_USER_ID = gql`
  query GetUserInfo($username: String!) {
    user(username: $username) {
      id
    }
  }
`;

const GET_USER = gql`
  query getUser($username: String!) {
    user(username: $username) {
      ...UserInfo
      paidPostsAvailable
      collectionsAvailable
      channelLogo
      channelSubscribeButtonIcon
      desktopChannelLogo
      channelRating
      channelRatingAspectRatio
      channelTitle
      channelSubtitle
      channelMainColor
      channelLoadingColor
      channelType
      channelStartDate
      channelInvertedColors
      channelSubscribeButtonText
      channelCast
      channelCastVerb
      channelSecondarySubscribeButtonText
      channelSecondarySubscribeButtonIcon
      channelSecondarySubscribeButtonSubtext
      channelSecondarySubscribeButtonFeatureUid
      channelSecondarySubscribeButtonPreviewUid
      enableDemoPosts
      channelPoster(width: 1200)
      ethCollectionSlug
      ethCollectionName
      ethNetwork
      ethButtonText
      ethButtonIcon
      ethButtonSubtext
      preferredSubscriptionMethod
      channelSubscribeButtonTextSubscribed
      channelSubscribeButtonSubscribedIcon
      channelSubscribeButtonPostUid
      commentsButtonCustomText
      connectWalletFeatureUid
      profileBannerAspectRatio
      desktopProfileBannerAspectRatio
      channelLogoAspectRatio
      desktopChannelLogoAspectRatio
    }
  }
  ${UserFragment}
`;

const GET_USER_CONTACT_INFO = gql`
  query GetUserContactInfo($username: String!) {
    user(username: $username) {
      id
      commentsPhone
      commentsWhatsapp
      commentsEmail
      commentsCustom1
      commentsCustomSubtext1
      commentsCustomLink1
      commentsCustom2
      commentsCustomSubtext2
      commentsCustomLink2
      commentsCustom3
      commentsCustomSubtext3
      commentsCustomLink3
      commentsRequireSubscription
      welcomeText
    }
  }
`;

const GET_MY_POSTS = gql`
  query GetUserPosts($username: String!) {
    posts(username: $username) {
      id
      timeStamp: timestamp
      ...PostInfo
    }
  }

  ${PostFragment}
`;

const UPDATE_TAGLINE = gql`
  mutation UpdateTagline($tagline: String!) {
    updateProfileTagline(tagline: $tagline) {
      user {
        id
        tagline
      }
    }
  }
`;

const UPDATE_PROFILE_IMAGE = gql`
  mutation updateProfileImageMutation($blobId: String!) {
    updateProfileImage(blobId: $blobId) {
      user {
        id
        username
        thumbnailProfileImage: profileImage(width: 64)
        regularProfileImage: profileImage(width: 400)
        bigProfileImage: profileImage(width: 1200)
      }
    }
  }
`;

const UPDATE_PROFILE_BANNER = gql`
  mutation updateProfileBannerMutation($blobId: String!) {
    updateProfileBanner(blobId: $blobId) {
      user {
        id
        username
        profileBanner(width: 3500)
      }
    }
  }
`;

const GET_SUBSCRIBED_ARTISTS = gql`
  query GetSubscribedArtists {
    subscribedTo {
      id
      username
      realName
      thumbnailProfileImage: profileImage(width: 64)
      channelPoster(width: 800)
      desktopProfileBanner(width: 3500)
      channelLoadingColor
    }
  }
`;

const GET_SUBSCRIBERS = gql`
  query GetSubscribers {
    subscribers {
      createdAt
      subscriber {
        id
        username
      }
    }
  }
`;

const GET_AVAILABLE_ARTISTS = gql`
  query GetAvailableArtists {
    availableArtists {
      id
      username
      thumbnailProfileImage: profileImage(width: 64)
      channelPoster(width: 800)
      desktopProfileBanner(width: 3500)
      channelLoadingColor
    }
  }
`;

const GET_ALL_USERS = gql`
  query GetAllUsers {
    users {
      id
      username
      thumbnailProfileImage: profileImage(width: 64)
      roles
    }
  }
`;

const GET_SUBSCRIPTION = gql`
  query GetSubscription($username: String!) {
    subscription(username: $username) {
      id
      nextBillAt
      grantor
      artist {
        id
        realName
        ethCollectionName
      }
    }
  }
`;

const SUBSCRIBE_ASYNC = gql`
  mutation SubscribeAsync($artistId: ID!) {
    subscribeAsync(artistId: $artistId) {
      paymentIntentClientSecret
      errors
    }
  }
`;

const IS_USERNAME_UNIQUE = gql`
  query IsUsernameUnique($username: String!) {
    isUsernameUnique(username: $username)
  }
`;

const CREATE_PENDING_EMAIL_LOGIN = gql`
  mutation CreatePendingEmailLogin($email: String!, $redirectUrl: String = null) {
    createPendingEmailLogin(email: $email, redirectUrl: $redirectUrl) {
      errors
    }
  }
`;

const CREATE_PENDING_PHONE_LOGIN = gql`
  mutation CreatePendingPhoneLogin(
    $phone: String!
    $phoneType: PhoneEnum!
    $redirectUrl: String = null
  ) {
    createPendingPhoneLogin(phone: $phone, phoneType: $phoneType, redirectUrl: $redirectUrl) {
      errors
    }
  }
`;

const CREATE_PENDING_SIGN_UP = gql`
  mutation CreatePendingSignup(
    $phone: String = null
    $email: String = null
    $username: String = null
    $phoneType: PhoneEnum
    $redirectUrl: String = null
  ) {
    createPendingSignup(
      phone: $phone
      email: $email
      username: $username
      phoneType: $phoneType
      redirectUrl: $redirectUrl
    ) {
      errors
    }
  }
`;

const CREATE_PENDING_WARP_LOGIN = gql`
  mutation CreatePendingWarpLogin($username: String!, $redirectUrl: String!) {
    createPendingWarpLogin(username: $username, redirectUrl: $redirectUrl) {
      magicLinkUrl
      errors
    }
  }
`;

const UPDATE_USERNAME = gql`
  mutation updateUsername($username: String!) {
    updateUsername(username: $username) {
      username
    }
  }
`;

const CONSUME_MAGIC_LINK = gql`
  mutation ConsumeMagicLink($token: String!) {
    consumeMagicLink(token: $token) {
      userToken {
        token
      }
      redirectUrl
    }
  }
`;

export const GET_BILLING_PORTAL = gql`
  query BillingPortal {
    billingPortal
  }
`;

export const GET_PAYOUT_PORTAL = gql`
  query PayoutPortal {
    payoutPortal
  }
`;

const UPDATE_USER_NOTIFICATIONS = gql`
  mutation updateUserNotifications($notifications: [Notification!]!) {
    updateUserNotifications(notifications: $notifications) {
      user {
        id
        notifications
      }
    }
  }
`;

const CHANGE_EMAIL = gql`
  mutation changeEmail($newEmail: String!, $sendConfirmation: Boolean) {
    changeEmail(newEmail: $newEmail, sendConfirmation: $sendConfirmation) {
      newEmail
      sendConfirmation
      errors
    }
  }
`;

const CHANGE_PHONE = gql`
  mutation changePhone($newPhone: String!) {
    changePhone(newPhone: $newPhone) {
      newPhone
      errors
    }
  }
`;

const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($artistId: String!) {
    cancelSubscription(artistId: $artistId) {
      success
      errors
    }
  }
`;

const UPDATE_STRIPE_DEFAULT_PAYMENT = gql`
  mutation updateStripeDefaultPayment(
    $stripeDefaultPaymentMethodId: String!
    $stripeDefaultPaymentMethodCard: String!
  ) {
    updateStripeDefaultPayment(
      stripeDefaultPaymentMethodId: $stripeDefaultPaymentMethodId
      stripeDefaultPaymentMethodCard: $stripeDefaultPaymentMethodCard
    ) {
      stripeDefaultPaymentMethodId
      stripeDefaultPaymentMethodCard
    }
  }
`;

const GET_USERNAME_ERRORS = gql`
  query usernameErrors($username: String!) {
    usernameErrors(username: $username)
  }
`;

const UPDATE_WELCOME_TEXT = gql`
  mutation updateWelcomeText($welcomeText: String!) {
    updateWelcomeText(welcomeText: $welcomeText) {
      user {
        ...UserInfo
      }
      errors
    }
  }
  ${UserFragment}
`;

const UPDATE_COMMENT_METHOD_EMAIL = gql`
  mutation updateCommentMethodEmail($commentsEmail: String) {
    updateCommentMethodEmail(commentsEmail: $commentsEmail) {
      user {
        ...UserInfo
      }
      errors
    }
  }
  ${UserFragment}
`;

const UPDATE_COMMENT_METHOD_PHONE = gql`
  mutation updateCommentMethodPhone($commentsPhone: String) {
    updateCommentMethodPhone(commentsPhone: $commentsPhone) {
      user {
        ...UserInfo
      }
      errors
    }
  }
  ${UserFragment}
`;

const UPDATE_COMMENT_METHOD_WHATSAPP = gql`
  mutation updateCommentMethodWhatsapp($commentsWhatsapp: String) {
    updateCommentMethodWhatsapp(commentsWhatsapp: $commentsWhatsapp) {
      user {
        ...UserInfo
      }
      errors
    }
  }
  ${UserFragment}
`;

const REPLACE_LOGGED_OUT_POST_VIEWS = gql`
  mutation replaceLoggedOutPostViews($loggedOutUserUuid: String = null) {
    replaceLoggedOutPostViews(loggedOutUserUuid: $loggedOutUserUuid) {
      errors
    }
  }
`;

const CREATE_ETH_WALLET = gql`
  mutation createEthWallet($address: String!, $signature: String!) {
    createEthWallet(address: $address, signature: $signature) {
      address
      signature
      errors
    }
  }
`;

const CREATE_WALLET_LOGIN = gql`
  mutation createWalletLogin($address: String!, $signature: String!) {
    createWalletLogin(address: $address, signature: $signature) {
      address
      signature
      userToken {
        token
      }
    }
  }
`;

const SUBSCRIBE_WITH_NFT = gql`
  mutation subscribeWithNft($username: String!) {
    subscribeWithNft(username: $username) {
      nftFound
      errors
    }
  }
`;

const ACCOUNT_WITH_ADDRESS_EXISTS = gql`
  query AccountWithAddressExists($address: String!) {
    accountWithAddressExists(address: $address)
  }
`;

export {
  LOGIN,
  GET_CURRENT_USER,
  GET_MY_POSTS,
  UPDATE_TAGLINE,
  UPDATE_PROFILE_IMAGE,
  UPDATE_PROFILE_BANNER,
  GET_SUBSCRIBED_ARTISTS,
  GET_AVAILABLE_ARTISTS,
  GET_ALL_USERS,
  GET_SUBSCRIPTION,
  IS_USERNAME_UNIQUE,
  SUBSCRIBE_ASYNC,
  GET_USER_ID,
  CREATE_PENDING_EMAIL_LOGIN,
  CREATE_PENDING_PHONE_LOGIN,
  CREATE_PENDING_SIGN_UP,
  CREATE_PENDING_WARP_LOGIN,
  UPDATE_USERNAME,
  CONSUME_MAGIC_LINK,
  UPDATE_USER_NOTIFICATIONS,
  CHANGE_EMAIL,
  CHANGE_PHONE,
  CANCEL_SUBSCRIPTION,
  UPDATE_STRIPE_DEFAULT_PAYMENT,
  GET_USER_CONTACT_INFO,
  GET_USER,
  GET_USERNAME_ERRORS,
  GET_SUBSCRIBERS,
  UPDATE_WELCOME_TEXT,
  UPDATE_COMMENT_METHOD_EMAIL,
  UPDATE_COMMENT_METHOD_PHONE,
  UPDATE_COMMENT_METHOD_WHATSAPP,
  REPLACE_LOGGED_OUT_POST_VIEWS,
  CREATE_ETH_WALLET,
  CREATE_WALLET_LOGIN,
  SUBSCRIBE_WITH_NFT,
  ACCOUNT_WITH_ADDRESS_EXISTS,
};
