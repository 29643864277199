import React, { useState } from 'react';
import { View, Text } from 'react-native';

import { hexToRgba } from 'src/util/colorUtils';
import HorizontalHairlineStroke from 'src/components/HorizontalHairlineStroke';
import { TouchableOpacity } from 'src/components/Touchable';
import ProgressiveImage from 'src/components/ProgressiveImage';
import styles from 'src/components/ChannelHeader/DesktopChannelHeader.styles';
import webStyles from 'src/components/ChannelHeader/DesktopChannelHeader.module.css';
import SubscribeButton from 'src/components/ChannelHeader/SubscribeButton';
import SecondarySubscribeButton from 'src/components/ChannelHeader/SecondarySubscribeButton';
import ArtistChannelButton from 'src/components/ChannelHeader/ArtistChannelButton';
import ProfileTagline from 'src/components/ChannelHeader/ProfileTagline.web';
import ChannelLogo from 'src/components/ChannelHeader/ChannelLogo';
import TvRating from 'src/components/ChannelHeader/TvRating';
import ShieldPopup from 'src/components/ChannelHeader/ShieldPopup';
import ConnectWalletButton from 'src/components/ChannelHeader/ConnectWalletButton';
import ShieldFooterSvg from 'assets/images/shield-footer.svg';

const DesktopChannelHeader = ({ artist, loading, isArtist }) => {
  const {
    desktopProfileBanner: profileBanner,
    desktopProfileBannerAspectRatio: profileBannerAspectRatio,
    channelSubscribeButtonIcon,
    tagline,
    nonprofit,
    isSubscribed,
    desktopChannelLogo: channelLogo,
    desktopChannelLogoAspectRatio: channelLogoAspectRatio,
    channelRating,
    channelRatingAspectRatio,
    channelType,
    channelSubscribeButtonText,
    channelTitle,
    channelSubtitle,
    channelStartDate,
    channelInvertedColors: invertedColors,
    channelMainColor = '#000',
    channelLoadingColor = '#FFF',
    channelCast,
    channelCastVerb,
    channelSecondarySubscribeButtonText,
    channelSecondarySubscribeButtonIcon,
    channelSecondarySubscribeButtonSubtext,
    channelSecondarySubscribeButtonFeatureUid,
    channelSecondarySubscribeButtonPreviewUid,
    ethCollectionSlug,
    ethButtonText,
    ethButtonIcon,
    ethButtonSubtext,
    username,
    channelSubscribeButtonTextSubscribed,
    channelSubscribeButtonSubscribedIcon,
    channelSubscribeButtonPostUid,
  } = artist || {};
  const [nonprofitDetailsOpen, setNonprofitDetailsOpen] = useState(false);

  const bottomGradientColor = hexToRgba(channelMainColor, 0);

  return (
    <View style={styles.container} nativeID="desktop-profile" testID="desktop-profile">
      <ProgressiveImage
        imageSource={{ uri: profileBanner }}
        resizeMode="cover"
        style={styles.backgroundImage}
        placeholderColor={channelLoadingColor}
        endPlaceholderColor={channelLoadingColor}
        ratio={profileBannerAspectRatio}
        imageFadeDuration={425}
        imageWebStyle={{ objectPosition: 'top' }}
        forceRender
      />
      {!loading && (
        <View style={styles.bottomContainer}>
          <View
            style={[
              styles.bottomOverlay,
              {
                backgroundImage: `linear-gradient(to top, ${channelMainColor} 0%, ${bottomGradientColor} 100%)`,
              },
            ]}
          />
          <View style={[styles.bottomSolidColor, { background: channelMainColor }]} />
          <View style={styles.bottomInnerContainer}>
            <View style={styles.row}>
              <View style={styles.leftColumn}>
                {channelLogo ? (
                  <ChannelLogo logo={channelLogo} ratio={channelLogoAspectRatio} maxHeight={120} />
                ) : (
                  <Text style={[styles.title, invertedColors ? styles.invertedText : {}]}>
                    {channelTitle}
                  </Text>
                )}
                {(channelType || channelStartDate) && (
                  <Text
                    style={[
                      styles.channelInfo,
                      invertedColors ? styles.channelInfoInverted : {},
                      channelLogo ? styles.channelInfoWithLogo : {},
                    ]}
                  >
                    {channelType} {channelType && channelStartDate && '·'} {channelStartDate}
                  </Text>
                )}
              </View>
              <View style={[styles.rightColumn, styles.buttonContainer]}>
                {isArtist && !channelSubscribeButtonTextSubscribed ? (
                  <View style={[styles.buttonContainer, styles.editButtonContainer]}>
                    <ArtistChannelButton inverted={invertedColors} />
                  </View>
                ) : (
                  <>
                    {!!channelSecondarySubscribeButtonText && !isSubscribed && (
                      <SecondarySubscribeButton
                        text={channelSecondarySubscribeButtonText}
                        subtitle={channelSecondarySubscribeButtonSubtext}
                        inverted={invertedColors}
                        featureUid={channelSecondarySubscribeButtonFeatureUid}
                        previewUid={channelSecondarySubscribeButtonPreviewUid}
                        additionalButtonText={channelSubscribeButtonText || ethCollectionSlug}
                        icon={channelSecondarySubscribeButtonIcon}
                      />
                    )}
                    {ethCollectionSlug && !isSubscribed && (
                      <ConnectWalletButton
                        ethCollectionSlug={ethCollectionSlug}
                        text={ethButtonText}
                        subtitle={ethButtonSubtext}
                        inverted={invertedColors}
                        username={username}
                        icon={ethButtonIcon}
                        additionalButtonText={channelSubscribeButtonText}
                      />
                    )}
                    {(channelSubscribeButtonText || isSubscribed) && (
                      <SubscribeButton
                        inverted={invertedColors}
                        isSubscribed={isSubscribed}
                        text={channelSubscribeButtonText}
                        subtitle={channelSubtitle}
                        subscribedText={channelSubscribeButtonTextSubscribed}
                        postToRedirect={channelSubscribeButtonPostUid}
                        icon={channelSubscribeButtonIcon}
                        subscribedIcon={channelSubscribeButtonSubscribedIcon}
                      />
                    )}
                  </>
                )}
              </View>
            </View>
            <HorizontalHairlineStroke
              style={styles.hairline}
              color={invertedColors ? '#00000026' : '#FFFFFF2B'}
            />
            <View style={styles.row}>
              <View style={styles.leftColumn}>
                {tagline && <ProfileTagline tagline={tagline} inverted={invertedColors} />}
              </View>
              <View style={styles.rightColumn}>
                <Text style={[styles.castText, invertedColors ? styles.castTextInverted : {}]}>
                  <Text
                    style={[styles.castVerbText, invertedColors ? styles.castVerbTextInverted : {}]}
                  >
                    {channelCastVerb}{' '}
                  </Text>
                  {channelCast}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.charityRow}>
                {channelRating && (
                  <TvRating uri={channelRating} aspectRatio={channelRatingAspectRatio} />
                )}
                {nonprofit && (
                  <>
                    <ShieldFooterSvg className={webStyles.shieldIcon} />
                    <Text
                      style={[styles.charityText, invertedColors ? styles.charityInvertedText : {}]}
                    >
                      This channel supports{' '}
                      <TouchableOpacity
                        style={[
                          styles.charityLink,
                          invertedColors ? styles.charityInvertedText : {},
                        ]}
                        onPress={() => setNonprofitDetailsOpen(true)}
                        hitSlop={{ top: 10, left: 10, bottom: 10, right: 10 }}
                      >
                        <Text>{nonprofit.name}</Text>
                      </TouchableOpacity>
                      .
                    </Text>
                  </>
                )}
              </View>
            </View>
          </View>
        </View>
      )}
      {nonprofitDetailsOpen && (
        <ShieldPopup onClose={() => setNonprofitDetailsOpen(false)} nonprofit={nonprofit} />
      )}
    </View>
  );
};

export default DesktopChannelHeader;
