import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  buttonStyle: {
    backgroundColor: 'transparent',
    borderRadius: 6,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#fff',
    margin: 'auto',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 11,
    paddingRight: 11,
    marginRight: 13,
  },
  buttonText: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: 18,
    color: '#fff',
    textAlign: 'center',
  },
});

export default styles;
