import { gql } from '@apollo/client';

import { PostFragment, PostNavigationFragment } from 'src/graphql/Fragments';

const GET_PINNED_POSTS = gql`
  query getPinnedPosts($username: String!) {
    pinnedPosts(username: $username) {
      id
      username
      ...PostInfo
    }
  }

  ${PostFragment}
`;

const GET_POST = gql`
  query getPost($publicUid: String!) {
    post(publicUid: $publicUid) {
      id
      username
      visibleForUser
      isWelcome
      ...PostInfo
    }
  }

  ${PostFragment}
`;

const GET_POST_LIKES = gql`
  query getPostLikes($publicUid: String!) {
    post(publicUid: $publicUid) {
      id
      likes {
        id
        user {
          id
          username
          thumbnailProfileImage: profileImage(width: 64)
        }
        createdAt
      }
    }
  }
`;

const GET_POST_VIEWS = gql`
  query getPostViews($publicUid: String!) {
    post(publicUid: $publicUid) {
      id
      views {
        id
        user {
          id
          username
          thumbnailProfileImage: profileImage(width: 64)
        }
        createdAt
      }
    }
  }
`;

const LIKE_POST = gql`
  mutation LikePost($id: ID!) {
    likePost(id: $id) {
      id
      post {
        id
        likeCount
        ...PostInfo
      }
    }
  }

  ${PostFragment}
`;

const UNLIKE_POST = gql`
  mutation UnlikePost($id: ID!) {
    unlikePost(id: $id) {
      id
      post {
        id
        likeCount
        ...PostInfo
      }
    }
  }

  ${PostFragment}
`;

const CREATE_POST_MUTATION = gql`
  mutation createPostMutation(
    $blobId: String!
    $posterBlobId: String = null
    $caption: String!
    $collectionId: String!
    $pinned: Boolean
    $paid: Boolean
    $draft: Boolean
    $welcome: Boolean
    $notificationsEnabled: Boolean
    $idempotencyKey: String!
    $publishedAt: Timestamp
    $youTubeEmbedUrl: String
  ) {
    createPost(
      blobId: $blobId
      posterBlobId: $posterBlobId
      caption: $caption
      collectionId: $collectionId
      pinned: $pinned
      paid: $paid
      draft: $draft
      welcome: $welcome
      notificationsEnabled: $notificationsEnabled
      idempotencyKey: $idempotencyKey
      publishedAt: $publishedAt
      youTubeEmbedUrl: $youTubeEmbedUrl
    ) {
      post {
        id
        timeStamp: timestamp
        username
        publishedAt
        ...PostInfo
      }
      errors
    }
  }

  ${PostFragment}
`;

const UPDATE_POST_MUTATION = gql`
  mutation updatePost(
    $id: ID!
    $blobId: String = null
    $posterBlobId: String = null
    $caption: String
    $collectionId: String!
    $pinned: Boolean!
    $paid: Boolean!
    $draft: Boolean!
    $welcome: Boolean!
    $notificationsEnabled: Boolean
    $publishedAt: Timestamp
    $youTubeEmbedUrl: String
  ) {
    updatePost(
      id: $id
      blobId: $blobId
      posterBlobId: $posterBlobId
      caption: $caption
      collectionId: $collectionId
      pinned: $pinned
      paid: $paid
      draft: $draft
      welcome: $welcome
      notificationsEnabled: $notificationsEnabled
      publishedAt: $publishedAt
      youTubeEmbedUrl: $youTubeEmbedUrl
    ) {
      post {
        ...PostInfo
      }
      errors
    }
  }
  ${PostFragment}
`;

const DELETE_POST_MUTATION = gql`
  mutation deletePost($id: ID!) {
    deletePost(id: $id) {
      post {
        id
      }
      errors
    }
  }
`;

const MARK_POST_AS_SEEN = gql`
  mutation markPostAsSeen($postId: String!, $loggedOutUserUuid: String = null) {
    markPostAsSeen(postId: $postId, loggedOutUserUuid: $loggedOutUserUuid) {
      errors
    }
  }
`;

const GET_POST_FOR_NAVIGATION = gql`
  query getPostForNavigation($publicUid: String!) {
    post(publicUid: $publicUid) {
      ...PostNavigationInfo
    }
  }

  ${PostNavigationFragment}
`;

export {
  CREATE_POST_MUTATION,
  DELETE_POST_MUTATION,
  GET_POST,
  GET_POST_LIKES,
  LIKE_POST,
  UNLIKE_POST,
  UPDATE_POST_MUTATION,
  GET_PINNED_POSTS,
  MARK_POST_AS_SEEN,
  GET_POST_VIEWS,
  GET_POST_FOR_NAVIGATION,
};
