import React, { useEffect } from 'react';
import { bool, string } from 'prop-types';
import { Text, View } from 'react-native';
import { useTrack } from 'use-analytics';

import styles from 'src/components/ErrorMessage.styles';
import StylePropType from 'src/util/StylePropType';

const ErrorMessage = ({ text, style = {}, textStyle = {}, showPrefix = true, ...props }) => {
  if (!text) return null;

  const track = useTrack();
  useEffect(() => {
    track('error', {
      label: text,
    });
  }, [text]);

  if (text === "You're not authorized to do this") {
    // eslint-disable-next-line no-param-reassign
    text =
      "We're sorry, you were not logged in correctly. This can happen sometimes when there is an unstable internet connection during login. Please click your login link again. We apologize for the inconvenience!";
  }

  return (
    <View {...props} style={[styles.container, style]}>
      <Text style={[styles.text, textStyle]}>{`${showPrefix ? '* ' : ''}${text}`}</Text>
    </View>
  );
};

ErrorMessage.propTypes = {
  text: string.isRequired,
  showPrefix: bool,
  style: StylePropType,
  textStyle: StylePropType,
};

export default ErrorMessage;
