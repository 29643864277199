import { StyleSheet } from 'react-native';
import { zIndex } from 'src/styles/zIndex';

const styles = StyleSheet.create({
  loadingOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: zIndex.loadingOverlay,
    backgroundColor: 'white',
    borderRadius: 18,
  },
});

export default styles;
