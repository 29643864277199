import React from 'react';
import { View, Text } from 'react-native';
import { func, number, arrayOf } from 'prop-types';

import { featureShape } from 'src/constants/Shapes';

import { TouchableOpacity } from 'src/components/Touchable';
import DotsPagination from 'src/components/DotsPagination';
import styles from 'src/components/Features/FeatureModal/FeatureModalNavigation.styles';
import webStyles from 'src/components/Features/FeatureModal/FeatureModalNavigation.module.css';
import LeftArrowSvg from 'assets/images/left-arrow.svg';
import CrossSvg from 'assets/images/cross.svg';
import clsx from 'clsx';

const FeatureModalNavigation = ({ onNext, onBack, onClose, features, currentIndex }) => {
  const renderRightButton = () => {
    if (features.length === 1) {
      // If there's only feature in the modal, the word "Done" doesn't make much sense because
      // the user hasn't actually taken any action. Show an "X" instead.
      return (
        <TouchableOpacity
          testID="close-feature-modal-button"
          onPress={onClose}
          style={[styles.button, styles.rightButton]}
        >
          <CrossSvg className={webStyles.closeIcon} />
        </TouchableOpacity>
      );
    }

    if (currentIndex < features.length - 1) {
      // If there are multiple features in the modal, show the word "Next" until the last slide,
      // when we show the word "Done".
      return (
        <TouchableOpacity
          testID="next-feature-button"
          onPress={onNext}
          style={[styles.button, styles.rightButton]}
        >
          <LeftArrowSvg className={clsx(webStyles.icon, webStyles.nextIcon)} />
        </TouchableOpacity>
      );
    }

    return (
      <TouchableOpacity
        testID="feature-modal-button-done"
        onPress={onClose}
        style={[styles.button, styles.rightButton]}
      >
        <Text style={styles.closeText}>Done</Text>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.header}>
      {currentIndex > 0 && (
        <TouchableOpacity onPress={onBack} style={styles.button}>
          <LeftArrowSvg className={webStyles.icon} />
        </TouchableOpacity>
      )}
      {features.length > 1 && (
        <View style={styles.dotsContainer} testID="feature-navigation-dots">
          <DotsPagination totalSteps={features.length} currentStep={currentIndex + 1} />
        </View>
      )}
      {renderRightButton()}
    </View>
  );
};

FeatureModalNavigation.propTypes = {
  onBack: func.isRequired,
  onNext: func.isRequired,
  onClose: func.isRequired,
  features: arrayOf(featureShape).isRequired,
  currentIndex: number.isRequired,
};

export default FeatureModalNavigation;
