import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    marginTop: 112,
  },
  loadingContainer: {
    height: '100%',
    width: '100%',
    marginHorizontal: 0,
  },
  loadingRow: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export default styles;
