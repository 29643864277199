import React, { useState, useRef } from 'react';
import { View } from 'react-native';
import { string, bool, func } from 'prop-types';

import { formatPhoneNumber, formatPhoneWithCountryCode } from 'src/util/phoneFormatHelpers';
import { US } from 'src/constants/CountryCodes';
import styles from 'src/components/PhoneInput.styles';
import CountryPicker from 'src/components/CountryPicker';
import BaseInput from 'src/components/BaseInput';

const PhoneInput = ({
  focus,
  onChange,
  placeholder,
  placeholderTextColor,
  value,
  onChangeCountry,
  ...props
}) => {
  const baseInputRef = useRef();
  const [country, setCountry] = useState(US);
  const [backspacePressed, setBackspacePressed] = useState(false);
  const [showInput, setShowInput] = useState(true);

  const handleChange = (phone) => {
    onChange(formatPhoneWithCountryCode(phone, country));
  };

  const onKeyPress = ({ nativeEvent }) => {
    setBackspacePressed(nativeEvent.key === 'Backspace');
  };

  return (
    <View style={[styles.container, !showInput && styles.expandedContainer]}>
      <CountryPicker
        onOpen={() => setShowInput(false)}
        onClose={() => setShowInput(true)}
        {...{
          countryCode: country.cca2,
          onSelect: (...args) => {
            setCountry(...args);
            baseInputRef.current.focus();
          },
          withEmoji: false,
          withCallingCodeButton: true,
          withFlag: true,
          containerButtonStyle: styles.countryPicker,
        }}
      />
      <BaseInput
        data-phone
        testID="phone"
        /*
         * Note the React Native docs say that `autoCompleteType` is the right
         * prop to control autocomplete, but `autoComplete` is the one that
         * actually has an effect.
         */
        autoComplete="tel"
        keyboardType="phone-pad"
        excludeFromFS
        ref={baseInputRef}
        focus={focus}
        placeholder={placeholder}
        style={[styles.input, showInput ? {} : styles.hide]}
        autoCorrect={false}
        onChange={handleChange}
        onKeyPress={onKeyPress}
        placeholderTextColor={placeholderTextColor}
        value={formatPhoneNumber(value, country, backspacePressed)}
        {...props}
      />
    </View>
  );
};

PhoneInput.propTypes = {
  placeholder: string,
  placeholderTextColor: string,
  focus: bool,
  onChange: func.isRequired,
  value: string,
};

PhoneInput.defaultProps = {
  placeholder: 'Phone number',
  placeholderTextColor: 'rgba(60, 60, 67, 0.6)',
  focus: false,
  value: '',
};

export default PhoneInput;
