/* eslint-disable no-return-assign */
/* eslint-disable global-require */

import React, { Suspense } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { enableScreens } from 'react-native-screens';
import Analytics from 'analytics';
import fullStoryPlugin from '@analytics/fullstory';
import googleAnalytics from '@analytics/google-analytics';
import { AnalyticsProvider } from 'use-analytics';

import PlatformStorage from 'src/storage';
import { API_URL } from 'env';
import AppNavigator from 'src/navigation';
import 'src/lib/sentry';
import DefaultMetaTags from 'src/components/metatags/DefaultMetaTags';
import MinDurationLink from 'src/util/MinDurationLink';
import retryingLazy from 'src/util/retryingLazy';
import { SafeAreaProvider } from 'react-native-safe-area-context';

enableScreens();

const LottieAnimationFreezer = retryingLazy(() =>
  import('./src/components/LottieAnimationFreezer'),
);

const analytics = Analytics({
  app: 'channels-dot-org',
  plugins: [
    fullStoryPlugin({
      org: 'QP304',
    }),
    googleAnalytics({
      trackingId: 'UA-6976225-14',
      anonymizeIp: true,
    }),
  ],
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f5f5f5',
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingComplete: false,
    };

    const httpLink = new HttpLink({ uri: API_URL });
    const authLink = setContext(async ({ operationName }, { headers }) => {
      // This callback called before every request to graphql server
      try {
        const token = await PlatformStorage.get('token');

        if (!token) {
          // eslint-disable-next-line no-console
          console.log(`[D] GraphQL operation ${operationName}: Token is empty`);
          return {};
        }
        // eslint-disable-next-line no-console
        console.log(`[D] GraphQL operation ${operationName}: Token value is:`, token);

        return { headers: { ...headers, authorization: `Bearer ${token}` } };
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`[D] GraphQL operation ${operationName}: Can not retrieve auth token.`);
      }

      return {};
    });

    const cache = new InMemoryCache({
      typePolicies: {
        Collection: {
          fields: {
            posts: offsetLimitPagination(),
          },
        },
      },
    });

    this.client = new ApolloClient({
      // By default, this client will send queries to the
      // `/graphql` endpoint on the same host
      // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
      // to a different host
      cache,
      link: ApolloLink.from([new MinDurationLink(), authLink, httpLink]),
    });

    (async () => {
      await this.loadResourcesAsync();
      this.setState({ isLoadingComplete: true });
    })();
  }

  loadResourcesAsync = async () => {
    return Promise.all([
      Promise.all([
        [
          require('assets/images/loading.svg?url'),
          require('assets/images/loading-inverted.svg?url'),
          require('assets/images/avatar-transparent.svg?url'),
          require('assets/images/payment-confirmation.svg?url'),
          require('assets/images/ssl-lock.svg?url'),
          require('assets/images/apple-pay.svg?url'),
          require('assets/images/google-pay.svg?url'),
          require('assets/images/credit-card.svg?url'),
          require('assets/images/text-message-22x22.svg?url'),
          require('assets/images/whatsapp-22x22.png?url'),
          require('assets/images/wallet_providers/metamask.svg?url'),
          require('assets/images/wallet_providers/walletconnect.svg?url'),
          require('assets/images/wallet_providers/coinbase_wallet.svg?url'),
        ].map((source) => Image.prefetch(source)),
      ]),
    ]);
  };

  render() {
    const { isLoadingComplete } = this.state;

    if (!isLoadingComplete) {
      return null;
    }

    return (
      <>
        {/* SafeAreaProvider is required by React Navigation, but basically
            is unused on web. */}
        <SafeAreaProvider>
          <ApolloProvider client={this.client}>
            <AnalyticsProvider instance={analytics}>
              <DefaultMetaTags>
                <View style={styles.container}>
                  <Suspense fallback={null}>
                    <LottieAnimationFreezer />
                  </Suspense>
                  <AppNavigator />
                </View>
              </DefaultMetaTags>
            </AnalyticsProvider>
          </ApolloProvider>
        </SafeAreaProvider>
      </>
    );
  }
}

export default App;
