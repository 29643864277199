// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OptionRow-module__icon__Ecq_9 {\n    font-size: 24px;\n    color: #333333;\n    opacity: 0.5;\n}\n", ""]);
// Exports
exports.locals = {
	"icon": "OptionRow-module__icon__Ecq_9"
};
module.exports = exports;
