import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  modalTopContainer: {
    paddingHorizontal: 15,
    marginBottom: 2,
  },
  confirmationHeaderContainer: {
    marginBottom: 4,
  },
  inputContainer: {
    position: 'relative',
  },
  input: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(56px)',
    borderWidth: 0.33,
    borderColor: 'rgba(60, 60, 67, 0.33)',
    height: 33,
    borderRadius: 7,
    paddingVertical: 7.5,
    paddingLeft: 7.5,
    paddingRight: 30,
    boxSizing: 'border-box',
    fontSize: 13,
    marginTop: 2,
    marginBottom: 6,
  },
  rightInputComponent: {
    width: 18,
    height: 18,
    position: 'absolute',
    right: 7,
    top: 9.5,
    margin: 'auto',
  },
  errorMessageContainer: {
    marginBottom: 4,
  },
  errorMessage: {
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: 0,
  },
  errorMessageText: {
    fontSize: 13,
    lineHeight: 16,
  },
  signedUpParagraph: {
    marginTop: 11,
  },
  loginContainer: {
    flexDirection: 'row',
    marginHorizontal: 'auto',
  },
  clearButton: {
    opacity: 1,
  },
  clearButtonPressed: {
    opacity: 0.5,
  },
});

export default styles;
