import React from 'react';
import { Text } from 'react-native';
import { withNavigation } from 'react-navigation';

import { useQuery } from '@apollo/client';
import topBarStyles from 'src/components/TopBar.styles';
import { GET_POST_FOR_NAVIGATION } from 'src/graphql/Post';

/**
 * A dead-simple component to query the collection name to put in the header of
 * the post screen.
 */
const CollectionNameHeader = ({ navigation }) => {
  const publicUid = navigation.getParam('public_uid');
  const { data: { post } = {} } = useQuery(GET_POST_FOR_NAVIGATION, {
    variables: {
      publicUid,
    },
  });

  return (
    <Text style={topBarStyles.text}>{post?.visibleForUser ? post?.collection?.name : ''}</Text>
  );
};

export default withNavigation(CollectionNameHeader);
