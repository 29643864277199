import React from 'react';
import { View } from 'react-native';
import { string } from 'prop-types';

import StylePropType from 'src/util/StylePropType';

const SvgImage = ({ source, style, alt }) => (
  <View style={style}>
    <img src={source} alt={alt} style={{ height: '100%', width: '100%' }} />
  </View>
);

SvgImage.propTypes = {
  source: string.isRequired,
  alt: string.isRequired,
  style: StylePropType,
};

SvgImage.defaultProps = {
  style: {},
};

export default SvgImage;
