import React, { useEffect, useContext } from 'react';
import { View } from 'react-native';
import { string, oneOfType, arrayOf, node, oneOf, bool } from 'prop-types';
import { withNavigation } from 'react-navigation';

import hairlineBorderStyle from 'src/util/hairlineBorderStyle';
import Colors from 'src/styles/colors';
import VideoContext from 'src/contexts/VideoContext';
import styles from 'src/components/ScreenBase.styles';
import ResponsiveView from 'src/components/ResponsiveView';
import Footer from 'src/components/Footer';

// This screen provides the default layout for screens within the application.
// It consists of:
//
//   - A "center column" with content
//   - A "main" container that acts as the background for that center column
//     on screens where the center column doesn't occupy the entire viewport.
//   - Two vertical hairline borders on either side of the center column that
//     disappear when the screen is small enough that only the center column
//     is visible.

export const BORDER_TYPES = {
  Normal: 1,
  Gradient: 2,
};

const ScreenBase = ({
  children,
  centerColumnBackgroundColor,
  centerColumnBorderColor,
  borderType,
  withFooter,
  withNegativeMarginTop,
  navigation,
}) => {
  const { setVideoPlaying } = useContext(VideoContext);

  const isScreenFocused = navigation.isFocused();

  useEffect(() => {
    // We pause any video that could be playing when navigating between screens.
    if (!isScreenFocused) {
      setVideoPlaying(null);
    }
  }, [isScreenFocused]);

  const contentStyleOverrides = {
    backgroundColor: centerColumnBackgroundColor,
  };
  return (
    <View style={[styles.main, withNegativeMarginTop ? styles.withNegativeMarginTop : {}]}>
      <View style={styles.centerColumn}>
        <ResponsiveView
          style={{ display: 'none' }}
          breakStyle={[
            styles.verticalHairlineStroke,
            borderType === BORDER_TYPES.Gradient
              ? hairlineBorderStyle('left', centerColumnBorderColor)
              : { backgroundColor: centerColumnBorderColor },
          ]}
        />
        <View style={[styles.content, contentStyleOverrides]}>{children}</View>
        <ResponsiveView
          style={{ display: 'none' }}
          breakStyle={[
            styles.verticalHairlineStroke,
            borderType === BORDER_TYPES.Gradient
              ? hairlineBorderStyle('right', centerColumnBorderColor)
              : { backgroundColor: centerColumnBorderColor },
          ]}
        />
      </View>
      {withFooter && <Footer />}
    </View>
  );
};

ScreenBase.defaultProps = {
  children: [],
  centerColumnBackgroundColor: Colors.defaultCenterColumnBackground,
  centerColumnBorderColor: Colors.defaultCenterColumnBorder,
  borderType: BORDER_TYPES.Normal,
  withFooter: false,
  withNegativeMarginTop: false,
};

ScreenBase.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  centerColumnBackgroundColor: string,
  centerColumnBorderColor: string,
  borderType: oneOf(Object.values(BORDER_TYPES)),
  withFooter: bool,
  withNegativeMarginTop: bool,
};

export default withNavigation(ScreenBase);
