import React, { useState } from 'react';
import { bool, oneOf, func } from 'prop-types';
import { Text } from 'react-native';

import StylePropType from 'src/util/StylePropType';
import defaultStyles from 'src/components/HoverableButton.styles';
import { TouchableWithoutFeedback } from 'src/components/Touchable';

const HoverableButton = ({
  children,
  hoverStyle,
  textStyle,
  style,
  pressedStyle,
  onPress,
  size,
  disabled,
  ...otherProps
}) => {
  const [pressed, setPressed] = useState(false);
  const [hover, setHover] = useState(false);

  const styles = [defaultStyles.button, style];
  const textStyles = [defaultStyles.buttonText, textStyle];

  if (size === 'large') {
    styles.push(defaultStyles.largeButton);
    textStyles.push(defaultStyles.largeButtonText);
  }

  if (pressed) {
    styles.push(defaultStyles.pressedButton, pressedStyle);
  } else if (hover) {
    styles.push(defaultStyles.hoverButton, hoverStyle);
  } else if (disabled) {
    styles.push(defaultStyles.disabledButton);
  }

  const handlePress = (...params) => {
    if (onPress) {
      onPress(...params);
    }
  };

  return (
    <TouchableWithoutFeedback
      {...otherProps}
      onPressIn={() => disabled || setPressed(true)}
      onPressOut={() => disabled || setPressed(false)}
      onMouseEnter={() => disabled || setHover(true)}
      onMouseLeave={() => disabled || setHover(false)}
      onPress={handlePress}
      style={styles}
    >
      <Text style={textStyles}>{children}</Text>
    </TouchableWithoutFeedback>
  );
};

HoverableButton.propTypes = {
  style: StylePropType,
  hoverStyle: StylePropType,
  textStyle: StylePropType,
  pressedStyle: StylePropType,
  size: oneOf(['default', 'large']),
  onPress: func,
  disabled: bool,
};

HoverableButton.defaultProps = {
  style: {},
  hoverStyle: {},
  textStyle: {},
  pressedStyle: {},
  size: 'default',
  disabled: false,
  onPress: null,
};

export default HoverableButton;
