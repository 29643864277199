import React, { useRef } from 'react';
import { View, Text } from 'react-native';

import styles from 'src/components/PostModal/CustomThumbnailButton.styles';
import { TouchableOpacity } from 'src/components/Touchable';

const CustomThumbnailButton = ({ onChange }) => {
  const hiddenFieldInput = useRef(null);

  const handleChange = (event) => {
    if (event.target.files.length === 0) {
      return false;
    }

    const uploadedFile = event.target.files[0];

    return onChange(uploadedFile);
  };

  const handleOnPress = () => {
    hiddenFieldInput.current.click();
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={handleOnPress} style={styles.button}>
        <Text style={styles.text}>Custom Thumbnail</Text>
      </TouchableOpacity>
      <input
        type="file"
        ref={hiddenFieldInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        data-testid="file-input"
      />
    </View>
  );
};

export default CustomThumbnailButton;
