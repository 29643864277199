// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LeftSidebar-module__closeIcon__3cmQ6 {\n    width: 16px;\n    height: 16px;\n    margin-left: 16px;\n    font-size: 17px;\n    color: #FFFFFF;\n}\n\n.LeftSidebar-module__listItemIcon__2TV-o {\n    margin: 0 16px;\n    font-size: 16px;\n    color: #FFFFFF;\n}\n\n.LeftSidebar-module__loginIcon__1os_7 {\n    margin: 0 15px;\n    width: 18px;\n}\n", ""]);
// Exports
exports.locals = {
	"closeIcon": "LeftSidebar-module__closeIcon__3cmQ6",
	"listItemIcon": "LeftSidebar-module__listItemIcon__2TV-o",
	"loginIcon": "LeftSidebar-module__loginIcon__1os_7"
};
module.exports = exports;
