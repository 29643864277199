import React, { Component } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';

import StylePropType from 'src/util/StylePropType';

class ResponsiveView extends Component {
  state = {
    isWide: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
    // On mobile 100vh !== 100%, that's why we need to define the vh variable ourselves.
    // https://dev.to/admitkard/mobile-issue-with-100vh-height-100-100vh-3-solutions-3nae
    document.querySelector(':root').style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    const { breakPoint } = this.props;
    this.setState(() => ({ isWide: window.innerWidth > breakPoint }));
  };

  render() {
    const { isWide } = this.state;
    const { children, breakComponent, style, breakStyle, testID } = this.props;
    const content = breakComponent !== null && isWide ? breakComponent : children;
    const containerStyle = breakStyle !== null && isWide ? breakStyle : style;
    return (
      <View testID={testID} style={containerStyle}>
        {content}
      </View>
    );
  }
}

ResponsiveView.propTypes = {
  breakPoint: PropTypes.number,
  children: PropTypes.node,
  breakComponent: PropTypes.node,
  style: StylePropType,
  breakStyle: StylePropType,
};

ResponsiveView.defaultProps = {
  breakPoint: 600,
  breakStyle: null,
  style: null,
  children: null,
  breakComponent: null,
};

export default ResponsiveView;
