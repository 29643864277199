// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FlowModalNavigation-module__backIcon__SvuZq {\n    font-size: 19px;\n    color: #0279FF;\n}\n", ""]);
// Exports
exports.locals = {
	"backIcon": "FlowModalNavigation-module__backIcon__SvuZq"
};
module.exports = exports;
