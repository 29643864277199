import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  contentBackground: {
    alignSelf: 'center',
    width: '100%',
    minWidth: 250,
    maxWidth: 1680,
  },
  feedBackground: {
    backgroundColor: '#FFF',
    width: '100%',
    paddingBottom: 24,
  },
});

export default styles;
