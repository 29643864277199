import { StyleSheet } from 'react-native';

import { zIndex } from 'src/styles/zIndex';

const styles = StyleSheet.create({
  floatingPlusButton: {
    borderRadius: '50%',
    backgroundImage: 'linear-gradient(180deg, #60CAFF 0%, #1A91FD 100%)',
    boxShadow: '0px 1.93846px 4.34483px rgba(0, 0, 0, 0.25)',
    bottom: 28,
    height: 65,
    justifyContent: 'center',
    paddingBottom: 0,
    paddingTop: 0,
    position: 'fixed',
    right: '4.67%',
    width: 65,
    zIndex: zIndex.bottomBar,
  },
});

export default styles;
