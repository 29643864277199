import { useState, useEffect, useRef } from 'react';

// Arguments
// loadingDelay: indicates the initial wait time before the loading is shown.
// minLoadDuration: indicates the minimum time that the loading indicator should be shown.
const useSmartLoading = ({ loading, loadingDelay, minLoadDuration, callback }) => {
  const initialWaitTimeoutId = useRef(0);
  const callbackFired = useRef(false);
  const [loadingStarted, setLoadingStarted] = useState(false);
  const [initialWaitCompleted, setInitialWaitCompleted] = useState(loadingDelay === 0 && !loading);
  const [minLoadDurationCompleted, setMinLoadDurationCompleted] = useState(minLoadDuration === 0);

  const startLoading = () => {
    setInitialWaitCompleted(true);
    if (minLoadDuration !== 0) {
      setMinLoadDurationCompleted(false);
      setTimeout(() => {
        setMinLoadDurationCompleted(true);
      }, minLoadDuration);
    } else {
      setMinLoadDurationCompleted(true);
    }
  };

  useEffect(() => {
    if (loading) {
      setLoadingStarted(true);
      if (loadingDelay !== 0) {
        if (!initialWaitTimeoutId.current) {
          initialWaitTimeoutId.current = setTimeout(() => {
            startLoading();
          }, loadingDelay);
        }
      } else {
        startLoading();
      }
    } else if (loadingStarted && !initialWaitCompleted) {
      clearTimeout(initialWaitTimeoutId.current);
      setInitialWaitCompleted(true);
      setMinLoadDurationCompleted(true);
    }
  }, [loading, initialWaitCompleted]);

  useEffect(() => {
    if (
      !loading &&
      initialWaitCompleted &&
      minLoadDurationCompleted &&
      !callbackFired.current &&
      callback
    ) {
      callback();
      callbackFired.current = true;
    }
  }, [loading, initialWaitCompleted, minLoadDurationCompleted, callback]);

  let showLoading = false;

  if (initialWaitCompleted) {
    if (loading) {
      showLoading = true;
    } else {
      showLoading = !minLoadDurationCompleted && loadingStarted;
    }
  }

  const onInitialWait = loadingStarted && !initialWaitCompleted;

  return { onInitialWait, showLoading };
};

export default useSmartLoading;
