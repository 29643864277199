import React from 'react';
import { View } from 'react-native';
import { string, func } from 'prop-types';

import styles from 'src/components/PostInformationModal/Tabs.styles';
import Tab from 'src/components/PostInformationModal/Tab';
import SeenBySvg from 'assets/images/seen-by.svg';
import HeartSvg from 'assets/images/heart.svg';

export const TABS = {
  SeenBy: 'seenBy',
  Likes: 'likes',
};

const Tabs = ({ selectedTab, onChange }) => (
  <View style={styles.container}>
    <Tab
      Icon={SeenBySvg}
      text="Seen By"
      selected={selectedTab === TABS.SeenBy}
      onPress={() => onChange(TABS.SeenBy)}
    />
    <Tab
      Icon={HeartSvg}
      text="Likes"
      selected={selectedTab === TABS.Likes}
      onPress={() => onChange(TABS.Likes)}
    />
  </View>
);

Tabs.propTypes = {
  selectedTab: string.isRequired,
  onChange: func.isRequired,
};

export default Tabs;
