import React, { useEffect } from 'react';
import { View, Text } from 'react-native';
import { useQuery } from '@apollo/client';
import { bool } from 'prop-types';

import { shortFormTimeStamp } from 'src/util/dateUtils';
import FlowDescriptionLarge from 'src/components/FlowModal/FlowDescriptionLarge';
import UserRow from 'src/components/UserRow';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { postShape } from 'src/constants/Shapes';
import { GET_POST_LIKES } from 'src/graphql/Post';
import styles from 'src/components/PostInformationModal/LikesInformation.styles';

const REFETCH_DELAY_MS = 650;

// Tab of the post information modal describing who has liked a post.
//
// This tab has two possible "loading states":
//
//   1) The tab is loading for the first time: display only the loading indicator.
//   2) The tab has data, but is refetching it. Display the old data alongside a
//      loading indicator.
const LikesInformation = ({ post, visible }) => {
  const {
    loading: postWithLikesLoading,
    data: postWithLikes,
    refetch: refetchPostWithLikes,
  } = useQuery(GET_POST_LIKES, {
    variables: {
      publicUid: post?.publicUid,
      // Determined experimentally by science!
      minDuration: 650,
    },
    notifyOnNetworkStatusChange: true,
  });
  const likes = postWithLikes?.post?.likes;

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        refetchPostWithLikes();
      }, REFETCH_DELAY_MS);
    }
  }, [visible]);

  return (
    <View style={visible ? {} : styles.hidden}>
      {likes ? (
        <>
          <FlowDescriptionLarge style={styles.likesCount}>
            {likes.length} likes
            {postWithLikesLoading && <LoadingIndicator style={styles.refetchLoadingIndicator} />}
          </FlowDescriptionLarge>
          {likes.map(({ user, createdAt }) => (
            <View key={user.id} style={styles.userRowContainer}>
              <UserRow {...user} />
              <Text style={styles.likesTimestamp}>{shortFormTimeStamp(createdAt)}</Text>
            </View>
          ))}
        </>
      ) : (
        <LoadingIndicator style={styles.initialLoadingIndicator} />
      )}
    </View>
  );
};

LikesInformation.propTypes = {
  post: postShape.isRequired,
  visible: bool.isRequired,
};

export default LikesInformation;
