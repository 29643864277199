// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RightSidebar-module__closeIcon__3DxEJ {\n    margin: 0 20px;\n    width: 16px;\n    height: 16px;\n    font-size: 17px;\n    color: #FFFFFF;\n}\n\n.RightSidebar-module__editIcon__3RPys {\n    font-size: 17px;\n    color: #F2F2F2;\n    margin: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"closeIcon": "RightSidebar-module__closeIcon__3DxEJ",
	"editIcon": "RightSidebar-module__editIcon__3RPys"
};
module.exports = exports;
