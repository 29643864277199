import React, { useState, createContext } from 'react';
import WithFeatureExplanation from 'src/components/hocs/WithFeatureExplanation';

const ConnectWalletFlowContext = createContext({
  openConnectWalletFlow: () => {},
  closeConnectWalletFlow: () => {},
  connectWalletFlowOpen: false,
  flowProps: null,
});

export const ConnectWalletFlowContextProvider = WithFeatureExplanation()(
  ({ children, openFeatureExplanation }) => {
    const [connectWalletFlowOpen, setConnectWalletFlowOpen] = useState(false);
    const [flowProps, setFlowProps] = useState(null);

    const closeConnectWalletFlow = () => {
      setConnectWalletFlowOpen(false);
      setFlowProps(null);
    };

    const openConnectWalletFlow = ({
      artist = null,
      showWelcome = false,
      changeWallet = false,
    } = {}) => {
      setFlowProps({ artist, showWelcome, changeWallet });

      if (artist?.connectWalletFeatureUid) {
        openFeatureExplanation({
          uid: artist.connectWalletFeatureUid,
          handleContinue: () => setConnectWalletFlowOpen(true),
        });
      } else {
        setConnectWalletFlowOpen(true);
      }
    };

    return (
      <ConnectWalletFlowContext.Provider
        value={{
          openConnectWalletFlow,
          closeConnectWalletFlow,
          connectWalletFlowOpen,
          flowProps,
        }}
      >
        {children}
      </ConnectWalletFlowContext.Provider>
    );
  },
);

export default ConnectWalletFlowContext;
