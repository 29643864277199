import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { bool, func, string } from 'prop-types';

import styles from 'src/components/PostModal/OptionRow.styles';
import webStyles from 'src/components/PostModal/OptionRow.module.css';
import Switch from 'src/components/Switch';
import { TouchableOpacity } from 'src/components/Touchable';
import ChildrenPropType from 'src/util/ChildrenPropType';
import QuestionCircleSvg from 'assets/images/question-circle.svg';

const OptionRow = ({ onChange, value, title, helperText, innerComponent, ...props }) => {
  const [isHelperTextOpen, setIsHelperTextOpen] = useState(false);

  const toggleHelperText = () => setIsHelperTextOpen((oldIsHelperTextOpen) => !oldIsHelperTextOpen);

  return (
    <View style={styles.container} {...props}>
      <View style={styles.row}>
        <Text style={styles.title}>{title}</Text>
        <TouchableOpacity onPress={toggleHelperText} testID="toggle-helper-text">
          <QuestionCircleSvg className={webStyles.icon} />
        </TouchableOpacity>
        <Switch onValueChange={onChange} value={value} style={styles.switch} />
      </View>
      {value && innerComponent}
      <View style={styles.row}>
        {isHelperTextOpen && <Text style={styles.helperText}>{helperText}</Text>}
      </View>
    </View>
  );
};

OptionRow.defaultProps = {
  innerComponent: undefined,
};

OptionRow.propTypes = {
  onChange: func.isRequired,
  value: bool.isRequired,
  title: string.isRequired,
  helperText: string,
  innerComponent: ChildrenPropType,
};

OptionRow.defaultProps = {
  helperText: '',
};

export default OptionRow;
