// This is a reimplementation of react-native-country-picker using its
// internal components that allows us to customize its styling.
//
// For the original implementation, see:
// https://github.com/xcarpentier/react-native-country-picker-modal/blob/master/src/CountryPicker.tsx
import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { HeaderModal } from 'react-native-country-picker-modal/lib/HeaderModal';
import { FlagType } from 'react-native-country-picker-modal/lib/types';
import { CountryFilter } from 'react-native-country-picker-modal/lib/CountryFilter';
import { FlagButton } from 'react-native-country-picker-modal/lib/FlagButton';
import { useContext } from 'react-native-country-picker-modal/lib/CountryContext';
import { CountryList } from 'react-native-country-picker-modal/lib/CountryList';
import styles from 'src/components/CountryPicker.styles';

const CountryPicker = (props) => {
  const {
    countryCode,
    countryCodes,
    excludeCountries,
    placeholder,
    preferredCountries,
    renderFlagButton: renderButton,
    flatListProps,
    containerButtonStyle,
    onSelect,
    withEmoji,
    withCallingCodeButton,
    withCountryNameButton,
    withAlphaFilter,
    withCallingCode,
    withFlag,
    visible: visibleProp,
    onClose: handleClose,
    onOpen: handleOpen,
    style,
  } = props;

  const [state, setState] = useState({
    visible: visibleProp || false,
    countries: [],
    filter: '',
    filterFocus: true,
  });
  const { translation, getCountriesAsync } = useContext();
  const { visible, filter, countries, filterFocus } = state;

  useEffect(() => {
    if (state.visible !== visibleProp) {
      setState({ ...state, visible: visibleProp || false });
    }
  }, [visibleProp]);

  const onOpen = () => {
    setState({ ...state, visible: true });
    if (handleOpen) {
      handleOpen();
    }
  };
  const onClose = () => {
    setState({ ...state, filter: '', visible: false });
    if (handleClose) {
      handleClose();
    }
  };
  const setFilter = (filterValue) => setState({ ...state, filter: filterValue });
  const setCountries = (countriesValue) => setState({ ...state, countries: countriesValue });
  const onSelectClose = (country) => {
    onSelect(country);
    onClose();
  };
  const onFocus = () => setState({ ...state, filterFocus: false });
  const onBlur = () => setState({ ...state, filterFocus: false });
  const flagProp = {
    countryCode,
    withEmoji,
    withCallingCodeButton,
    withCountryNameButton,
    renderFlagButton: renderButton,
    onOpen,
    containerButtonStyle,
    placeholder,
  };

  useEffect(() => {
    getCountriesAsync(
      FlagType.FLAT,
      translation,
      null,
      null,
      countryCodes,
      excludeCountries,
      preferredCountries,
      withAlphaFilter,
    ).then(setCountries);
  }, [translation]);

  return (
    <View
      style={[visible ? styles.containerStyle : styles.flagButtonContainer, style]}
      testID="country-picker"
    >
      {!visible && (
        <View testID="countrypicker-flag-button" style={styles.flagButton}>
          <FlagButton {...flagProp} />
        </View>
      )}

      {visible && (
        <>
          <HeaderModal
            withFilter
            onClose={onClose}
            renderFilter={(renderFilterProps) => (
              <CountryFilter
                {...renderFilterProps}
                autoFocus
                onFocus={onFocus}
                onBlur={onBlur}
                onChangeText={setFilter}
                value={filter}
              />
            )}
          />

          <View style={styles.selectorContainer} testID="country-list">
            <CountryList
              {...{
                onSelect: onSelectClose,
                data: countries,
                letters: [],
                withAlphaFilter: withAlphaFilter && filter === '',
                withCallingCode,
                withFlag,
                withEmoji,
                filter,
                filterFocus,
                flatListProps,
              }}
            />
          </View>
        </>
      )}
    </View>
  );
};

CountryPicker.defaultProps = {
  withAlphaFilter: false,
  withCallingCode: false,
  placeholder: 'Select Country',
  style: {},
};

export default CountryPicker;
