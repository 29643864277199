// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FloatingPlusButton-module__icon__A4pQg {\n    font-size: 24px;\n    color: #FFFFFF;\n}\n", ""]);
// Exports
exports.locals = {
	"icon": "FloatingPlusButton-module__icon__A4pQg"
};
module.exports = exports;
