import React from 'react';
import { Text, View, ScrollView } from 'react-native';
import { func, string } from 'prop-types';

import { TouchableWithoutFeedback, TouchableOpacity } from 'src/components/Touchable';
import Modal from 'src/components/Modal';
import HorizontalHairlineStroke from 'src/components/HorizontalHairlineStroke';
import styles from 'src/components/SelectCollectionFlow/CollectionPickerModal.styles';
import webStyles from 'src/components/SelectCollectionFlow/CollectionPickerModal.module.css';
import CollectionPickerRow from 'src/components/SelectCollectionFlow/CollectionPickerRow';
import CrossSvg from 'assets/images/cross.svg';
import PlusIconSvg from 'assets/images/plus-icon.svg';

const CollectionPickerModal = ({
  collections,
  selectedCollectionId,
  onChange,
  onClose,
  openCollectionModal,
  moveCollectionHigher,
}) => (
  <Modal
    style={styles.modal}
    backdropTransitionInTiming={1}
    backdropTransitionOutTiming={1}
    animationInTiming={1}
    animationOutTiming={1}
    testID="collection-picker-modal"
  >
    <ScrollView contentContainerStyle={styles.contentContainer}>
      <TouchableWithoutFeedback style={styles.backDrop} onPress={onClose} />
      <View style={[styles.sizeBox, styles.sizeBoxTop]} />
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.navigation}>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={onClose}
              testID="close-collection-picker-modal"
            >
              <CrossSvg className={webStyles.closeIcon} />
            </TouchableOpacity>
            <Text style={styles.title}>Post to</Text>
          </View>
          <HorizontalHairlineStroke color="#C4C4C4" />
          {collections.map((collection, idx) => {
            const { id, name } = collection;

            return (
              <CollectionPickerRow
                key={`collection-${id}`}
                name={name}
                selected={id === selectedCollectionId}
                onPress={() => onChange(id)}
                onEditPress={() => openCollectionModal(collection)}
                onMoveCollectionHigher={idx > 0 ? () => moveCollectionHigher(collection) : null}
              />
            );
          })}
          <TouchableOpacity style={styles.collectionRow} onPress={() => openCollectionModal()}>
            <View style={styles.selectedBox}>
              <PlusIconSvg className={webStyles.plusIcon} />
            </View>
            <Text style={styles.createCollectionText}>Create Collection</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={[styles.sizeBox, styles.sizeBoxBottom]} />
    </ScrollView>
  </Modal>
);

CollectionPickerModal.propTypes = {
  selectedCollectionId: string,
  onChange: func.isRequired,
  onClose: func.isRequired,
  openCollectionModal: func.isRequired,
};

CollectionPickerModal.defaultProps = {
  selectedCollectionId: undefined,
};

export default CollectionPickerModal;
