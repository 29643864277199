// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Tab-module__icon__3E67e {\n    font-size: 20px;\n    margin-bottom: 8px;\n    color: #7A7A7B;\n}\n.Tab-module__selected__2zgiX {\n    color: #222327;\n}\n", ""]);
// Exports
exports.locals = {
	"icon": "Tab-module__icon__3E67e",
	"selected": "Tab-module__selected__2zgiX"
};
module.exports = exports;
