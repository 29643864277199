import React from 'react';
import { View } from 'react-native';
import { number, bool } from 'prop-types';

import StylePropType from 'src/util/StylePropType';
import ChildrenPropType from 'src/util/ChildrenPropType';
import useSmartLoading from 'src/hooks/useSmartLoading';
import LoadingIndicator from 'src/components/LoadingIndicator';

// Arguments
// loadingDelay: indicates the initial wait time before the loading is shown.
// minLoadDuration: indicates the minimum time that the loading indicator should be shown.
const SmartLoading = ({
  children,
  loading,
  loadingStyles,
  loadingContainerStyles,
  loadingDelay,
  minLoadDuration,
}) => {
  const { onInitialWait, showLoading } = useSmartLoading({
    loading,
    loadingDelay,
    minLoadDuration,
  });

  if (onInitialWait) {
    return null;
  }

  if (showLoading) {
    return (
      <View style={loadingContainerStyles}>
        <LoadingIndicator style={loadingStyles} />
      </View>
    );
  }

  return <>{children}</>;
};

SmartLoading.defaultProps = {
  children: [],
  loadingStyles: {},
  loadingContainerStyles: {},
  loadingDelay: 0,
  minLoadDuration: 0,
};

SmartLoading.propTypes = {
  children: ChildrenPropType,
  loadingStyles: StylePropType,
  loadingContainerStyles: StylePropType,
  loadingDelay: number,
  minLoadDuration: number,
  loading: bool.isRequired,
};

export default SmartLoading;
