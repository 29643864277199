import React from 'react';
import { Image } from 'react-native';

import styles from 'src/components/ChannelHeader/TvRating.styles';

const RATING_HEIGHT = 13;

const TvRating = ({ uri, aspectRatio }) => (
  <Image
    source={{ uri }}
    resizeMode="contain"
    style={[styles.rating, { paddingRight: aspectRatio * RATING_HEIGHT }]}
  />
);

export default TvRating;
