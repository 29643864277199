import { gql } from '@apollo/client';

import { PostFragment, CollectionFragment } from 'src/graphql/Fragments';

const GET_COLLECTION_WITH_PAGINATED_POSTS = gql`
  query getCollection($username: String!, $slug: String!, $offset: Int!, $limit: Int!) {
    collection(username: $username, slug: $slug) {
      ...CollectionInfo
      postCount
      posts(offset: $offset, limit: $limit) {
        id
        timeStamp: timestamp
        ...PostInfo
      }
    }
  }
  ${CollectionFragment}
  ${PostFragment}
`;

const GET_COLLECTION_HEADERS = gql`
  query getCollectionHeaders($username: String!) {
    collections(username: $username) {
      ...CollectionInfo
      postCount
      posts(limit: 5) {
        id
        timeStamp: timestamp
        thumbnail
      }
    }
  }
  ${CollectionFragment}
`;

const GET_COLLECTIONS = gql`
  query getCollections($username: String!) {
    collections(username: $username) {
      ...CollectionInfo
      posts(limit: 999) {
        id
        timeStamp: timestamp
        ...PostInfo
      }
    }
  }
  ${CollectionFragment}
  ${PostFragment}
`;

const GET_COLLECTIONS_FOR_ARTIST = gql`
  query getCollectionsForArtist($username: String!) {
    collections(username: $username) {
      ...CollectionInfo
    }
  }
  ${CollectionFragment}
`;

const UPDATE_COLLECTION_MUTATION = gql`
  mutation updateCollection($id: String!, $name: String!, $description: String, $public: Boolean!) {
    updateCollection(id: $id, name: $name, description: $description, public: $public) {
      collection {
        ...CollectionInfo
      }
      errors
    }
  }
  ${CollectionFragment}
`;

const MOVE_COLLECTION_HIGHER = gql`
  mutation moveCollectionHigher($id: ID!) {
    moveCollectionHigher(id: $id) {
      collection {
        id
      }
    }
  }
`;

const CREATE_COLLECTION_MUTATION = gql`
  mutation createCollection(
    $name: String!
    $slug: String!
    $description: String!
    $public: Boolean!
  ) {
    createCollection(name: $name, slug: $slug, description: $description, public: $public) {
      collection {
        ...CollectionInfo
      }
      errors
    }
  }
  ${CollectionFragment}
`;

const GET_MOST_RECENTLY_USED_COLLECTION = gql`
  query getMostRecentlyUsedCollection {
    mostRecentlyUsedCollection {
      id
    }
  }
`;

export {
  GET_COLLECTIONS,
  GET_COLLECTION_HEADERS,
  GET_COLLECTION_WITH_PAGINATED_POSTS,
  GET_COLLECTIONS_FOR_ARTIST,
  UPDATE_COLLECTION_MUTATION,
  MOVE_COLLECTION_HIGHER,
  CREATE_COLLECTION_MUTATION,
  GET_MOST_RECENTLY_USED_COLLECTION,
};
