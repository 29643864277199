import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';

import { GET_CURRENT_USER } from 'src/graphql/User';
import DrawerContext from 'src/contexts/DrawerContext';
import StylePropType from 'src/util/StylePropType';
import styles from 'src/components/ProfileButton.styles';
import { TouchableOpacity } from 'src/components/Touchable';
import Avatar from 'src/components/Avatar';

const ProfileButton = ({ style, avatarStyle }) => {
  const { data: { me } = {} } = useQuery(GET_CURRENT_USER);

  const { rightDrawerOpen, openDrawer, closeDrawer } = useContext(DrawerContext);

  return (
    <TouchableOpacity
      testID="profile-button"
      style={[styles.containerStyle, style]}
      onPress={(evt) => {
        // NOTE(zbrukas): we're preventing the default behavior here
        // for the buttons to work on narrow viewports
        evt.preventDefault();

        if (rightDrawerOpen) {
          closeDrawer('right');
        } else {
          openDrawer('right');
        }
      }}
    >
      <Avatar
        size="small"
        username={me?.username}
        profileImage={me?.thumbnailProfileImage}
        style={avatarStyle}
      />
    </TouchableOpacity>
  );
};

ProfileButton.defaultProps = {
  style: {},
};

ProfileButton.propTypes = {
  style: StylePropType,
};

export default ProfileButton;
