// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Notification-module__notification__3bN77 {\n    display: flex;\n    position: fixed;\n    bottom: 30px;\n    left: 0;\n    right: 0;\n    background-color: black;\n    opacity: 0;\n    border-radius: 40px;\n    width: 200px;\n    height: 50px;\n    margin: 0 auto;\n    z-index: 1000;\n    justify-content: center;\n    align-items: center;\n    color: white;\n    font-size: 16px;\n    -webkit-animation: Notification-module__fadeOut__1PCzq 150ms ease-in-out forwards;\n            animation: Notification-module__fadeOut__1PCzq 150ms ease-in-out forwards;\n    transform: translateY(0)\n}\n\n.Notification-module__notification__3bN77.Notification-module__visible__2Qi_2 {\n        -webkit-animation: Notification-module__fadeIn__2RroI 150ms ease-in-out 250ms forwards;\n                animation: Notification-module__fadeIn__2RroI 150ms ease-in-out 250ms forwards;\n    }\n\n@-webkit-keyframes Notification-module__fadeIn__2RroI {\n    0% {\n        opacity: 0.85;\n        transform: translateY(100px)\n    }\n\n    100% {\n        opacity: 0.85;\n        transform: translateY(0);\n    }\n}\n\n@keyframes Notification-module__fadeIn__2RroI {\n    0% {\n        opacity: 0.85;\n        transform: translateY(100px)\n    }\n\n    100% {\n        opacity: 0.85;\n        transform: translateY(0);\n    }\n}\n\n@-webkit-keyframes Notification-module__fadeOut__1PCzq {\n    0% {\n        opacity: 0.85;\n    }\n    100% {\n        opacity: 0;\n    }\n}\n\n@keyframes Notification-module__fadeOut__1PCzq {\n    0% {\n        opacity: 0.85;\n    }\n    100% {\n        opacity: 0;\n    }\n}\n\n", ""]);
// Exports
exports.locals = {
	"notification": "Notification-module__notification__3bN77",
	"fadeOut": "Notification-module__fadeOut__1PCzq",
	"visible": "Notification-module__visible__2Qi_2",
	"fadeIn": "Notification-module__fadeIn__2RroI"
};
module.exports = exports;
