/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  StyleSheet,
  TouchableOpacity as RNTouchableOpacity,
  TouchableHighlight as RNTouchableHighlight,
} from 'react-native';

const styles = StyleSheet.create({
  button: {
    paddingTop: 6,
    paddingBottom: 6,
    backgroundColor: '#3F51B5',
    borderRadius: 2,
    borderColor: '#3F51B5',
    borderLeftWidth: null,
    borderRightWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    height: 45,
    flexDirection: 'row',
    elevation: 2,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const NBButton = ({ children, style, onPress, ...props }) => (
  <TouchableOpacity style={[styles.button, style]} onPress={onPress} {...props}>
    {children}
  </TouchableOpacity>
);

const Button = NBButton;
const TouchableOpacity = RNTouchableOpacity;
const TouchableHighlight = RNTouchableHighlight;

// The latest version of React Native Web's TouchableWithoutFeedback doesn't
// pass on unhandled props to the underlying DOM element. This breaks two key
// ways that we use TouchableWithoutFeedback:
//
//   - We can't use onClick instead of onPress in order to avoid touch events
//     unintentionally trigger while scrolling.
//   - We can't pass on data-test-"..." to attach unique identifiers to DOM
//     elements for use in Cypress tests.
//
// To avoid this, we export our own `TouchableWithoutFeedback` that *does*
// properly forward unhandled props. The main difference between this
// `TouchableWithoutFeedback` and RNW's default one is that ours automatically
// wraps `children` with a DOM element and can therefore be styled directly,
// whereas RNW's requires wrapping any children in a <View /> in order to style.
const TouchableWithoutFeedback = ({ children, ...props }) => (
  <TouchableOpacity activeOpacity={1.0} {...props}>
    {children}
  </TouchableOpacity>
);

export { Button, TouchableOpacity, TouchableHighlight, TouchableWithoutFeedback };
