// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NonprofitScreen-module__shieldIcon__151lI {\n    color: #FFFFFF;\n    font-size: 40px;\n    margin-top: -3px;\n    filter: drop-shadow(-1px 2px 3px rgba(111 110 110 / 0.5));\n}\n", ""]);
// Exports
exports.locals = {
	"shieldIcon": "NonprofitScreen-module__shieldIcon__151lI"
};
module.exports = exports;
