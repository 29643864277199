import { StyleSheet } from 'react-native';
import { zIndex } from 'src/styles/zIndex';

const styles = StyleSheet.create({
  header: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingHorizontal: 12,
    borderTopRightRadius: 18,
    borderTopLeftRadius: 18,
    paddingTop: 16,
    minHeight: 68,
    paddingBottom: 4,
    zIndex: zIndex.modalNavigation,
  },
  crossIcon: {
    width: 18,
    height: 18,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48,
  },
  exitButton: {
    marginLeft: 'auto',
  },
  actionButton: {
    marginLeft: 'inherit',
  },
});

export default styles;
