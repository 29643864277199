import React, { useEffect, Suspense } from 'react';
import { View } from 'react-native';
import { createStackNavigator } from 'react-navigation-stack';
import { useQuery } from '@apollo/client';

import { getCurrentRoute } from 'src/util/urlUtils';
import retryingLazy from 'src/util/retryingLazy';
import { WithFeatureFlag, FeatureFlags } from 'src/util/featureFlags';
import ShareChannelButton from 'src/components/Share/ShareChannelButton';
import CollectionNameHeader from 'src/components/CollectionNameHeader';
import { DrawerContextProvider } from 'src/contexts/DrawerContext';
import BackButton from 'src/components/BackButton';
import TopBar from 'src/components/TopBar';
import { AuthFlowContextProvider } from 'src/contexts/AuthFlowContext';
import { FeaturesContextProvider } from 'src/contexts/FeaturesContext';
import { NotificationsContextProvider } from 'src/contexts/NotificationsContext';
import { PostActionsModalContextProvider } from 'src/contexts/PostActionsModalContext';
import { PostModalContextProvider } from 'src/contexts/PostModalContext';
import { VideoContextProvider } from 'src/contexts/VideoContext';
import { WarpLoginContextProvider } from 'src/contexts/WarpLoginContext';
import LinkLogInScreen from 'src/screens/LinkLogInScreen';
import RootScreen from 'src/screens/RootScreen';
import PageTracker from 'src/components/PageTracker';
import FeaturesRoot from 'src/components/Features/FeaturesRoot';
import AuthFlowRoot from 'src/components/AuthFlow/AuthFlowRoot';
import PostModalRoot from 'src/components/PostModal/PostModalRoot';
import UserLoadingSplashScreen from 'src/components/UserLoadingSplashScreen';
import DemoWarning from 'src/components/DemoWarning';
import WithLazyLoading from 'src/components/hocs/WithLazyLoading';
import MenuButton from 'src/components/MenuButton';
import ProfileButton from 'src/components/ProfileButton';
import LogInButton from 'src/components/LogInButton';
import ArtistNameHeader from 'src/components/ArtistNameHeader.tsx';
import {
  HomeScreenLoading,
  UserScreenLoading,
  NonprofitScreenLoading,
  AccountScreenLoading,
} from 'src/components/LazyLoading';
import { GET_CURRENT_USER } from 'src/graphql/User';
import LeftDrawer from 'src/components/LeftDrawer';
import RightDrawer from 'src/components/RightDrawer';
import { ConnectWalletFlowContextProvider } from 'src/contexts/ConnectWalletFlowContext';
import ConnectWalletFlowRoot from 'src/components/ConnectWalletFlow/ConnectWalletFlowRoot';

const HomeScreen = retryingLazy(() => import('../screens/HomeScreen.web'));
const HomeLoginScreen = retryingLazy(() => import('../screens/HomeLoginScreen'));
const WarpLoginScreen = retryingLazy(() => import('../screens/WarpLoginScreen'));
const UserScreen = retryingLazy(() => import('../screens/UserScreen'));
const PostScreen = retryingLazy(() => import('../screens/PostScreen'));
const AccountScreen = retryingLazy(() => import('../screens/AccountScreen'));

const EditNotificationsScreen = retryingLazy(() => import('../screens/EditNotificationsScreen'));
const SubscriptionsScreen = retryingLazy(() => import('../screens/SubscriptionsScreen'));
const SubscribersScreen = retryingLazy(() => import('../screens/SubscribersScreen'));
const NonprofitSettingsScreen = retryingLazy(() => import('../screens/NonprofitSettingsScreen'));
const EditSubscriptionScreen = retryingLazy(() => import('../screens/EditSubscriptionScreen'));
const EditChannelCommentSettingsScreen = retryingLazy(() =>
  import('../screens/EditChannelCommentSettingsScreen'),
);
const NonprofitScreen = retryingLazy(() => import('src/screens/NonprofitScreen'));
const LogInScreen = retryingLazy(() => import('src/screens/LogInScreen'));
const WarpLoginRoot = retryingLazy(() => import('src/components/WarpLogin/WarpLoginRoot'));

function getAuthRoute(Component, redirectTo) {
  function getRouteOrRedirect({ navigation, ...restProps }) {
    const { loading: meLoading, data: { me } = {} } = useQuery(GET_CURRENT_USER);

    useEffect(() => {
      if (!meLoading && !me) {
        navigation.navigate(redirectTo);
      }
    }, [meLoading, me]);

    return meLoading || !me ? null : <Component navigation={navigation} {...restProps} />;
  }

  getRouteOrRedirect.navigationOptions = Component.navigationOptions;
  getRouteOrRedirect.propTypes = Component.propTypes;
  getRouteOrRedirect.defaultProps = Component.defaultProps;

  return getRouteOrRedirect;
}

function createParamGetter(route) {
  return (paramName, defaultValue) => {
    const { params } = route;

    if (params && paramName in params) {
      return params[paramName];
    }

    return defaultValue;
  };
}

export function getComponentForRouteName(parentRouter, routeName) {
  if (parentRouter.childRouters) {
    const { childRouters } = parentRouter;
    const routerNames = Object.keys(childRouters);
    const routeIndex = routerNames.indexOf(routeName);

    if (routeIndex > -1) {
      return parentRouter.getComponentForRouteName(routeName);
    }

    let component = null;

    for (let i = 0; i <= routerNames.length - 1; i += 1) {
      const nextRouter = childRouters[routerNames[i]];
      if (component === null && nextRouter !== null) {
        component = getComponentForRouteName(nextRouter, routeName);
      }
    }

    return component;
  }

  return parentRouter.getComponentForRouteName(routeName);
}

const MainStack = createStackNavigator(
  {
    LogIn: {
      screen: WithLazyLoading(LogInScreen),
      path: 'login',
      navigationOptions: () => ({
        headerCenter: 'Log in',
      }),
    },
    Root: {
      screen: RootScreen,
      path: '',
      navigationOptions: () => ({
        hideTopBar: true,
      }),
    },
    Home: {
      screen: WithLazyLoading(HomeScreen, { loadingScreen: <HomeScreenLoading /> }),
      path: 'home',
      navigationOptions: () => ({
        headerLeft: <MenuButton />,
        headerCenter: null,
        headerRight: <ProfileButton />,
        loggedOutHeaderRight: <LogInButton />,
        isFullWidth: true,
        withHeaderUnderlay: false,
      }),
    },
    HomeLogin: {
      screen: WithLazyLoading(HomeLoginScreen, { loadingScreen: <HomeScreenLoading /> }),
      path: 'home/login',
      navigationOptions: () => ({
        hideTopBar: true,
      }),
    },
    WarpLogin: {
      screen: WithLazyLoading(WarpLoginScreen, { loadingScreen: <HomeScreenLoading /> }),
      path: 'home/warplogin',
      navigationOptions: () => ({
        hideTopBar: true,
      }),
    },
    EditNotifications: {
      screen: getAuthRoute(
        WithLazyLoading(EditNotificationsScreen, { loadingScreen: <AccountScreenLoading /> }),
        'Home',
      ),
      path: 'settings/notifications',
      navigationOptions: () => ({
        headerCenter: 'Notifications',
        headerLeft: <MenuButton />,
        headerRight: <ProfileButton />,
      }),
    },
    Subscribers: {
      screen: getAuthRoute(
        WithLazyLoading(SubscribersScreen, { loadingScreen: <AccountScreenLoading /> }),
        'Home',
      ),
      path: 'settings/subscribers',
      navigationOptions: () => ({
        headerCenter: 'Subscribers',
        headerLeft: <MenuButton />,
        headerRight: <ProfileButton />,
      }),
    },
    NonprofitSettings: {
      screen: getAuthRoute(
        WithLazyLoading(NonprofitSettingsScreen, { loadingScreen: <AccountScreenLoading /> }),
        'Home',
      ),
      path: 'settings/nonprofit',
      navigationOptions: () => ({
        headerCenter: 'Nonprofit',
        headerLeft: <MenuButton />,
        headerRight: <ProfileButton />,
      }),
    },
    Settings: {
      screen: getAuthRoute(
        WithLazyLoading(AccountScreen, { loadingScreen: <AccountScreenLoading /> }),
        'Home',
      ),
      path: 'settings',
      navigationOptions: () => ({
        headerCenter: 'Account Settings',
        headerLeft: <MenuButton />,
        headerRight: <ProfileButton />,
      }),
    },
    Subscriptions: {
      screen: getAuthRoute(
        WithLazyLoading(SubscriptionsScreen, { loadingScreen: <AccountScreenLoading /> }),
        'Home',
      ),
      path: 'settings/subscriptions',
      navigationOptions: () => ({
        headerCenter: 'Your Channels',
        headerLeft: <MenuButton />,
        headerRight: <ProfileButton />,
      }),
    },
    EditSubscription: {
      screen: getAuthRoute(
        WithLazyLoading(EditSubscriptionScreen, { loadingScreen: <AccountScreenLoading /> }),
        'Home',
      ),
      path: 'settings/subscriptions/:username',
      navigationOptions: () => ({
        headerCenter: 'Channel Subscription',
        headerLeft: <MenuButton />,
        headerRight: <ProfileButton />,
      }),
    },
    ChannelCommentSettings: {
      screen: getAuthRoute(
        WithLazyLoading(EditChannelCommentSettingsScreen, {
          loadingScreen: <AccountScreenLoading />,
        }),
        'Home',
      ),
      path: 'settings/channel/comments',
      navigationOptions: () => ({
        headerCenter: 'Comment Settings',
        headerLeft: <MenuButton />,
        headerRight: <ProfileButton />,
      }),
    },
    Nonprofit: {
      screen: WithLazyLoading(NonprofitScreen, { loadingScreen: <NonprofitScreenLoading /> }),
      path: 'nonprofit/:slug',
      navigationOptions: () => ({
        headerCenter: 'Nonprofit',
        headerLeft: <BackButton />,
        headerRight: <ProfileButton />,
        loggedOutHeaderRight: <LogInButton />,
      }),
    },
    LinkLogIn: {
      screen: LinkLogInScreen,
      path: 'ml/:token',
    },
    PostFromNotification: {
      screen: WithLazyLoading(PostScreen),
      path: ':username/p/:public_uid/:notification_type',
      navigationOptions: ({ navigation }) => ({
        headerCenter: <CollectionNameHeader />,
        headerLeft: (
          <BackButton
            defaultRoute={{
              routeName: 'User',
              params: { username: navigation.getParam('username') },
            }}
          />
        ),
        headerRight: <ProfileButton />,
        loggedOutHeaderRight: <LogInButton />,
      }),
    },
    Post: {
      screen: WithLazyLoading(PostScreen),
      path: ':username/p/:public_uid',
      navigationOptions: ({ navigation }) => ({
        headerCenter: <CollectionNameHeader />,
        headerLeft: (
          <BackButton
            defaultRoute={{
              routeName: 'User',
              params: { username: navigation.getParam('username') },
            }}
          />
        ),
        headerRight: <ProfileButton />,
        loggedOutHeaderRight: <LogInButton />,
      }),
    },
    UserWithAction: {
      screen: WithLazyLoading(UserScreen, { loadingScreen: <UserScreenLoading /> }),
      path: ':username/:action',
      navigationOptions: ({ navigation }) => ({
        headerCenter: <ArtistNameHeader username={navigation.getParam('username')} />,
        headerLeft: (
          <WithFeatureFlag flag={FeatureFlags.BACK_ARROW_ON_CHANNELS} fallback={<MenuButton />}>
            <BackButton defaultRoute={{ routeName: 'Root' }} />
          </WithFeatureFlag>
        ),
        headerRight: (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <ShareChannelButton /> <ProfileButton />
          </View>
        ),
        loggedOutHeaderRight: (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <ShareChannelButton /> <LogInButton />
          </View>
        ),
        withHeaderUnderlay: false,
        isFullWidth: true,
      }),
    },
    User: {
      screen: WithLazyLoading(UserScreen, { loadingScreen: <UserScreenLoading /> }),
      path: ':username',
      navigationOptions: ({ navigation }) => ({
        headerCenter: <ArtistNameHeader username={navigation.getParam('username')} />,
        headerLeft: (
          <WithFeatureFlag flag={FeatureFlags.BACK_ARROW_ON_CHANNELS} fallback={<MenuButton />}>
            <BackButton defaultRoute={{ routeName: 'Root' }} />
          </WithFeatureFlag>
        ),
        headerRight: (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <ShareChannelButton /> <ProfileButton />
          </View>
        ),
        loggedOutHeaderRight: (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <ShareChannelButton /> <LogInButton />
          </View>
        ),
        withHeaderUnderlay: false,
        isFullWidth: true,
      }),
    },
  },
  {
    headerMode: 'screen',
    transparentCard: true,
    initialRouteName: 'Root',
    mode: 'card',
    defaultNavigationOptions: {
      animationEnabled: false,
      header: ({ scene, navigation }) => {
        const {
          options: {
            headerLeft,
            headerRight,
            headerCenter,
            loggedOutHeaderLeft,
            loggedOutHeaderRight,
            loggedOutHeaderCenter,
            withHeaderUnderlay,
            hideTopBar,
            hideTopBarIfLoggedIn,
            isFullWidth,
          } = {},
        } = scene.descriptor;

        if (hideTopBar) return null;

        return (
          <TopBar
            headerLeft={headerLeft}
            headerRight={headerRight}
            headerCenter={headerCenter}
            loggedOutHeaderLeft={loggedOutHeaderLeft}
            loggedOutHeaderRight={loggedOutHeaderRight}
            loggedOutHeaderCenter={loggedOutHeaderCenter}
            navigation={navigation}
            withHeaderUnderlay={withHeaderUnderlay}
            hideTopBarIfLoggedIn={hideTopBarIfLoggedIn}
            isFullWidthPage={isFullWidth}
          />
        );
      },
    },
  },
);

class NavigatorWithState extends React.Component {
  static router = MainStack.router;

  componentDidMount() {
    const { navigation } = this.props;

    // Make `navigation` globally available. This enables a temporary workaround
    // where Curtis can add links to other pages on our site from captions
    // by directly using React Navigation:
    //
    // <a onClick="window.navigation.navigate('User', { username: 'curtiswiklund' })">@curtiswiklund</a>
    //
    // Unlike normal relative anchor links, these links don't trigger a full
    // page refresh.
    //
    // See https://github.com/canopy-channels/ploria/issues/4340 for more
    // context.
    window.navigation = navigation;
  }

  render() {
    const { navigation } = this.props;
    const navigationState = navigation.state;
    const route = getCurrentRoute(navigationState);
    const navigationWithGetParam = { ...navigation, getParam: createParamGetter(route) };

    return (
      <AuthFlowContextProvider>
        <ConnectWalletFlowContextProvider>
          <FeaturesContextProvider>
            <NotificationsContextProvider>
              <PostModalContextProvider>
                <PostActionsModalContextProvider navigation={navigationWithGetParam}>
                  <VideoContextProvider>
                    <WarpLoginContextProvider>
                      <DrawerContextProvider>
                        <PageTracker route={route} />
                        <UserLoadingSplashScreen />
                        <DemoWarning />
                        <LeftDrawer />
                        <RightDrawer />
                        <MainStack navigation={navigationWithGetParam} />
                        <FeaturesRoot />
                        <AuthFlowRoot navigation={navigationWithGetParam} />
                        <ConnectWalletFlowRoot />
                        <PostModalRoot navigation={navigationWithGetParam} />
                        <WithFeatureFlag flag={FeatureFlags.WARP_LOGIN}>
                          <Suspense fallback={null}>
                            <WarpLoginRoot />
                          </Suspense>
                        </WithFeatureFlag>
                      </DrawerContextProvider>
                    </WarpLoginContextProvider>
                  </VideoContextProvider>
                </PostActionsModalContextProvider>
              </PostModalContextProvider>
            </NotificationsContextProvider>
          </FeaturesContextProvider>
        </ConnectWalletFlowContextProvider>
      </AuthFlowContextProvider>
    );
  }
}

export default NavigatorWithState;
