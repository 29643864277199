import React from 'react';
import { Text, View } from 'react-native';
import { string, func } from 'prop-types';

import StylePropType from 'src/util/StylePropType';
import styles from 'src/components/UserRow.styles';
import ProgressiveImage from 'src/components/ProgressiveImage';
import { TouchableOpacity } from 'src/components/Touchable';
import Avatar from 'src/components/Avatar';

const UserRow = ({ thumbnailProfileImage, username, realName, onPress, style }) => (
  <TouchableOpacity
    testID="user-row"
    disabled={!onPress}
    onPress={() => {
      onPress(username);
    }}
  >
    <View style={[styles.listItem, onPress || styles.nonpressableListItem, style]}>
      <View style={styles.imageContainer}>
        {thumbnailProfileImage ? (
          <ProgressiveImage
            style={styles.profileImage}
            placeholderColor="rgba(0, 0, 0, 0)"
            imageSource={{ uri: thumbnailProfileImage }}
            borderRadius={999}
            ratio={1}
          />
        ) : (
          <Avatar size="medium" username={username} />
        )}
        <View style={styles.imageBorder} />
      </View>
      <Text style={styles.username}>{realName || username}</Text>
    </View>
  </TouchableOpacity>
);

UserRow.propTypes = {
  username: string.isRequired,
  thumbnailProfileImage: string,
  onPress: func,
  style: StylePropType,
};

UserRow.defaultProps = {
  onPress: undefined,
  thumbnailProfileImage: null,
  style: {},
};

export default UserRow;
