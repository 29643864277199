import * as Sentry from '@sentry/browser';
import SentryFullStory from '@sentry/fullstory';
import { SENTRY_DSN, SENTRY_ORG_SLUG } from 'env';

function getEnvironmentFromHostname() {
  if (window.location.hostname === 'channels.org') {
    return 'production';
  }
  if (window.location.hostname === 'staging.channels.org') {
    return 'staging';
  }
  if (window.location.hostname === 'master.channels.org') {
    return 'master';
  }
  if (/canopychannels-.+\.herokuapp\.com/.test(window.location.hostname)) {
    return 'review';
  }
  if (window.location.hostname === 'demo.channels.org') {
    return 'demo';
  }
  return 'development';
}

Sentry.init({
  dsn: SENTRY_DSN,
  environment: getEnvironmentFromHostname(),
  integrations: [new SentryFullStory(SENTRY_ORG_SLUG)],
});
export default Sentry;
