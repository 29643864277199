import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 40,
    marginBottom: 68,
  },
  sentHeader: {
    marginBottom: 16,
    textAlign: 'center',
  },
  smallText: {
    marginBottom: 16,
    textAlign: 'center',
  },
  resendLink: {
    fontSize: 17,
  },
});

export default styles;
