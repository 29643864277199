import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  containerStyle: {
    height: 50,
    alignContent: 'center',
    justifyContent: 'center',
  },
});

export default styles;
