export const FLOW_MODAL_MIN_HEIGHT = 352;

export const FLOW_MODAL_NAVIGATION_HEIGHT = 68;

export const FLOW_MODAL_CONTENT_MIN_HEIGHT = FLOW_MODAL_MIN_HEIGHT - FLOW_MODAL_NAVIGATION_HEIGHT;

export const PROFILE_IMAGE_THUMBNAIL_WIDTH_PX = 128;
export const PROFILE_IMAGE_REGULAR_WIDTH_PX = 400;
export const PROFILE_IMAGE_BIG_WIDTH_PX = 1200;

// We just make this obnoxiously large so that, for the most part, the max width
// is just determined by how big of an image was uploaded. This gives us temporary
// flexibility in experimenting with banner widths just by uploading different
// widths.
export const PROFILE_BANNER_WIDTH_PX = 3500;

export const POST_IMAGE_WIDTH_PX = 1500;

export const TOP_BAR_HEIGHT_PX = 50;

export const POST_MAX_HEIGHT_PX = 640;

export const CHANNEL_POSTER_IMAGE_THUMBNAIL_WIDTH_PX = 800;
export const CHANNEL_POSTER_IMAGE_BIG_WIDTH_PX = 1200;
