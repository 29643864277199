// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ShieldPopup-module__shieldIcon__1PsVF {\n    font-size: 33px;\n    align-self: center;\n    color: #FFFFFF;\n    filter: drop-shadow(-1px 2px 3px rgba(111 110 110 / 0.5));\n}\n", ""]);
// Exports
exports.locals = {
	"shieldIcon": "ShieldPopup-module__shieldIcon__1PsVF"
};
module.exports = exports;
