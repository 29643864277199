import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    height: 30,
    borderRadius: 15,
    backgroundColor: '#F4F4F5',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    marginBottom: 28,
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 20,
    marginLeft: 5,
  },
  unselected: {
    color: '#7A7A7B',
  },
  selected: {
    color: '#1D89FF',
  },
});

export default styles;
