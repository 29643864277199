// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MoreOptions-module__icon__1Iy7I {\n    font-size: 10px;\n    color: #7A7A7B;\n    margin-right: 7px;\n}\n.MoreOptions-module__downArrow__2WqDR {\n    transform: rotate(90deg);\n}\n", ""]);
// Exports
exports.locals = {
	"icon": "MoreOptions-module__icon__1Iy7I",
	"downArrow": "MoreOptions-module__downArrow__2WqDR"
};
module.exports = exports;
