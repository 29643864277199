import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'space-between',
    minHeight: 'min(max(110vw, 560px), 750px)',
    overflow: 'hidden',
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  bottomOverlay: {
    height: 190,
    width: '100%',
    backgroundImage: 'linear-gradient(180deg, rgba(21, 23, 24, 0) 0%, #151718 65.87%)',
    marginBottom: -1,
  },
  bottomContainer: {
    flex: 1,
    justifyContent: 'end',
  },
  bottomSolidColor: {
    width: '100%',
    flex: 1,
  },
  bottomInnerContainer: {
    width: '100%',
    zIndex: 'unset',
  },
  channelInfo: {
    flexDirection: 'row',
    fontFamily: 'System',
    fontSize: 13,
    lineHeight: 20,
    textAlign: 'center',
    marginTop: 14.5,
    color: 'rgba(255, 255, 255, 0.70)',
  },
  channelInfoInverted: {
    color: 'rgba(0, 0, 0, 0.69)',
  },
  title: {
    paddingTop: 56,
    paddingHorizontal: 64,
    alignSelf: 'center',
    minWidth: 400,
    maxWidth: 440,
    fontFamily: 'System',
    fontSize: 32,
    fontWeight: 600,
    lineHeight: 36,
    textAlign: 'center',
    color: '#FFF',
  },
  tagline: {
    fontFamily: 'System',
    fontSize: 15,
    lineHeight: 20,
    color: '#FFF',
    marginBottom: 30,
  },
  invertedText: {
    color: '#000',
  },
  charityRow: {
    flexDirection: 'row',
    marginTop: 18,
    marginBottom: 24,
    alignItems: 'center',
    paddingHorizontal: '4vw',
  },
  charityText: {
    fontFamily: 'System',
    fontSize: 13,
    lineHeight: 16,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  charityInvertedText: {
    color: 'rgba(0, 0, 0, 0.65)',
  },
  charityLink: {
    textDecorationLine: 'underline',
    color: 'rgba(255, 255, 255, 0.6)',
  },
  logoContainer: {
    paddingHorizontal: '4vw',
  },
  logo: {
    alignSelf: 'center',
  },
  taglineContainer: {
    paddingHorizontal: '4vw',
  },
  buttonContainer: {
    paddingHorizontal: '4vw',
  },
  colorDivs: {
    zIndex: -1,
    position: 'absolute',
    width: '100%',
    height: 'calc(100% + 155px)',
    bottom: 0,
  },
  titleContainer: {
    paddingHorizontal: '4vw',
  },
});

export default styles;
