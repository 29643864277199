import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  tab: {
    flex: 1,
    height: 46,
    paddingVertical: 15,
    alignItems: 'center',
    boxSizing: 'content-box',
    marginBottom: -1,
  },

  text: {
    fontSize: 14,
    fontWeight: 600,
    color: '#7A7A7B',
  },
  selected: {
    color: '#222327',
  },
  selectedBorder: {
    borderBottomWidth: 1,
    borderBottomColor: '#222327',
  },
});

export default styles;
