import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';

import formatGraphQLTextFromMessage from 'src/util/errorHandling';
import { isArtist } from 'src/util/userUtils';
import { delay } from 'src/util/promiseUtils';
import { UPDATE_USERNAME, GET_CURRENT_USER } from 'src/graphql/User';
import FlowModal from 'src/components/FlowModal/FlowModal';
import FlowLoading from 'src/components/FlowModal/FlowLoading';
import FlowModalNavigation from 'src/components/FlowModal/FlowModalNavigation';
import { MIN_LOADING_DURATION_MS } from 'src/components/LoadingIndicator';
import styles from 'src/components/EditUsernameFlow/EditUsernameFlow.styles';
import EditUsernameForm from 'src/components/EditUsernameFlow/EditUsernameForm';
import Finish from 'src/components/EditUsernameFlow/Finish';

const EditUsernameSteps = {
  Form: 0,
  Finish: 1,
};

const EditUsernameFlow = ({ onClose }) => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState();
  const [step, setStep] = useState(EditUsernameSteps.Form);
  const {
    data: { me },
  } = useQuery(GET_CURRENT_USER);

  const [updateUsername] = useMutation(UPDATE_USERNAME, {
    refetchQueries: [{ query: GET_CURRENT_USER }],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (me?.username) {
      setUsername(me.username);
    }
  }, [me]);

  const handleSubmit = async () => {
    if (!username.trim()) {
      setError('Username cannot be empty');
      return;
    }

    try {
      setLoading(true);
      await Promise.all([
        updateUsername({ variables: { username } }),
        delay(MIN_LOADING_DURATION_MS),
      ]);
      setStep(EditUsernameSteps.Finish);
    } catch (err) {
      setError(formatGraphQLTextFromMessage(err.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <FlowModal onClose={onClose}>
      <View style={styles.container} testID="edit-username-flow">
        <FlowModalNavigation onClose={onClose} />
        {loading && <FlowLoading />}
        {
          {
            [EditUsernameSteps.Form]: (
              <EditUsernameForm
                username={username}
                setUsername={setUsername}
                onSubmit={handleSubmit}
                error={error}
                isArtist={isArtist(me)}
              />
            ),
            [EditUsernameSteps.Finish]: <Finish username={username} onClose={onClose} />,
          }[step]
        }
      </View>
    </FlowModal>
  );
};

export default EditUsernameFlow;
