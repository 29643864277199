import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { bool, func } from 'prop-types';

import styles from 'src/components/PostModal/MoreOptions.styles';
import webStyles from 'src/components/PostModal/MoreOptions.module.css';
import HorizontalHairlineStroke from 'src/components/HorizontalHairlineStroke';
import { TouchableOpacity } from 'src/components/Touchable';
import OptionRow from 'src/components/PostModal/OptionRow';
import TimestampSelector from 'src/components/PostModal/TimestampSelector.web';
import DeletePostButton from 'src/components/PostModal/DeletePostButton';
import RightFullArrowSvg from 'assets/images/right-full-arrow.svg';
import clsx from 'clsx';

const MoreOptions = ({
  onWelcomePostChange,
  isWelcomePost,
  notificationsEnabled,
  onNotificationsEnabledChange,
  showDelete,
  handleDelete,
  showNotificationsOption,
  onTimestampChange,
  timestamp,
  defaultTimestamp,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [timestampEnabled, setTimestampEnabled] = useState(false);

  const toggleMoreOptions = () => setIsOpen((oldIsOpen) => !oldIsOpen);

  const handleTimestampEnableChange = (enabled) => {
    if (enabled) {
      setTimestampEnabled(true);
    } else {
      setTimestampEnabled(false);
      onTimestampChange(defaultTimestamp);
    }
  };

  return (
    <>
      <TouchableOpacity onPress={toggleMoreOptions} style={styles.moreOptionsButton}>
        <RightFullArrowSvg className={clsx(webStyles.icon, isOpen && webStyles.downArrow)} />
        <Text style={styles.moreOptionsText}>More options</Text>
      </TouchableOpacity>
      {isOpen && (
        <>
          <HorizontalHairlineStroke color="#CCCCCC" />
          <View style={styles.moreOptionsContainer}>
            <OptionRow
              value={isWelcomePost}
              onChange={onWelcomePostChange}
              title="It's a welcome post"
              helperText={`This post will be sent to users when they first subscribe.${'\n\n'}Often the first (oldest) paid post should be set as the welcome post. It should introduce and welcome the new subscriber, and allow them to begin browsing through all the available posts to date, beginning with the welcome post.`}
              testID="welcome-post-option"
            />
            {showNotificationsOption && (
              <>
                <HorizontalHairlineStroke color="#CCCCCC" />
                <OptionRow
                  value={!notificationsEnabled}
                  onChange={onNotificationsEnabledChange}
                  title="Do not notify subscribers"
                  helperText="No one will get a notification if you enable this and then share this post."
                  testID="notifications-enabled-option"
                />{' '}
              </>
            )}
            <HorizontalHairlineStroke color="#CCCCCC" />
            <OptionRow
              value={timestampEnabled}
              onChange={handleTimestampEnableChange}
              title="Edit Post Date"
              helperText="You can set your post to appear on a date in the past in order to rearrange your posts, but posts cannot be set for a time in the future."
              testID="published-date-option"
              innerComponent={
                <TimestampSelector timestamp={timestamp} onChange={onTimestampChange} />
              }
            />
          </View>
          <HorizontalHairlineStroke color="#CCCCCC" />
          {showDelete && <DeletePostButton onConfirm={handleDelete} />}
        </>
      )}
    </>
  );
};

MoreOptions.propTypes = {
  onWelcomePostChange: func.isRequired,
  showNotificationsOption: bool.isRequired,
};

export default MoreOptions;
