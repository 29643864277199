import React from 'react';
import { View, ScrollView } from 'react-native';
import { func, bool } from 'prop-types';

import ChildrenPropType from 'src/util/ChildrenPropType';
import StylePropType from 'src/util/StylePropType';
import wrapAnimationDuration from 'src/util/wrapAnimationDuration';
import { TouchableWithoutFeedback } from 'src/components/Touchable';
import ResponsiveView from 'src/components/ResponsiveView';
import Modal from 'src/components/Modal';
import styles from 'src/components/FlowModal/FlowModal.styles';

const LIGHTWEIGHT_BREAKPOINT_PX = 360;
const DEFAULT_BREAKPOINT_PX = 535;
/**
 * Component for ubiquitous "flow modal", which is often used for multi-step
 * flows like logging in, changing your email, etc.
 *
 * Supports a `lightweight` mode for tasks we intend to be not fullscreen-on-
 * mobile and visually lightweight.
 */
const FlowModal = ({
  onClose,
  children,
  lightweight,
  animateBackdrop,
  popupContainerStyle,
  popupContainerBreakStyle,
  ...props
}) => {
  const breakPoint = lightweight ? LIGHTWEIGHT_BREAKPOINT_PX : DEFAULT_BREAKPOINT_PX;

  return (
    <Modal
      style={styles.modal}
      backdropTransitionInTiming={animateBackdrop ? wrapAnimationDuration(205) : 1}
      backdropTransitionOutTiming={1}
      animationIn="slideInUp"
      animationInTiming={wrapAnimationDuration(205)}
      animationOutTiming={wrapAnimationDuration(205)}
      {...props}
    >
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <TouchableWithoutFeedback style={styles.backDrop} onPress={onClose} />
        <ResponsiveView breakPoint={breakPoint} breakStyle={styles.sizeBoxTop} />
        <ResponsiveView
          style={styles.sizer}
          breakStyle={styles.sizerBreak}
          breakPoint={breakPoint}
          breakComponent={
            <View
              style={[
                styles.popupContainerBreak,
                lightweight ? styles.lightweightPopupContainerBreak : {},
                popupContainerBreakStyle,
              ]}
            >
              <View style={styles.content}>{children}</View>
            </View>
          }
        >
          <View style={[styles.popupContainer, popupContainerStyle]}>
            <View style={styles.content}>{children}</View>
          </View>
        </ResponsiveView>
        <ResponsiveView breakPoint={breakPoint} breakStyle={styles.sizeBoxBottom} />
      </ScrollView>
    </Modal>
  );
};

FlowModal.defaultProps = {
  lightweight: false,
  animateBackdrop: true,
  popupContainerStyle: {},
  popupContainerBreakStyle: {},
};

FlowModal.propTypes = {
  onClose: func.isRequired,
  children: ChildrenPropType.isRequired,
  lightweight: bool,
  animateBackdrop: bool,
  popupContainerStyle: StylePropType,
  popupContainerBreakStyle: StylePropType,
};

export default FlowModal;
